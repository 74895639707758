import { css } from '@emotion/react';

import fonts from 'Themes/fonts';
import colors from '../../../Themes/colors';

const { black, textgrey, orange, white, lightgreen, darkgreen, grey, lightred, darkred } = colors;

export const ProductContainer = css({
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
    // backgroundColor: 'pink',
});

export const Price = css({
    font: '13px Archivo-Regular, sans-serif',
    color: black,
    fontWeight: '400',
    textAlign: 'right',
    alignSelf: 'center',

    '@media(min-width: 808px)': {
        font: '17px Archivo-Regular, sans-serif',
    },
});

export const Title = css({
    font: '13px Archivo-Regular, sans-serif',
    color: black,
    fontWeight: '600',
    lineHeight: '20px',

    '@media(min-width: 386px)': {
        font: '17px Archivo-Regular, sans-serif',
    },
});
export const Variation = css({
    font: 'Archivo-Regular, sans-serif',
    fontSize: 12,
    color: textgrey,
    marginTop: '0.5em',

    '@media(min-width: 720px)': {
        fontSize: 14,
    },
});
export const Remarks = css({
    fontFamily: fonts.primary,
    fontSize: 10,
    marginTop: '0.3em',
    color: textgrey,

    '@media(min-width: 350px)': {
        fontFamily: fonts.primary,
        fontSize: 12,
    },
});
export const Quantity = css({
    font: '12px Archivo-Regular, sans-serif',
    color: textgrey,
    fontWeight: '400',
    marginTop: '0.3em',
    '@media(min-width: 386px)': {
        font: '14px Archivo-Regular, sans-serif',
    },
});

export const ProductImg = css({
    height: 90,
    width: 90,
    objectFit: 'contain',
});

export const ProductImgSubItem = css({
    height: 70,
    width: 70,
    objectFit: 'contain',
    paddingRight: 5,

    '@media(min-width: 720px)': {
        height: 100,
        width: 100,
    },
});

export const SubContainer = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '20px',
});

export const TitleContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

// container
export const OrderHistoryRow = css({
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: 20,
});
export const OrderStyleBold = css({
    font: '13px Archivo-Regular, sans-serif',
    fontWeight: '800',
    color: black,
    '@media(min-width: 386px)': {
        font: '16px Archivo-Regular, sans-serif',
    },
});
export const OrderStyle = css({
    font: '13px Archivo-Regular, sans-serif',
    fontWeight: '600',
    color: black,
    '@media(min-width: 386px)': {
        font: '16px Archivo-Regular, sans-serif',
    },
});

export const PriceTextContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
});
export const TotalTextStyle = css({
    font: '14px Archivo-Bold, sans-serif',
    color: black,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    textAlign: 'right',
    '@media(min-width: 386px)': {
        font: '16px Archivo-Bold, sans-serif',
    },
});
export const PriceTextStyle = css({
    font: '14px Archivo-Bold, sans-serif',
    color: orange,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media(min-width: 386px)': {
        font: '16px Archivo-Bold, sans-serif',
    },
});
export const ButtonContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});
export const ButtonStyle1 = css({
    font: '12px Archivo-Regular, sans-serif',
    color: orange,
    backgroundColor: white,
    borderColor: orange,
    '&:hover': { background: orange, borderColor: orange },
    padding: '10px',
    marginRight: '20px',
    '@media(min-width: 386px)': {
        font: '18px Archivo-Regular, sans-serif',
        padding: '10px 20px',
        marginRight: '30px',
    },
});
export const ButtonStyle2 = css({
    font: '12px Archivo-Regular, sans-serif',
    color: white,
    backgroundColor: orange,
    borderColor: orange,
    '&:hover': { background: white, borderColor: orange, color: orange },
    padding: '10px',
    '@media(min-width: 386px)': {
        font: '18px Archivo-Regular, sans-serif',
        padding: '10px 20px',
    },
});

export const ButtonStyle3 = css({
    font: '18px Archivo-Regular, sans-serif',
    color: orange,
    backgroundColor: white,
    borderColor: orange,
    '&:hover': { background: orange, borderColor: orange },
    padding: '10px 30px',
    marginRight: '30px',
});

export const ButtonStyle4 = css({
    font: '18px Archivo-Regular, sans-serif',
    color: white,
    backgroundColor: orange,
    borderColor: orange,
    '&:hover': { background: white, borderColor: orange, color: orange },
    padding: '10px 35px',
});

export const productRating = css({
    width: '130px',
    transform: 'scale(1)',
    '@media(min-width: 300px)': {
        transform: 'scale(1.1)',
    },
    '@media(min-width: 500px)': {
        transform: 'scale(1.4)',
    },
    '@media(min-width: 1000px)': {
        transform: 'scale(2)',
    },
});

export const BackandProgContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const BackButton = css({
    font: '14px Archivo-Bold, sans-serif',
    color: black,
    '@media(min-width: 600px)': {
        font: '16px Archivo-Bold, sans-serif',
    },
    '@media(min-width: 900px)': {
        font: '20px Archivo-Bold, sans-serif',
    },
});

export const ProgressStatus = css({
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '18px',
    font: '12px Archivo-Regular, sans-serif',
    lineHeight: '20px',
    fontWeight: '700',
    marginLeft: '15px',
    marginRight: '10px',
    padding: '5px 10px',
    '@media(min-width: 600px)': {
        font: '16px Archivo-Regular, sans-serif',
        marginLeft: '15px',
        marginRight: '10px',
        padding: '10px 20px',
    },
    '@media(min-width: 900px)': {
        font: '20px Archivo-Regular, sans-serif',
    },
});

export const ProgressStatusGreen = css({
    background: lightgreen,
    color: darkgreen,
});

export const ProgressStatusRed = css({
    background: lightred,
    color: darkred,
});

export const BackandSymbol = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});

export const RowFlexCenter = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
});

export const BackCenterer = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const ButtonStyle5 = css({
    font: '12px Archivo-Regular, sans-serif',
    color: orange,
    backgroundColor: white,
    borderColor: orange,
    '&:hover': { background: orange, borderColor: orange, color: white },
    '@media(min-width: 386px)': {
        font: '18px Archivo-Regular, sans-serif',
    },
});

export const StyleImageContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'front',
    flex: 1,
});

export const SummaryTitle = css({
    fontFamily: fonts.primaryBold,
    fontSize: 14,
    color: black,

    '@media(min-width: 900px)': {
        fontFamily: fonts.primaryBold,
        fontSize: 18,
    },
});

export const ProductListItemStyle = css({
    font: '14px Archivo-Regular, sans-serif',
    color: black,
    '@media(min-width: 900px)': {
        font: '18px Archivo-Regular, sans-serif',
    },
});

export const ProductListItemStyle2 = css({
    font: '13px Archivo-Bold, sans-serif',
    color: black,
    '@media(min-width: 900px)': {
        font: '24px Archivo-Bold, sans-serif',
    },
});

export const ProductListTitleStyle = css({
    font: '11px Archivo-Regular, sans-serif',
    color: grey,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '@media(min-width: 900px)': {
        font: '16px Archivo-Regular, sans-serif',
    },
});

export const MobileListStyle = css({
    font: '11px Archivo-Regular, sans-serif',
    color: black,
    marginTop: '3px',
});

export const MobileListStyleBold = css({
    font: '11px Archivo-Bold, sans-serif',
    color: black,
});
