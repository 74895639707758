export default [{
    country: 'Malaysia',
    states: [
        'Selangor',
        'WP Kuala Lumpur',
        'Johor',
        'Kedah',
        'Kelantan',
        'Melaka',
        'Negeri Sembilan',
        'Pahang',
        'Penang',
        'Perak',
        'Sabah',
        'Sarawak',
        'Terengganu',
    ],
    shippingFee: 10,
}, {
    country: 'Singapore',
    states: [
        'Singapore',
    ],
    shippingFee: 40,
},
];
