import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { AsyncThunkAPI } from 'Redux/types';
import Toast from 'Lib/Toast';

import cartSlice from 'Redux/slices/cart';
import userSlice from 'Redux/slices/user';

import NavActions from 'Services/Navigation/Actions';
import Utils from 'Lib/Utils';

export default createAsyncThunk<
    void,
    void,
    AsyncThunkAPI
>('auth/logout', async (params, thunkAPI) => {
    await thunkAPI.dispatch(userSlice.actions.userClearUserInfo());
    await thunkAPI.dispatch(cartSlice.actions.cartClearCart());

    Toast.show('Successfully logged out!');
    Utils.Auth.clearAuthToken();

    Sentry.configureScope(scope => scope.setUser(null));

    NavActions.navResetToHome();
});
