/** @jsxImportSource @emotion/react */
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { Col, Row } from 'reactstrap';

import Fonts from 'Themes/fonts';

import {
    ProductListTitleStyle,
    RowFlexCenter,
} from 'Containers/User/Styles/ComponentStyles';

import EditableProductsListComponent from './EditableProductsListComponent';

function EditableProductList({ products = [] }) {
    if (!products.length) {
        return (
            <View style={{ marginTop: 10 }}>
                <Text style={styles.noCartMessage}>
                    You do not have any items in the cart now. Add some now!
                </Text>
            </View>
        );
    }

    return (
        <div>
            <Row className='d-none d-md-flex mt-4'>
                <Col xs={11}>
                    <Row>
                        <Col xs='6'>
                            <div css={ProductListTitleStyle}>
                                Product Details
                            </div>
                        </Col>
                        <Col xs='2' css={RowFlexCenter}>
                            <div css={ProductListTitleStyle}>Quantity</div>
                        </Col>
                        <Col xs='2' css={RowFlexCenter}>
                            <div css={ProductListTitleStyle}>Price</div>
                        </Col>
                        <Col xs='2' css={RowFlexCenter}>
                            <div css={ProductListTitleStyle}>Total</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {products.map((product) => (
                <EditableProductsListComponent key={product.selectedVariant.sku} product={product} />
            ))}
        </div>
    );
}

const styles = StyleSheet.create({
    noCartMessage: {
        fontFamily: Fonts.primary,
        fontSize: 15,
    },
});

export default EditableProductList;
