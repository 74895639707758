import { UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';

import { IGoldPrice } from '../Entities';

export default createAsyncThunk<
    IGoldPrice | undefined,
    void,
    AsyncThunkAPI
>('home/getGoldPrice', async (params, thunkAPI) => {
    // Mock data for when db is down
    // return {
    //     gold750: 1,
    //     gold916: 2,
    //     gold999: 3,
    //     gold9999: 4,
    // };

    const response = await thunkAPI.extra.useCases.product.getGoldPrice.execute();

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
});
