import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import getProducts from 'Redux/slices/products/controllers/getProducts';
import getProductWidgets from 'Redux/slices/home/controllers/getWidgets';
import getHello from 'Redux/slices/home/controllers/getHello';
import getCart from 'Redux/slices/cart/controllers/getCart';
import getWishlist from 'Redux/slices/user/controllers/getWishlistLocalWeb';
import getUserInfo from 'Redux/slices/user/controllers/getUserInfo';

import { AsyncThunkAPI } from 'Redux/types';

import NavActions from 'Services/Navigation/Actions';
import Utils from 'Lib/Utils';
import authSlice from '..';

export default createAsyncThunk<
    null,
    void,
    AsyncThunkAPI
>('auth/startup', async (params, thunkAPI) => {
    /*
    we have to wait for products first before getting cart, as the getCart useCase
    requires the productList
    */

    const handleError = (error: string) => {
        NavActions.navToError();
        return thunkAPI.rejectWithValue(error);
    };

    let res;

    res = await thunkAPI.dispatch(getHello());

    if (res?.payload?.isMaintenance) return null;
    if (res.error) return handleError('Error in getting gold price. Please try again');

    const authToken: string | null = Utils.Auth.getAuthToken();

    if (authToken) {
        thunkAPI.dispatch(authSlice.actions.authSetAuthToken(authToken));
        const userInfo = await thunkAPI.dispatch(getUserInfo({ authToken }));

        if (userInfo.error) {
            Utils.Auth.clearAuthToken();
            return handleError('Error in getting user info. Please try again.');
        }

        const { email = '' } = userInfo.payload;

        Sentry.setUser({ email });
    }

    res = await thunkAPI.dispatch(getProducts());
    if (res.error) return handleError('Error in getting products. Please try again.');

    res = await thunkAPI.dispatch(getProductWidgets());
    if (res.error) return handleError('Error in getting products. Please try again.');

    res = await thunkAPI.dispatch(getCart());
    if (res.error) return handleError('Error in getting cart. Please try again.');

    res = await thunkAPI.dispatch(getWishlist());
    if (res.error) return handleError('Error in getting wishlist. Please try again.');

    if (window.location.pathname === '/error') {
        NavActions.navResetToHome();
    }

    return null;
});
