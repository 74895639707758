/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IOrderPaymentMethodEnum, IOrder } from '@ddot/AddToCartCommon';

import { View } from 'react-native-web';
import { Container } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';

import PageSkeleton from 'Components/Skeletons/PageSkeleton';
import ErrorMessage from 'Components/Common/ErrorMessage';

import { RootState } from 'Redux/store';
import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import NavActions from 'Services/Navigation/Actions';

import Toast from 'Lib/Toast';

import {
    TopTitleContainer,
    WishTitle,
    SubTitle,
    ContainerBorder,
    PrintContainer,
} from './Styles/OrderConfirmationStyles';

import AddressComponent from './Components/OrderAddress';
import ProductList from './Components/ProductList';
import OrderSummary from './Components/OrderSummary';
import CustomerRemarks from './Components/CustomerRemarks';
import OrderId from './Components/OrderId';

export interface UrlParams {
    orderId: string;
}

interface PropType extends RouteComponentProps<UrlParams> {
    orderDetails: IOrder;
    getOrderDetails(orderId: string): void;
    loading: boolean;
    error: string;
    clearOrder(): void;
    authToken: string;
    userId: string;
    clearCart(): void;
}

const OrderConfirmation = (props: PropType): JSX.Element | null => {
    const { orderDetails, getOrderDetails, match, loading, error, clearOrder, authToken, userId, clearCart } = props;
    const { orderId } = match.params;

    useEffect(() => {
        if (orderId) {
            if (authToken) getOrderDetails(orderId);
        }
    }, [authToken]);

    useEffect(() => {
        if (userId) {
            const cartId = `cart-${userId}`;

            localStorage.removeItem(cartId);
            clearCart();
        }
    }, [userId]);

    useEffect(() => {
        return () => {
            clearOrder();
        };
    }, []);

    if (!orderDetails?.id) return <PageSkeleton />;

    const {
        id, deliveryInfo, paymentMethod, products, shippingFee = 0, totalPrice, currency, customerRemarks,
    } = orderDetails;

    if (!deliveryInfo?.address ?? !paymentMethod ?? !id) return <ErrorMessage error='Something went wrong. Please try again' />;

    let mappedPaymentMethod;

    if (paymentMethod === IOrderPaymentMethodEnum.BankTransfer) {
        mappedPaymentMethod = 'Online Banking';
    } else if (paymentMethod === IOrderPaymentMethodEnum.CreditCard) {
        mappedPaymentMethod = 'Credit Cart';
    } else if (paymentMethod === IOrderPaymentMethodEnum.FPXOnline) {
        mappedPaymentMethod = 'Bank Transfer';
    }

    const {
        email, lastName, firstName, address1, address2, city, postCode, state, country, phoneNumber,
    } = deliveryInfo.address;

    if (loading) return <PageSkeleton />;
    if (error) {
        Toast.showError(error);
        NavActions.navToHome();
    }
    if (!authToken) NavActions.navToHome();

    return (
        <View>
            <Container fluid className='mb-5'>
                <div css={TopTitleContainer}>
                    <Container css={WishTitle}>THANK YOU! </Container>
                    <Container className='mt-2' css={SubTitle}>
                        We’re getting started on your order now and you will
                        receive an order confirmation email to
                        {` ${email}.`}
                    </Container>
                    {/* <Container className='mt-5 mb-1' css={PrintContainer}>
                        <Print className='mr-1' />
                        <span>Print a receipt</span>
                    </Container> */}
                </div>
                <Container css={ContainerBorder} style={{ marginTop: 20 }}>
                    <OrderId orderId={id} />
                    <AddressComponent
                        name={`${firstName} ${lastName}`}
                        address1={`${address1},`}
                        address2={`${address2} ${city} ${postCode} ${state}, ${country}`}
                        email={`${email}`}
                        phoneNumber={`${phoneNumber}`}
                        billingAddress='Same as shipping'
                        paymentMethod={mappedPaymentMethod}
                    />
                    <ProductList products={products} />
                    <CustomerRemarks remarks={customerRemarks} />

                    <OrderSummary
                        ShippingCost={shippingFee}
                        SubTotal={totalPrice}
                        currency={currency}
                    />
                </Container>
            </Container>
        </View>
    );
};

const mapStateToProps = (state: RootState) => ({
    orderDetails: Selectors.getUiOrderDetails(state),
    loading: Selectors.getUiGetOrderDetailsAttempting(state),
    error: Selectors.getUiGetOrderDetailsError(state),
    authToken: Selectors.getAuthAuthToken(state),
    userId: Selectors.getUserId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrderDetails: (orderId: string) => dispatch(Actions.uiGetOrderDetails({ orderId })),
    clearOrder: () => dispatch(Actions.uiClearOrder()),
    clearCart: () => dispatch(Actions.cartClearCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
