/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';

const AboutUs = (): JSX.Element => {
    return (
        <div css={styles.container}>
            <div css={styles.title}>
                Terms and Conditions
            </div>

            <div css={styles.text}>
                <div>
                    在 勝峰珠寶 Seng Fong Jewellery 网站购买下单之前请看以下的所有通告，以免发生不愉快事件 .
                    本店賣的都是真貨黄金饰品！ 多数916 / 999. 本店是没店面，只是这个页面售卖金饰！
                </div>

                <br />
                <div>
                    1) 为什么价格如此便宜答：我们是网络买卖，所以省水电，昂贵的租金。
                </div>

                <br />
                <div>
                    2）不二價， 金价将由您当天所买当天所询问价格为实价，金价上升下降并不影响您之前的订单。(比如您是在230时候定的东西，过了一个星期东西才拿到那么将根据您定的时候价格计算)。
                </div>

                <br />
                <div>
                    3）禁完美主义者，金饰品是一个个制作，难免会有点小瑕疵，在意者勿拍. 尤其是亮面的饰品。
                </div>

                <br />
                <div>
                    4）由于金饰品的重量是以非常轻微的重量计算，每间店面所称出来的重量会相差0.02左右，介意者勿下单购买。
                </div>

                <br />
                <div>
                    5）禁急单。运输公司并非我司控制，请顾客们谅解。
                </div>

                <br />
                <div>
                    6）注意由于讯息常常爆满，助理将会尽快(48小时之内)回复你，助理将会又下方开始查看，所以不需要重复发送讯息以免你的讯息在上面导致你的讯息被忽略。除非已经过了48小时未回复。
                </div>

                <br />
                <div>
                    7）一切交易都由郵寄交易，沒提供面交 (所有的价钱均不包括运费)。
                </div>

                <br />
                <div>
                    8）关于烤漆及变色问题，镀色与变色都是同样把一层颜色镀上去，至于变色则是由多种颜色及药水做成的变色效果。变色效果显不显着及均不均匀这些都会因为温度及种种问题导致。
                </div>

                <br />
                <div>
                    9）毕竟变色，烤漆，电白等等，由于黄金本身就是金黄色，当然久而久之颜色将会慢慢脱离呈现出黄金色。(脱色情况以个人佩戴及照顾状况有所改变)。
                </div>

                <br />
                <div>
                    10）寄货有什么特别要求请记得在Checkout前的Remarks里写下您的要求。
                </div>

                <br />
                <div>
                    在金饰品吊坠背面会有小孔或者是(水沟)这个是为了让多余的金流出，水沟是让金饰品达到更加轻，所以是正常的～～～。
                </div>

                <br />
                <div>
                    12）烤漆相差的重量都会计算在重量(视烤漆的范围(多 / 少)(轻 / 重)。
                </div>

                <br />
                <div>
                    13）所有弹勾效果的产品将会使用钢丝或许铁丝来制成spring效果，此乃正常范围。
                </div>

                <br />
                <div>
                    14）有些饰品会有(钻 / 珍珠 / 配件)，有些饰品是会扣除重量，有些将会计算在金饰品。
                </div>

                <br />
                <div>
                    祝你愉快购物^^ 勝峰珠寶 Seng Fong Jewellery紧急电话号码:014-329 2282/011-2618 6599(紧急不包括询问)。
                </div>

                <br />
                <div>
                    如发现物品价格错误
                    店家有权退款销售商品。
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: css({
        margin: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }),
    title: css({
        fontFamily: Fonts.primaryBold,
        fontSize: 25,
    }),
    text: css({
        marginTop: 25,
        fontFamily: Fonts.primary,
        fontSize: 16,
        maxWidth: 650,
    }),
};

export default AboutUs;
