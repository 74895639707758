import React from 'react';
import { View, Text } from 'react-native-web';

import Fonts from 'Themes/fonts';
import colors from 'Themes/colors';
import { SadEmoji } from 'Icons/Icons';

const unsubscribedScreen = (): JSX.Element => {
    return (
        <View style={unsubscribeStyles.mainContainer}>
            <SadEmoji style={{ height: 120, width: 120 }} />

            <div style={unsubscribeStyles.bottomContainer}>
                <Text style={unsubscribeStyles.mainTextStyle}>
                    We are sorry to see you go...
                </Text>
                <Text style={unsubscribeStyles.subTextStyle}>
                    You have been unsubscribed successfully!
                </Text>
            </div>
        </View>
    );
};

const unsubscribeStyles = {
    mainContainer: {
        height: '100vh',
        width: '100%',
        flex: 1,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.background,
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    } as const,
    mainTextStyle: {
        fontFamily: Fonts.primaryBold,
        fontSize: 25,
    },
    subTextStyle: {
        fontFamily: Fonts.primary,
        fontSize: 16,
        marginTop: 20,
    },
};

export default unsubscribedScreen;
