import { css } from '@emotion/react';
import colors from '../../../Themes/colors';

const { black, orange, white } = colors;

export const AccountTitle = css({
    padding: '0px 40px',
    marginBottom: '20px',
    font: '23px Archivo-Bold, serif',
    color: black,
});

export const AccountLabel = css({
    font: '14px Archivo-Regular, serif',
    fontWeight: 400,
    color: black,
});

export const AccountSubTitle = css({
    padding: '0px 40px',
    marginBottom: '20px',
    marginTop: 20,
    font: '16px Archivo-Regular, serif',
    fontWeight: 500,
    color: black,
});

export const SaveButton = css({
    font: '18px Archivo-Regular, sans-serif',
    color: white,
    backgroundColor: orange,
    borderColor: orange,
    '&:hover': { background: white, borderColor: orange, color: orange },
    padding: '10px 20px',
});

export const CancelButton = css({
    font: '18px Archivo-Regular, sans-serif',
    color: orange,
    backgroundColor: white,
    borderColor: orange,
    '&:hover': { background: orange, borderColor: orange, color: white },
    padding: '10px 20px',
});

export const AccountFormGroup = css({
    height: '95px',
    marginBottom: '0px',
});
