/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';

import { ActivityIndicator } from 'react-native-web';
import { connect } from 'react-redux';

import { ICartProduct } from '@ddot/AddToCartCommon';

import ErrorMessage from 'Components/Common/ErrorMessage';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { IGoldPrice } from 'Redux/slices/home/Entities';
import { RootState } from 'Redux/store';

import NavActions from 'Services/Navigation/Actions';

import { Checkout } from 'Icons/Icons';
import Utils from 'Lib/Utils';

import {
    buttonIcons,
    checkoutButton,
    checkoutButtonLoading,
} from 'Containers/Products/Styles/ProductDetailsStyles';
import { LoginThunkParams } from 'Redux/slices/auth/controllers/login';
import {
    cartOrderSummaryContainer,
    cartOrderSummaryRows,
    cartOrderSummaryText,
    cartOrderSummaryTitle,
    cartOrderSummaryColumnRight,
    orderTotal,
    promoCodeInput,
    redeemButton,
} from '../Styles/CartStyles';

interface CartProps {
    cartItems: ICartProduct[];
    shippingFee: number;
    currency: string;
    // promoCode: string;
    goldPrice: IGoldPrice;
    stockCheck(products: ICartProduct[], callback: () => void): void;
    stockCheckLoading: boolean;
    stockCheckError: string;
}

const CartOrderSummary = (props: CartProps): JSX.Element | null => {
    const {
        shippingFee,
        currency,
        cartItems,
        goldPrice,
        stockCheck,
        stockCheckLoading,
        stockCheckError,
    } = props;

    if (!cartItems.length) return null;

    let subTotal = 0;

    cartItems.forEach(item => {
        const { selectedVariant, quantity } = item;
        const variantPrice = Utils.Jemisys.getItemVariantPrice(item, selectedVariant, goldPrice);
        subTotal += (variantPrice * quantity);
    });

    const totalCost = subTotal + shippingFee;

    const onProceed = () => {
        if (!stockCheckLoading) {
            stockCheck(cartItems, () => {
                NavActions.navToShippingAddress();
                window.scrollTo(0, 0);
            });
        }
    };

    const checkoutButtonStyle = stockCheckLoading ? checkoutButtonLoading : checkoutButton;

    return (
        <div css={cartOrderSummaryContainer}>
            <div css={cartOrderSummaryTitle}>Order Summary</div>
            <hr
                css={{
                    width: '100%',
                    border: '1px solid #BF7247',
                    marginBottom: '1.2em',
                    '@media(min-width:576px)': {
                        marginBottom: '3.6em',
                    },
                }}
            />
            <Row css={cartOrderSummaryRows}>
                <Col xs={7} sm={8} css={cartOrderSummaryText}>
                    Sub Total
                </Col>
                <Col
                    xs={5}
                    sm={4}
                    css={[
                        cartOrderSummaryText,
                        cartOrderSummaryColumnRight,
                    ]}
                >
                    {`${Utils.Formatter.currencyFormatter(subTotal)} ${currency}`}
                </Col>
            </Row>
            <Row css={cartOrderSummaryRows}>
                <Col xs={7} sm={8} css={cartOrderSummaryText}>
                    Shipping Cost
                </Col>
                <Col
                    xs={5}
                    sm={4}
                    css={[
                        cartOrderSummaryText,
                        cartOrderSummaryColumnRight,
                    ]}
                >
                    {`${Utils.Formatter.currencyFormatter(shippingFee)} ${currency}`}
                </Col>
            </Row>
            <Row css={cartOrderSummaryRows}>
                <Col xs={12} css={cartOrderSummaryText}>
                    Promo Code
                </Col>
                <Col xs={7} className='pr-0'>
                    <Input css={promoCodeInput} placeholder='Enter your code' />
                </Col>
                <Col xs={5} css={cartOrderSummaryColumnRight}>
                    <Button css={redeemButton}>Redeem</Button>
                </Col>
            </Row>
            <hr
                css={{
                    width: '100%',
                    border: '1px solid #BF7247',
                    marginBottom: '0.6em',
                    '@media(min-width:576px)': {
                        marginBottom: '3.6em',
                    },
                }}
            />
            <Row css={cartOrderSummaryRows}>
                <Col xs={5} className='pr-0' css={orderTotal}>
                    Total Cost
                </Col>
                <Col xs={7} css={[orderTotal, cartOrderSummaryColumnRight]}>
                    {`${Utils.Formatter.currencyFormatter(totalCost)} ${currency}`}
                </Col>
                <Col xs={12}>
                    <ErrorMessage error={stockCheckError} />
                    <Button
                        css={[checkoutButtonStyle, { width: '100% !important' }]}
                        onClick={onProceed}
                    >
                        {stockCheckLoading ? (
                            <ActivityIndicator color='white' />
                        ) : (
                            <>
                                <Checkout css={buttonIcons} />
                                CHECKOUT
                            </>
                        )}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    cartItems: Selectors.getCartItems(state),
    shippingFee: Selectors.getCartShippingFee(state),
    currency: Selectors.getCartCurrency(state),
    promoCode: Selectors.getCartPromoCode(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
    stockCheckLoading: Selectors.getUiStockCheckAttempting(state),
    stockCheckError: Selectors.getUiStockCheckError(state),
    loading: Selectors.getAuthLoginAttempting(state),
    error: Selectors.getAuthLoginError(state),
    forgetPasswordEmailSent: Selectors.getUiAuthIsEmailSent(state),
    forgetPasswordError: Selectors.getAuthForgetPasswordError(state),
    forgetPasswordLoading: Selectors.getAuthForgetPasswordAttempting(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    stockCheck: (products: ICartProduct[], callback: () => void) => dispatch(Actions.uiStockCheck({ products, callback })),
    login: (params: LoginThunkParams) => dispatch(Actions.authLogin(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartOrderSummary);
