/** @jsxImportSource @emotion/react */
import React from 'react';
import { Row, Col } from 'reactstrap';

import { Edit } from 'Icons/Icons';

import colors from 'Themes/colors';

import {
    orderReviewSectionTitleContainer,
    orderReviewSectionTitle,
    orderReviewEditText,
} from '../Styles/OrderReviewStyles';

interface PropType {
    title: string;
    onClick(): void;
    children: JSX.Element;
}

const OrderReviewSection = (props: PropType): JSX.Element => {
    const { title, onClick, children } = props;

    const onPress = () => {
        window.scrollTo(0, 0);
        onClick();
    };

    const renderDivider = () => {
        return <hr css={{ width: '100%', backgroundColor: colors.disabledGray, border: `1px solid ${colors.disabledGray}` }} />;
    };

    return (
        <div>
            <Row>
                <Col css={orderReviewSectionTitleContainer}>
                    <div css={orderReviewSectionTitle}>
                        {title}
                    </div>
                    <div
                        css={{ cursor: 'pointer' }}
                        onClick={onPress}
                        onKeyPress={onPress}
                        role='button'
                        tabIndex={0}
                    >
                        <Edit />
                        <span css={orderReviewEditText}>Edit</span>
                    </div>
                </Col>
            </Row>
            {children}
            {renderDivider()}
        </div>
    );
};

export default OrderReviewSection;
