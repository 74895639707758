import { createAsyncThunk } from '@reduxjs/toolkit';
import Selectors from 'Redux/Selectors';
import NavActions from 'Services/Navigation/Actions';
import uiSlice from 'Redux/slices/ui';

import { AsyncThunkAPI } from 'Redux/types';
import { IProduct } from '@ddot/AddToCartCommon';

export interface IFilterProductsParams {
    name: 'Purity' | 'Weight' | 'Length' | 'Size',
    type: 'ENUM' | 'RANGE',
    valuesSelected?: string[],
    min?: number,
    max?: number,
}

export interface IFilterProductsThunkParams {
    filters: IFilterProductsParams[]
}

export default createAsyncThunk<
    void,
    IFilterProductsThunkParams,
    AsyncThunkAPI
>('ui/filterProducts', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const uiProductList = Selectors.getUiProductList(state);
    const productsProductList = Selectors.getProductsList(state);
    let productList: IProduct[] = [];

    if (uiProductList.length) {
        productList = uiProductList;
    } if (!uiProductList.length && productsProductList.length) {
        productList = productsProductList;
    } if (!uiProductList.length && !productsProductList.length) {
        return thunkAPI.rejectWithValue('Something went wrong. Please try again later');
    }

    const { filters } = params;

    if (!filters.length) return NavActions.navToProductListing();

    const productsToBeFiltered: IProduct[] = productList.map(product => product);

    const purityFilter = filters.find(item => item.name === 'Purity');
    const weightFilter = filters.find(item => item.name === 'Weight');
    const lengthFilter = filters.find(item => item.name === 'Length');
    const sizeFilter = filters.find(item => item.name === 'Size');

    const filterPurity = (products: IProduct[]) => {
        if (purityFilter?.valuesSelected?.length && products.length) {
            const filteredProducts = products.filter(product => {
                let filterPassed = false;

                product.filterAttributes?.map(attribute => {
                    if (attribute.name === 'Purity' && attribute.type === 'ENUM') {
                        const puritiesMatch = attribute.value.map(item => {
                            if (purityFilter.valuesSelected?.length && purityFilter.valuesSelected?.includes(item)) {
                                return true;
                            } return false;
                        });

                        if (puritiesMatch.includes(true)) {
                            filterPassed = true;
                        } else filterPassed = false;
                    } return false;
                });

                if (filterPassed) {
                    return product;
                } return false;
            });

            return filteredProducts;
        } return products;
    };

    const filterWeight = (products: IProduct[]) => {
        if (weightFilter?.min && weightFilter?.max && products.length) {
            const filteredProducts = products.filter(product => {
                let filterPassed = false;

                product.filterAttributes?.map(attribute => {
                    if (attribute.name === 'Weight' && attribute.type === 'RANGE') {
                        const weightsMatch = attribute.value.map(item => {
                            if (weightFilter?.min && weightFilter?.max) {
                                if (item >= weightFilter.min && item <= weightFilter.max) return true;
                            } return false;
                        });

                        if (weightsMatch.includes(true)) {
                            filterPassed = true;
                        } else filterPassed = false;
                    } return false;
                });

                if (filterPassed) {
                    return product;
                } return false;
            });

            return filteredProducts;
        } return products;
    };

    const filterLength = (products: IProduct[]) => {
        if (lengthFilter?.min && lengthFilter?.max && products.length) {
            const filteredProducts = products.filter(product => {
                let filterPassed = false;

                product.filterAttributes?.map(attribute => {
                    if (attribute.name === 'Length' && attribute.type === 'RANGE') {
                        const lengthsMatch = attribute.value.map(item => {
                            if (lengthFilter?.min && lengthFilter?.max) {
                                if (item >= lengthFilter.min && item <= lengthFilter.max) return true;
                            } return false;
                        });

                        if (lengthsMatch.includes(true)) {
                            filterPassed = true;
                        } else filterPassed = false;
                    } return false;
                });

                if (filterPassed) {
                    return product;
                } return false;
            });

            return filteredProducts;
        } return products;
    };

    const filterSize = (products: IProduct[]) => {
        if (sizeFilter?.min && sizeFilter?.max && products.length) {
            const filteredProducts = products.filter(product => {
                let filterPassed = false;

                product.filterAttributes?.map(attribute => {
                    if (attribute.name === 'Size' && attribute.type === 'RANGE') {
                        const sizesMatch = attribute.value.map(item => {
                            if (sizeFilter?.min && sizeFilter?.max) {
                                if (item >= sizeFilter.min && item <= sizeFilter.max) return true;
                            } return false;
                        });

                        if (sizesMatch.includes(true)) {
                            filterPassed = true;
                        } else filterPassed = false;
                    } return false;
                });

                if (filterPassed) {
                    return product;
                } return false;
            });

            return filteredProducts;
        } return products;
    };

    const filteredProducts = filterSize(filterLength(filterWeight(filterPurity(productsToBeFiltered))));

    if (filteredProducts) {
        thunkAPI.dispatch(uiSlice.actions.uiSetFilteredProducts(filteredProducts));
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again');
});
