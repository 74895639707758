import React from 'react';

interface ClickableProps {
    children: JSX.Element,
    onClick(): void;
}

const Clickable = (props: ClickableProps): JSX.Element => {
    const { onClick, children } = props;

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === 'Spacebar') {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <div
            tabIndex={0}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            role='button'
        >
            {children}
        </div>
    );
};

export default Clickable;
