/** @jsxImportSource @emotion/react */
import React from 'react';
import { InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Pressable, ActivityIndicator } from 'react-native-web';

import { SerializedStyles } from '@emotion/serialize';
import { css } from '@emotion/react';

import { Search } from 'Icons/Icons';

interface SearchbarProps {
    placeholder: string;
    onChange(value: string): void;
    onKeyDown(value: React.KeyboardEvent): void;
    onClicked(): void;
    loading: boolean;
    value: string;
    mainCss?: SerializedStyles;
    inputCss?: SerializedStyles;
}

const Searchbar = (props: SearchbarProps): JSX.Element => {
    const {
        placeholder, value, onChange, onKeyDown, onClicked, mainCss, inputCss, loading,
    } = props;

    const mainStyle = { ...styles.container, ...mainCss };
    const searchbarStyle = { ...styles.searchbar, ...inputCss };

    const onEnterPressed = (e: React.KeyboardEvent) => {
        if (!loading) onKeyDown(e);
    };

    return (
        <div css={mainStyle}>
            <InputGroup css={searchbarStyle}>
                <Input
                    css={searchbarStyle}
                    type='text'
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyDown={onEnterPressed}
                />
                <InputGroupAddon addonType='append'>
                    <InputGroupText css={styles.addon}>
                        <Pressable style={styles.button} onPress={onClicked} disabled={loading}>
                            {loading ? (
                                <ActivityIndicator />
                            ) : <Search />}
                        </Pressable>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
};

const styles = {
    container: css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    searchbar: css({
        width: '150px',

        '@media(max-width: 320px)': {
            width: '110px',
        },
        '@media(min-width: 375px)': {
            width: '175px',
        },
        '@media(min-width: 1080px)': {
            width: '200px',
        },
    }),
    addon: css({
        width: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
    }),
    button: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        height: '100%',
    },
};

Searchbar.defaultProps = {
    inputCss: {},
    mainCss: {},
};

export default Searchbar;
