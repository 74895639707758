import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseCaseResponseStatus } from '@ddot/AddToCartCommon';

import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';

import Utils from 'Lib/Utils';
import Toast from 'Lib/Toast';

import { ErrorTypes } from '.';

interface UpdatePasswordThunkParams {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export default createAsyncThunk<
    null,
    UpdatePasswordThunkParams,
    AsyncThunkAPI
>('user/updatePassword', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const authToken = Selectors.getAuthAuthToken(state);

    const { oldPassword, newPassword, confirmPassword } = params;

    if (!oldPassword || !newPassword || !confirmPassword) {
        return thunkAPI.rejectWithValue('Please fill in your old and new password.');
    }

    const passwordValidateError = Utils.Validator.validatePassword(newPassword);

    if (passwordValidateError) return thunkAPI.rejectWithValue(passwordValidateError);

    if (newPassword !== confirmPassword) {
        return thunkAPI.rejectWithValue('Passwords do not match. Please try again.');
    }

    const response = await thunkAPI.extra.useCases.user.updatePassword.execute({
        authToken,
        data: {
            oldPassword, newPassword,
        },
    });

    if (response.status === UseCaseResponseStatus.Success) {
        Toast.show('Password updated');

        return null;
    }

    const { type, title } = response.data;
    const { UpdatePasswordError } = ErrorTypes;

    if (type === UpdatePasswordError.InvalidUserInfo) {
        return thunkAPI.rejectWithValue('Account is invalid. Please refresh the page and login again.');
    }

    return thunkAPI.rejectWithValue(title || 'Something went wrong. Please try again.');
});
