/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, KeyboardEvent } from 'react';
import { connect } from 'react-redux';

import { Col, FormGroup, Label, Input } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'Components/Button';
import PageSkeleton from 'Components/Skeletons/PageSkeleton';
import ErrorMessage from 'Components/Common/ErrorMessage';

import Utils from 'Lib/Utils';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import styles from './styles';

interface UrlParams {
    hash: string;
}

interface ResetPasswordProps extends RouteComponentProps<UrlParams> {
    loading: boolean;
    error: string;
    verifyHash(hash: string): void;
    submitNewPassword(password: string): void;
    resetPasswordLoading: boolean,
    resetPasswordError: string,
}

const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
    const { loading, error, match, verifyHash, submitNewPassword, resetPasswordLoading, resetPasswordError } = props;
    const { hash } = match.params;

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPasswordValidationError, setResetPasswordValidationError] = useState('');
    const [passwordSubmitted, setPasswordSubmitted] = useState(false);

    useEffect(() => {
        verifyHash(hash);
    }, []);

    const onProceed = () => {
        if (password && confirmPassword) {
            if (password !== confirmPassword) {
                setResetPasswordValidationError('Password does not match.');
                return false;
            }

            const passwordValidationError = Utils.Validator.validatePassword(confirmPassword);

            if (passwordValidationError) {
                return setResetPasswordValidationError(passwordValidationError);
            }

            setPasswordSubmitted(true);
            return submitNewPassword(confirmPassword);
        } return setResetPasswordValidationError('Please enter password');
    };

    const onEnterPressed = (e: KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        if (key === 'Enter') onProceed();
    };

    const renderBody = () => {
        if (loading) return <PageSkeleton />;
        if (error) return <ErrorMessage error={error} />;

        return (
            <div>
                <Col md={6} lg={4}>
                    <FormGroup disabled={passwordSubmitted} className='pl-4 pr-4'>
                        <Label css={styles.label}>New Password</Label>
                        <Input
                            type='password'
                            name='newpassword'
                            value={password}
                            onChange={(e) => {
                                setResetPasswordValidationError('');
                                setPassword(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={6} lg={4}>
                    <FormGroup className='pl-4 pr-4'>
                        <Label css={styles.label}>
                            Confirm New Password
                        </Label>
                        <Input
                            type='password'
                            name='confirmnewpassword'
                            value={confirmPassword}
                            onChange={e => {
                                setResetPasswordValidationError('');
                                setConfirmPassword(e.target.value);
                            }}
                            onKeyDown={onEnterPressed}
                        />
                    </FormGroup>
                </Col>
                {resetPasswordValidationError ? <ErrorMessage error={resetPasswordValidationError} style={{ marginLeft: 40, marginTop: 10, width: 200 }} /> : false}
                <Button
                    text='Reset password'
                    onClick={onProceed}
                    loading={resetPasswordLoading}
                    error={resetPasswordError}
                    style={{ marginLeft: 40, marginTop: 10, width: 200 }}
                />
            </div>
        );
    };

    return (
        <div css={styles.container}>
            <div css={styles.title}>
                Reset Password
            </div>
            {renderBody()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthVerifyForgetPasswordAttempting(state),
    error: Selectors.getAuthVerifyForgetPasswordError(state),
    resetPasswordLoading: Selectors.getAuthResetPasswordAttempting(state),
    resetPasswordError: Selectors.getAuthResetPasswordError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    verifyHash: (hash: string) => dispatch(Actions.authVerifyForgetPassword({ hash })),
    submitNewPassword: (password: string) => dispatch(Actions.authResetPassword({ password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
