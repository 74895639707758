/** @jsxImportSource @emotion/react */
import { ProductListItemStyle } from 'Containers/User/Styles/ComponentStyles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Selectors from 'Redux/Selectors';
import Actions from 'Redux/Actions';
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { editableQuantity, quantityButton, quantityDropdown } from '../Styles/CartStyles';

const EditableQuantity = (props) => {
    const { quantity, onQuantityChanged, product, stockCheckLoading } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    };

    const onRemoveItem = () => {
        const { removeFromCart } = props;
        removeFromCart({ product });
    };

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const minus = () => {
        if (quantity > 1) onQuantityChanged(quantity - 1);
        if (quantity === 1) toggleModal();
    };

    const plus = () => onQuantityChanged(quantity + 1);

    const renderRemoveItemFromCartModal = () => {
        return (
            <div>
                <Modal isOpen={modal} toggle={toggleModal} size='sm'>
                    <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                        Confirm remove
                        {' '}
                        {product ? product.name : 'item'}
                        ?
                    </ModalHeader>
                    <ModalBody style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button color='secondary' style={{ marginRight: '20px' }} onClick={toggleModal}>
                            Cancel
                        </Button>
                        <Button color='danger' onClick={onRemoveItem}>
                            Confirm
                        </Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    };

    return (
        <div css={ProductListItemStyle}>
            <Row>
                <Col
                    className='d-flex'
                    xs='12'
                    css={{
                        backgroundColor: '#F6F7F8',
                        borderRadius: '4px',
                        flexDirection: 'row',
                        padding: '0px',
                    }}
                >
                    <Button disabled={stockCheckLoading} css={quantityButton} onClick={minus}>
                        -
                    </Button>
                    <div css={editableQuantity}>{quantity}</div>
                    <Button disabled={stockCheckLoading} css={quantityButton} onClick={plus}>
                        +
                    </Button>
                </Col>
            </Row>
            {renderRemoveItemFromCartModal()}
        </div>
    );
};

const mapStateToProps = (state) => ({
    stockCheckLoading: Selectors.getUiStockCheckAttempting(state),
});

const mapDispatchToProps = (dispatch) => ({
    removeFromCart: (params) => dispatch(Actions.cartRemoveFromCart(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableQuantity);
