/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button, Carousel, CarouselIndicators, CarouselItem, Col, Container, Row } from 'reactstrap';
import CarouselLov from 'Lib/LOVs/Carousel';
import PlaceholderImage from 'Assets/Images/PlaceholderImage';

import {
    carouselItemContainer, carouselSlideTitle, carouselSlideDesc, carouselSlideButton, carouselIndicator, carouselSlideContainer,
} from '../Styles/CarouselRowStyles';

const CarouselRow = (): JSX.Element | null => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [animating, setAnimating] = useState<boolean>(false);

    if (!CarouselLov || !CarouselLov.length) return null;

    const handleImageError = (e: any) => {
        e.target.src = PlaceholderImage.NoImagePlaceholder;
    };

    const items = CarouselLov.map((item) => {
        const { name, description, imageUrl, linkTo } = item;

        return (
            <CarouselItem
                css={carouselItemContainer}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`${name}${imageUrl}`}
            >
                <img
                    css={{ objectFit: 'contain', width: '100%', height: '100%' }}
                    src={imageUrl}
                    alt={description}
                    onError={(e) => handleImageError(e)}
                />
            </CarouselItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === CarouselLov.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? CarouselLov.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    return (
        <div style={{ width: '100%', justifyContent: 'center' }}>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={10000}
                slide
                enableTouch
            >
                <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                    css={carouselIndicator}
                />
                {items}
            </Carousel>
        </div>
    );
};

export default CarouselRow;
