import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';
import colors from 'Themes/colors';

const promoBar = css({
    padding: '12px 0px',
    backgroundColor: colors.mildbrown,
    textAlign: 'center',
    height: 45,
});

const promoBarText = css({
    font: Fonts.primary,
    fontSize: 14,
    color: colors.white,

    '@media(min-width: 320px)': {
        fontSize: 10,
    },

    '@media(min-width: 360px)': {
        fontSize: 12,
    },

    '@media(min-width: 720px)': {
        fontSize: 14,
    },
});

export default {
    promoBar,
    promoBarText,
};
