import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';
import { IOrderPaymentMethodEnum, UseCaseResponseStatus, ICartProduct } from '@ddot/AddToCartCommon';

import Selectors from 'Redux/Selectors';
import Utils from 'Lib/Utils';
import uiSlice from 'Redux/slices/ui';

import NavActions from 'Services/Navigation/Actions';
import productGetProducts from 'Redux/slices/products/controllers/getProducts';

import { OrderErrorTypes } from '.';

interface IPlaceOrderThunkParams {
    totalPrice: number;
    paymentMethod: IOrderPaymentMethodEnum;
    shippingFee: number;
}

export default createAsyncThunk<
    null,
    IPlaceOrderThunkParams,
    AsyncThunkAPI
>('cart/placeOrder', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const { totalPrice } = params;

    const userInfo = Selectors.getUserInfo(state);
    const cartItems = Selectors.getCartItems(state);
    const preMassagedShippingAddress = Selectors.getCartShippingAddress(state);
    const shippingFee = Selectors.getCartShippingFee(state);
    const remarks = Selectors.getCartCustomerRemarks(state);
    const currency = Selectors.getCartCurrency(state);
    const goldPrice = Selectors.getHomeGoldPrice(state);
    const authToken = Selectors.getAuthAuthToken(state);

    const checkedOutItems: ICartProduct[] = Utils.Jemisys.getAllCheckedOutItems(cartItems);

    if (!preMassagedShippingAddress) {
        return thunkAPI.rejectWithValue('Invalid address. Please update your address.');
    }

    if (!userInfo || !userInfo.id) {
        return thunkAPI.rejectWithValue('Please login first.');
    }

    const { ...shippingAddress } = preMassagedShippingAddress;

    const response = await thunkAPI.extra.useCases.order.placeOrder.execute({
        userInfo,
        products: checkedOutItems,
        totalPrice,
        shippingFee,
        remarks,
        currency,
        shippingAddress,
        goldPrice,
        authToken,
    });

    if (response.status === UseCaseResponseStatus.Success) {
        thunkAPI.dispatch(uiSlice.actions.uiSetOrderId(response.data.orderId));
        // we refresh the product listing
        thunkAPI.dispatch(productGetProducts());

        window.scrollTo(0, 0);
        NavActions.navToPayment();
        return null;
    }

    const { type, message } = response.data;

    if (type === OrderErrorTypes.PlaceOrderError.NoItems) {
        return thunkAPI.rejectWithValue('There are no items in your cart. Please add at least one item before proceeding.');
    }

    if (type === OrderErrorTypes.PlaceOrderError.NoUserInfo) {
        return thunkAPI.rejectWithValue('No user info found. Please try again.');
    }

    return thunkAPI.rejectWithValue(message || 'Something went wrong. Please try again.');
});
