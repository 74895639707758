import { IProduct, ISort, SortMethods } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Utils from 'Lib/Utils';
import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';
import Toast from 'Lib/Toast';

interface ISortProductsResponse {
    sort: ISort;
    products: IProduct[];
}

export default createAsyncThunk<
    ISortProductsResponse, // this is the response type
    ISort, // this is the param type
    AsyncThunkAPI // will be always the same
>('ui/sortProducts', async (params, thunkAPI) => {
    const { name, isAscending } = params;
    const state = thunkAPI.getState();
    const productList = Selectors.getProductsList(state).slice();
    const uiProductList = Selectors.getUiProductList(state).slice();

    const productsToRender = uiProductList.length ? uiProductList : productList;

    const sortPrice = () => {
        let newProductList: IProduct[];

        if (isAscending) {
            newProductList = productsToRender.sort((a, b) => {
                const aMinPrice = Utils.Product.getMinPrice(a.customAttributes);
                const bMinPrice = Utils.Product.getMinPrice(b.customAttributes);

                if (aMinPrice === null || bMinPrice === null) return 0;

                if (aMinPrice < bMinPrice) return -1;
                if (aMinPrice > bMinPrice) return 1;
                return 0;
            });
        } else {
            newProductList = productsToRender.sort((a, b) => {
                const aMinPrice = Utils.Product.getMinPrice(a.customAttributes);
                const bMinPrice = Utils.Product.getMinPrice(b.customAttributes);

                if (aMinPrice === null || bMinPrice === null) return 0;

                if (aMinPrice > bMinPrice) return -1;
                if (aMinPrice < bMinPrice) return 1;
                return 0;
            });
        }

        return newProductList;
    };

    try {
        let sortedProducts: IProduct[] = [];

        if (name === SortMethods.Price) sortedProducts = sortPrice();

        return {
            sort: params,
            products: sortedProducts,
        };
    } catch (error: any) {
        const errorMessage = error || 'Something went wrong. Please try again.';

        Toast.showError(errorMessage);
        return thunkAPI.rejectWithValue(errorMessage);
    }
});
