/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';

const AboutUs = (): JSX.Element => {
    return (
        <div css={styles.container}>
            <div css={styles.title}>
                About Us
            </div>

            <div css={styles.text}>
                勝峰珠寶成立于2017年，拥有自家工厂生产的一条线（设计-制作-批发-网络零售）。
                勝峰珠寶主要经营网络零售市场
                马来西亚无实体店经营方式，在多年来收集了大量客户的信任。
                我们的优势-第一货源，价格实惠，诚信经营，专注高品质黄金。杜绝假冒伪劣商品，对消费者负责。

                <br />
                <br />
                <br />

                Seng Fong Jewellery was established in 2017 and operates its own factory encompassing a production line (design-production-wholesale-online retail).
                Seng Fong Jewellery specializing in the online retail market,  and doesn&apos;t have physical stores in Malaysia but has garnered the trust of a substantial customer base over the years.
                Our advantages lie in prompt delivery, ample supply, competitive prices, transparent management, and a dedication to high-quality gold.
                We are committed to eliminating counterfeit and inferior products while upholding accountability to our consumers.
            </div>
        </div>
    );
};

const styles = {
    container: css({
        height: '100%',
        margin: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }),
    title: css({
        fontFamily: Fonts.primaryBold,
        fontSize: 25,
    }),
    text: css({
        marginTop: 25,
        fontFamily: Fonts.primary,
        fontSize: 16,
        maxWidth: 650,
    }),
};

export default AboutUs;
