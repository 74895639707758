/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'reactstrap';
import {
    RowFlexCenter,
    ProductListTitleStyle,
    Title,
} from '../Styles/ComponentStyles';

import ProductsListComponent from './ProductsListComponent';

function ProductList({ products }) {
    if (!products.length) return null;

    return (
        <div>
            <div className='mt-5' css={Title}>
                Order Review
            </div>
            <Row className='mt-4 d-none d-md-flex'>
                <Col xs='5'>
                    <div css={ProductListTitleStyle}>Product Details</div>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListTitleStyle}>Quantity</div>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListTitleStyle}>Price</div>
                </Col>
                {/* <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListTitleStyle}>Labour Cost</div> */}
                {/* </Col> */}
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListTitleStyle}>Total</div>
                </Col>
            </Row>
            {products.map((product) => (
                <ProductsListComponent key={product.id} product={product} />
            ))}
        </div>
    );
}

export default ProductList;
