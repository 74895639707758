import { css } from '@emotion/react';
import colors from '../../../Themes/colors';
import fonts from '../../../Themes/fonts';

export const orderSummaryContainer = css({
    padding: '0px 10px',
    backgroundColor: colors.white,
    marginTop: '40px',

    '@media(min-width:576px)': {
        padding: '0px 50px',
    },

    '@media(min-width:768px)': {
        padding: '0px 100px',
    },

    '@media(min-width:992px)': {
        padding: '0px 200px',
    },

    '@media(min-width:1200px)': {
        marginTop: '0px',
        padding: '0px 20px',
    },

    '@media(min-width:1400px)': {
        padding: '0px 40px',
    },
});

export const orderSummaryTitle = css({
    ...fonts.cartTitle,
    color: colors.primary,
    fontSize: 20,
});

export const orderSummaryText = css({
    ...fonts.orderSummaryText,
    fontSize: '15px',
    color: colors.black,
    paddingRight: '0px',

    '@media(min-width:576px)': {
        fontSize: '16px',
    },

    '@media(min-width:1400px)': {
        fontSize: '18px',
    },
});

export const orderSummaryColumnRight = css({
    textAlign: 'end',
    paddingLeft: '0px',
    paddingRight: '15px',
});

export const orderSummaryRows = css({
    '& .css-1p4rmct-OrderHistorySubItem': {
        width: '100%',
        padding: '15px',
    },

    '& .css-ps4swk-OrderHistorySubItem': {
        font: '18px Archivo-Medium, sans-serif',
        color: colors.black,
    },

    '& .css-ue6dge-OrderHistorySubItem, & .css-lvnu5m-OrderHistorySubItem': {
        fontSize: '16px',
        color: colors.black,
    },
});

export const checkBoxContainer = css({
    display: 'flex',
    alignItems: 'center',
    font: '15px Archivo-Regular, sans-serif',
    color: colors.black,
});

export const saveAccountContainer = css({
    borderRadius: '5px',
    backgroundColor: colors.lightbrown,
    padding: '20px',
    width: '100%',
    margin: '2.4em 15px',

    '@media(min-width:768px)': {
        padding: '30px 50px',
        margin: '2.4em 0px',
    },
});

export const saveAccountTitle = css({
    ...fonts.saveAccountTitle,
    color: colors.black,
});

export const saveAccountText = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '0.4em',
});

export const saveAccountLabel = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '1.2rem',
});

export const continuePaymentButton = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.primary,
    padding: '15px 40px',
    border: `2px solid ${colors.primary}`,
    backgroundColor: colors.white,
    margin: '15px 0px 1.2em',
    height: 54,
    width: 175,

    '&:hover,&:focus': {
        backgroundColor: colors.primary,
        color: colors.white,
        border: `2px solid ${colors.primary}`,
    },
});

export const continuePaymentButtonLoading = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.white,
    padding: '15px 40px',
    border: `2px solid ${colors.primary}`,
    backgroundColor: colors.primary,
    margin: '15px 0px 1.2em',
    height: 54,
    width: 175,

    '&:hover,&:focus': {
        backgroundColor: colors.primary,
        color: colors.white,
        border: `2px solid ${colors.primary}`,
    },
});
