import { css } from '@emotion/react';
import colors from '../../../Themes/colors';

const { black, lightbrown, lighterbrown, lightred, orange, white, red } = colors;

export const listingContainer = css({
    padding: '40px 120px',
});

export const productListingTitle = css({
    font: '70px FRL-Bold, serif',
    color: black,
    textAlign: 'center',
});

export const productListingOptionsContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const productListingOptionsText = css({
    font: '18px Archivo-Regular, sans-serif',
    display: 'inline-block',
});

export const productListingOptionsIcon = css({
    width: '27px',
});

export const productsContainer = css({
    padding: '100px 100px 60px',
});

export const productContainer = css({
    textAlign: 'center',
    margin: '30px 0px',
});

export const productPrice = css({
    font: '20px Archivo-Bold, sans-serif',
    color: '#BF7247',
    marginTop: '1.2em',
});

export const productName = css({
    font: '16px Archivo-Regular, sans-serif',
    color: black,
    marginTop: '1.2em',
});

export const productImg = css({
    width: '100%',
    height: 150,
    objectFit: 'contain',

    '@media(min-width:720px)': {
        height: 200,
    },
});

export const productRating = css({
    display: 'block !important',
    marginTop: '0.6em',
});

export const RemoveWishListButton = css({
    textTransform: 'uppercase',
    font: '12px Archivo-Regular, sans-serif',
    color: red,
    backgroundColor: white,
    border: `2px solid ${red}`,
    borderRadius: '0px',
    padding: '6px 22px',
    marginTop: '1.2rem',
    transition: 'ease 0.2s',
    '&:hover,&:focus': {
        border: `2px solid ${red}`,
        backgroundColor: red,
        fill: white,
    },
});

export const customPaginationText = css({
    font: '16px Archivo-Regular, sans-serif',
    color: black,
    '&:hover': {
        color: '#BF7247',
        backgroundColor: 'unset',
    },
});

export const ButtonContainer = css({
    display: 'flex',
    flexDirection: 'column',
});
