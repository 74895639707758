import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProducts, IProductDetails } from './Entities';

import getProducts, { IGetProductsResponse } from './controllers/getProducts';

const initialState: IProducts = {
    actions: {
        productList: true,
        productDetails: false,
        categories: true,
        collections: false,
        reviews: false,
    },
    productList: [],
    productDetails: undefined,
    collection: [],
    category: [],
    error: {
        productList: '',
        productDetails: '',
        categories: '',
        collections: '',
        reviews: '',
    },
};

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductDetailsAttempt: (state) => {
            state.actions.productDetails = true;
            state.error.productDetails = '';
        },
        setProductDetails: (state, action: PayloadAction<IProductDetails>) => {
            state.error.productDetails = '';
            state.productDetails = action.payload;
            state.actions.productDetails = false;
        },
        setProductDetailsError: (state, action: PayloadAction<string>) => {
            state.error.productDetails = action.payload;
            state.actions.productDetails = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProducts.pending, (state) => {
            state.actions.categories = true;
            state.actions.productList = true;
            state.error.categories = '';
            state.error.productList = '';
        });
        builder.addCase(getProducts.fulfilled, (state, action: PayloadAction<IGetProductsResponse>) => {
            state.actions.categories = false;
            state.actions.productList = false;

            if (action.payload) {
                state.category = action.payload.categories;
                state.productList = action.payload.products;
            }
        });
        builder.addCase(getProducts.rejected, (state, action) => {
            state.actions.categories = false;
            state.actions.productList = false;

            if (action.payload) {
                state.error.categories = action.payload;
                state.error.productList = action.payload;
            }
        });
    },
});

export default {
    actions: productSlice.actions,
    reducers: productSlice.reducer,
};
