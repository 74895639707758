/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import {
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import Selectors from 'Redux/Selectors';

import { IProduct, IProductCategory, ISort, SortMethods } from '@ddot/AddToCartCommon';
import Actions from 'Redux/Actions';
import { RootState } from 'Redux/store';

import FeaturedProduct from 'Containers/Home/Components/FeaturedProduct';
import ErrorMessage from 'Components/Common/ErrorMessage';

import {
    listingContainer,
    productListingOptionsContainer,
    productListingTitle,
    productsContainer,
    sortDropdownToggle,
} from '../Styles/ProductListingStyles';
import CustomPagination from './CustomPagination';
import Config from '../../../Config/Config';
import Filter from './Filter';

interface ListingProps {
    productList: IProduct[];
    sortedAndSearchedProducts: IProduct[];
    currentCategory: IProductCategory | null;
    sortProducts(sortCriteria: ISort): void;
    successSearchQuery: string;
    searchError: string | undefined;
    filteredProducts: IProduct[];
    categoryId: string;
    resetSearchedProducts(): void;
}

const Listing = (props: ListingProps): JSX.Element => {
    const {
        productList, sortedAndSearchedProducts, sortProducts, successSearchQuery, searchError, currentCategory, filteredProducts, categoryId, resetSearchedProducts,
    } = props;

    let categoryTitle = 'Jewellery';
    if (currentCategory) categoryTitle = currentCategory.name;

    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [products, setProducts] = useState<IProduct[]>([]);

    const [productsToDisplay, setProductsToDisplay] = useState(productList);

    const maxPages = Math.ceil(productsToDisplay.length / Config.MAX_NUMBER_OF_PRODUCTS_PER_PAGE);

    useEffect(() => {
        const start = (page - 1) * Config.MAX_NUMBER_OF_PRODUCTS_PER_PAGE;
        const end = page * Config.MAX_NUMBER_OF_PRODUCTS_PER_PAGE;

        const slicedProducts = productsToDisplay.slice(start, end);

        setProducts(slicedProducts);
    }, [page]);

    useEffect(() => {
        const start = (page - 1) * Config.MAX_NUMBER_OF_PRODUCTS_PER_PAGE;
        const end = page * Config.MAX_NUMBER_OF_PRODUCTS_PER_PAGE;

        const slicedProducts = productsToDisplay.slice(start, end);

        setProducts(slicedProducts);
        if (page !== 1) setPage(1);
    }, [productsToDisplay]);

    useEffect(() => {
        setProductsToDisplay(filteredProducts);
    }, [filteredProducts]);

    useEffect(() => {
        if (sortedAndSearchedProducts.length) setProductsToDisplay(sortedAndSearchedProducts);
        if (!sortedAndSearchedProducts.length) setProductsToDisplay([]);
    }, [sortedAndSearchedProducts]);

    const toggleSortDropdown = () =>
        setSortDropdownOpen((prevState) => !prevState);

    const onPageUpdate = (newPage: number) => {
        setPage(newPage);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const sortProductsByASC = () => {
        sortProducts({
            name: SortMethods.Price,
            isAscending: true,
        });
    };

    const sortProductsByDESC = () => {
        sortProducts({
            name: SortMethods.Price,
            isAscending: false,
        });
    };

    const productsToRender = products.map((product: IProduct) => {
        return (
            <Col xs={6} sm={4} md={3} key={product.id} className='p-0'>
                <FeaturedProduct product={product} />
            </Col>
        );
    });

    const renderFilterAndSort = () => {
        if (!successSearchQuery) {
            return (
                <Col xs={12} css={productListingOptionsContainer}>
                    <Filter categoryId={categoryId} />
                    <div hidden>
                        <Dropdown
                            isOpen={sortDropdownOpen}
                            toggle={toggleSortDropdown}
                            direction='left'
                        >
                            <DropdownToggle css={sortDropdownToggle}>
                                <div>
                                    <img
                                        src='/assets/icons/sort.svg'
                                        alt='Sort'
                                    />
                                    Sort
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={sortProductsByASC}>
                                    <div>
                                        Price: Low to High
                                    </div>
                                </DropdownItem>
                                <DropdownItem onClick={sortProductsByDESC}>
                                    <div>
                                        Price: High to Low
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Col>
            );
        } return false;
    };

    let titleToRender = successSearchQuery ? `"${successSearchQuery}"` : categoryTitle;

    if (titleToRender.length > 35) {
        titleToRender = `${titleToRender.slice(0, 35)}...`;
    }

    return (
        <Container fluid css={listingContainer}>
            <Row>
                <Col xs={12}>
                    <div css={productListingTitle}>{titleToRender}</div>
                    <hr css={{ width: '100%', border: '1px solid #BF7247' }} />
                </Col>
            </Row>
            {renderFilterAndSort()}
            <Row xs={1} sm={2} md={3} lg={4} css={productsContainer}>
                {!products.length ? <div>There seems to be nothing here...</div> : false}
                {searchError?.length ? <ErrorMessage error={searchError} /> : productsToRender}
            </Row>
            <Row css={{ justifyContent: 'center' }}>
                <Col xs='auto'>
                    <CustomPagination
                        currentPage={page}
                        onUpdatePage={onPageUpdate}
                        maxPages={maxPages}
                    />
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    searchError: Selectors.getUiSearchProductsError(state),
    successSearchQuery: Selectors.getUiSuccessSearchQuery(state),
    productList: Selectors.getProductsList(state),
    sortedAndSearchedProducts: Selectors.getUiProductList(state),
    currentCategory: Selectors.getUiProductCurrentCategory(state),
    filteredProducts: Selectors.getUiFilteredProducts(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    sortProducts: (sortCriteria: ISort) => dispatch(Actions.uiSortProducts(sortCriteria)),
    resetSearchedProducts: () => dispatch(Actions.uiResetProductList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
