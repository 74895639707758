/** @jsxImportSource @emotion/react */
import React from 'react';
import { Container, Row } from 'reactstrap';

const Banner = (props) => {
    const { imageUrl = '' } = props;

    if (!imageUrl) return null;

    return (
        <Container fluid style={{ width: '100%' }}>
            <Row
                css={{
                    background: `url(${imageUrl})`,
                    height: '200px',

                    '@media(min-width:576px)': {
                        height: '300px',
                    },

                    '@media(min-width:992px)': {
                        height: '400px',
                    },
                }}
            />
        </Container>
    );
};

export default Banner;
