import { css } from '@emotion/react';
import colors from 'Themes/colors';

export const carouselItemContainer = css({
    height: '300px',
    textAlign: 'center',
    backgroundColor: colors.background,

    '@media(min-width:576px)': {
        height: '300px',
    },

    '@media(min-width:992px)': {
        height: '400px',
    },

    '@media(min-width:1200px)': {
        height: '400px',
    },
});

export const carouselSlideContainer = css({
    position: 'absolute',
    top: '20%',

    '@media(min-width:576px)': {
        top: '10%',
    },

    '@media(min-width:768px)': {
        top: '-5%',
    },

    '@media(min-width:992px)': {
        top: '10%',
    },

    '@media(min-width:1200px)': {
        top: '10%',
    },
});

export const carouselSlideTitle = css({
    font: '40px FRL-Bold, serif',
    color: colors.black,
    marginTop: '0px',

    '@media(min-width:576px)': {
        fontSize: '55px',
        marginTop: '1.2em',
    },
});

export const carouselSlideDesc = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '0.6em',

    '@media(min-width:576px)': {
        fontSize: '20px',
        marginTop: '1.2em',
    },
});

export const carouselSlideButton = css({
    font: '14px Archivo-Regular, sans-serif',
    color: colors.primary,
    backgroundColor: colors.background,
    padding: '12px 22px',
    border: `2px solid ${colors.primary}`,
    borderRadius: '6px',
    marginTop: '1.2rem',
    '&:hover,&:focus': {
        backgroundColor: colors.primary,
        color: colors.white,
        border: `2px solid ${colors.primary}`,
    },

    '@media(min-width:576px)': {
        marginTop: '3.6rem',
    },
});

export const carouselIndicator = css({
    li: {
        height: '10px',
        width: '10px',
        margin: '0px 10px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
});
