import { IAuth } from './Entities';

const getStartupAttempting = (state: IAuth): boolean => state.actions.startingUp || false;

const getLoginAttempting = (state: IAuth): boolean => state.actions.login || false;
const getLoginError = (state: IAuth): string => state.error.login || '';

const getSignUpAttempting = (state: IAuth): boolean => state.actions.signUp || false;
const getSignUpError = (state: IAuth): string => state.error.signUp || '';

const getLogoutAttempting = (state: IAuth): boolean => state.actions.logout || false;
const getLogoutError = (state: IAuth): string => state.error.logout || '';

const getForgetPasswordAttempting = (state: IAuth): boolean => state.actions.forgetPassword || false;
const getForgetPasswordError = (state: IAuth): string => state.error.forgetPassword || '';

const getVerifyPasswordAttempting = (state: IAuth): boolean => state.actions.verifyForgetPassword || false;
const getVerifyPasswordError = (state: IAuth): string => state.error.verifyForgetPassword;

const getResetPasswordAttempting = (state: IAuth): boolean => state.actions.resetPassword || false;
const getResetPasswordError = (state: IAuth): string => state.error.resetPassword || '';

const getAuthToken = (state: IAuth): string => state.credentials.authToken || '';

export default {
    getStartupAttempting,

    getLoginAttempting,
    getLoginError,

    getSignUpAttempting,
    getSignUpError,

    getLogoutAttempting,
    getLogoutError,

    getForgetPasswordAttempting,
    getForgetPasswordError,

    getVerifyPasswordAttempting,
    getVerifyPasswordError,

    getResetPasswordAttempting,
    getResetPasswordError,

    getAuthToken,
};
