/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { Pressable } from 'react-native';
import { connect } from 'react-redux';

import Image from 'Components/Image';

import {
    StyleImageContainer,
    ProductImg,
    Variation,
    RowFlexCenter,
    MobileListStyle,
    MobileListStyleBold,
    Remarks,
} from 'Containers/User/Styles/ComponentStyles';
import { Trash } from 'Icons/Icons';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import Utils from 'Lib/Utils';
import NavActions from 'Services/Navigation/Actions';
import EditableQuantity from './EditableQuantity';
import { ProductListItemStyle, trashButton, trashIcon } from '../Styles/CartStyles';

function EditableProductsListComponent(props) {
    const { product, goldPrice, stockCheckLoading } = props;

    const { id, name, selectedVariant, quantity, imageUrl, remarks, customAttributes } = product;
    const variantPrice = Utils.Jemisys.getItemVariantPrice(product, selectedVariant, goldPrice);

    const totalPrice = variantPrice * quantity;

    const onQuantityChanged = (newQuantity) => {
        const { updateQuantity } = props;
        updateQuantity({ product, newQuantity });
    };

    const onRemoveItem = () => {
        const { removeFromCart } = props;
        removeFromCart({ product });
    };

    const renderRemarks = () => {
        if (!remarks) return null;
        return <div css={Remarks}>{`Remarks: ${remarks}`}</div>;
    };

    const renderVariation = () => {
        const salesType = Utils.Product.getSalesType(customAttributes);
        const variantName = Utils.Product.getItemVariantSizeLengthString(selectedVariant);

        if (salesType === 'JEWELERY') return false;
        return <div css={Variation}>{variantName}</div>;
    };

    const renderMobileView = () => {
        return (
            <div className='d-md-none'>
                <Row className='mt-5 mb-5 d-flex' style={{ alignItems: 'center' }}>
                    <Col xs='4'>
                        <Image
                            css={ProductImg}
                            url={imageUrl}
                            alt='Product Img'
                        />
                    </Col>
                    <Col xs='4'>
                        <div
                            css={ProductListItemStyle}
                            style={{ fontWeight: '700' }}
                        >
                            {name}
                        </div>
                        {renderVariation()}
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Quantity: </span>
                            {quantity}
                        </div>
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Price: </span>
                            {Utils.Formatter.currencyFormatter(variantPrice)}
                        </div>
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Total: </span>
                            {Utils.Formatter.currencyFormatter(totalPrice)}
                        </div>

                        <Col xs='2' style={{ marginTop: 10 }}>
                            <EditableQuantity quantity={quantity} onQuantityChanged={onQuantityChanged} />
                        </Col>
                    </Col>
                    <Col xs='2'>
                        <Pressable disabled={stockCheckLoading} style={{ padding: 5 }}>
                            <Trash css={trashButton} onClick={onRemoveItem} />
                        </Pressable>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderWebView = () => {
        return (
            <Row className='mt-5 d-none d-md-flex' style={{ alignItems: 'center' }}>
                <Col xs={11}>
                    <Row>
                        <Pressable
                            style={{ flex: 1 }}
                            onPress={() => NavActions.navToProductDetails(id)}
                        >
                            <Col css={StyleImageContainer}>
                                <Col xs='4' className='p-0'>
                                    <Image
                                        css={ProductImg}
                                        url={imageUrl}
                                        alt='Product Img'
                                    />
                                </Col>
                                <Col xs='8' style={{ padding: '20px' }}>
                                    <div
                                        css={ProductListItemStyle}
                                        style={{ fontWeight: '600', fontSize: 15 }}
                                    >
                                        {name}
                                    </div>
                                    {renderVariation()}
                                    {renderRemarks()}
                                </Col>
                            </Col>
                        </Pressable>
                        <Col xs='2' css={RowFlexCenter}>
                            <EditableQuantity quantity={quantity} onQuantityChanged={onQuantityChanged} product={product} />
                        </Col>
                        <Col xs='2' css={RowFlexCenter}>
                            <div css={ProductListItemStyle}>{variantPrice}</div>
                        </Col>
                        <Col xs='2' css={RowFlexCenter}>
                            <div css={ProductListItemStyle}>{totalPrice}</div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}>
                    <Button disabled={stockCheckLoading} css={trashButton} onClick={onRemoveItem}>
                        <Trash css={trashIcon} />
                    </Button>
                </Col>
            </Row>
        );
    };

    return (
        <>
            {renderWebView()}
            {renderMobileView()}
        </>
    );
}

const mapStateToProps = (state) => ({
    goldPrice: Selectors.getHomeGoldPrice(state),
    stockCheckLoading: Selectors.getUiStockCheckAttempting(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateQuantity: (params) => dispatch(Actions.cartUpdateCartQuantity(params)),
    removeFromCart: (params) => dispatch(Actions.cartRemoveFromCart(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableProductsListComponent);
