import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';
import { ICyberSourceInfo } from 'Redux/slices/cart/Entities';
import Toast from 'Lib/Toast';
import Config from 'Config/Config';

import NavActions from 'Services/Navigation/Actions';

interface PaymentWebViewProps {
    cyberSourceInfo: ICyberSourceInfo;
}

const PaymentWebView = (props: PaymentWebViewProps) => {
    const baseUrl = Config.CYBERSOURCE_PAYMENT_URL;
    const { cyberSourceInfo } = props;
    const {
        accessKey,
        profileId,
        transactionUuid,
        signedFieldNames,
        signedDateTime,
        locale,
        transactionType,
        referenceNumber,
        amount,
        currency,
        signature,
        billToAddressCity,
        billToAddressCountry,
        billToAddressLine1,
        billToAddressLine2,
        billToEmail,
        billToSurname,
        billToForename,
    } = cyberSourceInfo;

    useEffect(() => {
        // get the form and submit right away
        const form = document.getElementById('paymentForm') as HTMLFormElement;
        if (!accessKey || !profileId || !transactionUuid || !signedFieldNames || !signedDateTime || !locale || !transactionType || !referenceNumber || !amount || !currency || !signature || !billToAddressCity || !billToAddressCountry || !billToAddressLine1 || !billToAddressLine2 || !billToEmail || !billToSurname || !billToForename) {
            Toast.showError('Something went wrong, please try again later');
            NavActions.navToHome();
        } else if (form) {
            form.submit();
            form.reset();
        }
    });

    return (
        <div>
            <form action={baseUrl} method='post' id='paymentForm'>
                <input type='hidden' name='access_key' value={accessKey} />
                <input type='hidden' name='profile_id' value={profileId} />
                <input type='hidden' name='transaction_uuid' value={transactionUuid} />
                <input type='hidden' name='signed_field_names' value={signedFieldNames} />
                <input type='hidden' name='signed_date_time' value={signedDateTime} />
                <input type='hidden' name='locale' value={locale} />
                <input type='hidden' name='transaction_type' value={transactionType} />
                <input type='hidden' name='reference_number' value={referenceNumber} />
                <input type='hidden' name='amount' value={amount} />
                <input type='hidden' name='currency' value={currency} />
                <input type='hidden' name='signature' value={signature} />
                <input type='hidden' name='bill_to_address_city' value={billToAddressCity} />
                <input type='hidden' name='bill_to_address_country' value={billToAddressCountry} />
                <input type='hidden' name='bill_to_address_line1' value={billToAddressLine1} />
                <input type='hidden' name='bill_to_address_line2' value={billToAddressLine2} />
                <input type='hidden' name='bill_to_email' value={billToEmail} />
                <input type='hidden' name='bill_to_surname' value={billToSurname} />
                <input type='hidden' name='bill_to_forename' value={billToForename} />
            </form>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    cyberSourceInfo: Selectors.getCartCyberSourceInfo(state),
});

const mapDispatchToProps = () => ({
    // reset data action
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWebView);
