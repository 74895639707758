import { UseCaseResponseStatus, IOrder } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Selectors from 'Redux/Selectors';
import { AsyncThunkAPI } from 'Redux/types';

import { OrderErrorTypes } from '../../cart/controllers';

export interface IGetOrderThunkResponse {
    order: IOrder;
}

export interface IGetOrderThunkParams {
    orderId: string;
}

export default createAsyncThunk<
    IGetOrderThunkResponse,
    IGetOrderThunkParams,
    AsyncThunkAPI
>('order/orders', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const authToken = Selectors.getAuthAuthToken(state);
    const { orderId } = params;

    if (!authToken) return thunkAPI.rejectWithValue('Not authorized');

    const response = await thunkAPI.extra.useCases.order.getOrderDetails.execute({ authToken, orderId });

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    if (response.data.type === OrderErrorTypes.GetOrderDetailsError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
