/** @jsxImportSource @emotion/react */
import React from 'react';

import { OrderStyleBold } from '../Styles/ComponentStyles';

interface OrderProps {
    orderId: string;
}

const OrderId = (props: OrderProps): JSX.Element => {
    const { orderId } = props;

    return (
        <div>
            <div className='mt-2'>
                <span css={OrderStyleBold}>Order ID : </span>
                <span css={OrderStyleBold}>{orderId}</span>
            </div>
        </div>
    );
};

export default OrderId;
