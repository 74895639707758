import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser, UseCaseResponseStatus } from '@ddot/AddToCartCommon';

import { AsyncThunkAPI } from 'Redux/types';
import NavActions from 'Services/Navigation/Actions';

import { ErrorTypes } from '.';

interface GetUserInfoParams {
    authToken: string;
}

export default createAsyncThunk<
    IUser,
    GetUserInfoParams,
    AsyncThunkAPI
>('user/getUserInfo', async (params, thunkAPI) => {
    const { authToken } = params;

    const response = await thunkAPI.extra.useCases.user.getUserInfo.execute({ authToken });

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }
    if (response.data.type === ErrorTypes.GetUserInfoError.UserNotFound) {
        return thunkAPI.rejectWithValue(response.data.title || 'User not found!');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
