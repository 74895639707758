/** @jsxImportSource @emotion/react */
import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Card } from 'reactstrap';

import Image from 'Components/Image';

import Utils from 'Lib/Utils';
import NavActions from 'Services/Navigation/Actions';

import Selectors from 'Redux/Selectors';

import { productImg, productName, productPrice, productItem } from '../Styles/ProductsRowStyles';

const FeaturedProduct = ({ product }) => {
    const { imageUrl, name, id, currency } = product;
    const goldPrice = useSelector(Selectors.getHomeGoldPrice);

    const { minPrice, maxPrice } = Utils.Jemisys.getItemPriceRange(product, goldPrice);

    const onPressed = () => {
        window.scrollTo(0, 0);
        NavActions.navToProductDetails(id);
    };

    return (
        <Card
            css={productItem}
            onClick={onPressed}
        >
            <Container fluid>
                <Image
                    css={productImg}
                    url={imageUrl}
                    alt='Product Img'
                />
                <div css={productName}>{name}</div>
                <div css={productPrice}>
                    {maxPrice !== minPrice ? `${minPrice} ${currency} -` : ''}
                    {`${maxPrice} ${currency}`}
                </div>
            </Container>
        </Card>
    );
};

export default FeaturedProduct;
