import React from 'react';
import Breadcrumbs from 'Components/Breadcrumbs';

import NavActions from 'Services/Navigation/Actions';

interface CartBreadcrumbsProps {
    currentItem: number;
}

const CartBreadcrumbs = (props: CartBreadcrumbsProps): JSX.Element => {
    const { currentItem } = props;

    const data = [{
        key: '1',
        label: 'Shopping Cart',
        disabled: currentItem === 3,
        onClick: () => NavActions.navToCart(),
    }, {
        key: '2',
        label: 'Shipping Address',
        disabled: currentItem < 1 || currentItem === 3,
        onClick: () => NavActions.navToShippingAddress(),
    }, {
        key: '3',
        label: 'Review & Confirm',
        disabled: currentItem < 2 || currentItem === 3,
        onClick: () => NavActions.navToOrderReview(),
    }, {
        key: '4',
        label: 'Payment',
        disabled: currentItem < 3,
        onClick: () => NavActions.navToPayment(),
    }];

    return (
        <Breadcrumbs items={data} />
    );
};

export default CartBreadcrumbs;
