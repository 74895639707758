import React from 'react';

import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Selectors from 'Redux/Selectors';

interface PrivateRouteProps extends RouteProps {
    component: any;
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
    const { component: Component, location, ...rest } = props;

    const isAuthenticated = useSelector(Selectors.getUserId);

    const renderBody = (bodyProps: RouteProps) => {
        if (isAuthenticated) {
            return <Component {...bodyProps} />;
        }
        return (
            <Redirect
                to={{
                    pathname: '/',
                    state: { from: location },
                }}
            />
        );
    };

    return (
        <Route
            {...rest}
            render={renderBody}
        />
    );
};

export default PrivateRoute;
