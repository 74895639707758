/** @jsxImportSource @emotion/react */
import React from 'react';

import { Row, Col } from 'reactstrap';

import Utils from 'Lib/Utils';

import {
    SummaryTitle,
    BackandProgContainer,
    ProductListItemStyle2,
} from '../Styles/ComponentStyles';

interface SummaryProps {
    SubTotal: number,
    ShippingCost?: number,
    PromoCode?: string,
    DiscountPrice?: number,
    currency: string,
}

const OrderSummary = (props: SummaryProps): JSX.Element => {
    const { SubTotal, currency, ShippingCost = 0, PromoCode, DiscountPrice } = props;

    const totalCost = ShippingCost + SubTotal;

    const renderShippingFee = () => {
        if (!ShippingCost) return null;

        return (
            <Row className='mt-2'>
                <Col xs='5'> </Col>
                <Col xs='6' css={BackandProgContainer} className='p-0'>
                    <div css={SummaryTitle}>Shipping Cost</div>
                    <div css={SummaryTitle} className='text-nowrap'>
                        {`${Utils.Formatter.currencyFormatter(ShippingCost)} ${currency}`}
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <div>
            <Row className='mt-4'>
                <Col xs='5'>
                    <div css={SummaryTitle}>Order Summary</div>
                </Col>
                <Col xs='6' css={BackandProgContainer} className='p-0'>
                    <div css={SummaryTitle}>Sub Total</div>
                    <div css={SummaryTitle} className='text-nowrap'>
                        {`${Utils.Formatter.currencyFormatter(SubTotal - ShippingCost)} ${currency}`}
                    </div>
                </Col>
            </Row>
            {renderShippingFee()}
            {PromoCode && (
                <Row className='mt-2'>
                    <Col xs='5'> </Col>
                    <Col xs='6' css={BackandProgContainer} className='p-0'>
                        <div css={SummaryTitle}>
                            Discount
                            <div>{` (Promo code :${PromoCode})`}</div>
                        </div>
                        {DiscountPrice && (
                            <div css={SummaryTitle} className='text-nowrap'>
                                {`${Utils.Formatter.currencyFormatter(DiscountPrice)} ${currency}`}
                            </div>
                        )}
                    </Col>
                </Row>
            )}
            <Row className='mt-4'>
                <Col xs='4' md='5'> </Col>
                <Col xs='7' md='6' css={BackandProgContainer} className='p-0'>
                    <div css={SummaryTitle}>Total Cost</div>
                    <div css={SummaryTitle}>
                        {`${Utils.Formatter.currencyFormatter(totalCost - ShippingCost)} ${currency}`}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

OrderSummary.defaultProps = {
    DiscountPrice: 0,
    PromoCode: '',
    ShippingCost: 0,
};

export default OrderSummary;
