/** @jsxImportSource @emotion/react */
import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';

import Image from 'Components/Image';

import Utils from 'Lib/Utils';

import Selectors from 'Redux/Selectors';

import {
    ProductContainer,
    ProductImgSubItem,
    SubContainer,
    TitleContainer,
    Title,
    Price,
    Variation,
    Quantity,
    Remarks,
} from '../Styles/ComponentStyles';

// CURRENTLY NOT IN USE
function OrderHistorySubItem({ product }) {
    const { name, currency, imageUrl, quantity, selectedVariant, remarks, customAttributes } = product;
    const goldPrice = useSelector(Selectors.getHomeGoldPrice);

    const salesType = Utils.Product.getSalesType(customAttributes);

    const variantName = Utils.Product.getItemVariantSizeLengthString(selectedVariant);
    const variantPrice = Utils.Jemisys.getItemVariantPrice(product, selectedVariant, goldPrice);
    const totalPrice = variantPrice * quantity;

    const renderVariation = () => {
        if (salesType === 'JEWELERY') return false;

        return (
            <div css={Variation}>
                {`Variation : ${variantName}`}
            </div>
        );
    };

    return (
        <div css={ProductContainer} key={selectedVariant.sku}>
            <Col xs={3} className='p-0'>
                <Image css={ProductImgSubItem} url={imageUrl} alt='Product Img' />
            </Col>
            <Col xs={7} className='p-0'>
                <div css={SubContainer}>
                    <div css={TitleContainer}>
                        <div css={Title}>{name}</div>
                    </div>

                    {renderVariation()}
                    {remarks && (<div css={Remarks}>{`Remarks: ${remarks}`}</div>)}
                    <div css={Quantity}>
                        {'Quantity : '}
                        {quantity}
                    </div>
                </div>
            </Col>
            <Col xs={2} className='p-0'>
                <div css={SubContainer}>
                    <div css={TitleContainer}>
                        <div css={Price}>{`${totalPrice} ${currency}`}</div>
                    </div>
                </div>
            </Col>
        </div>
    );
}

export default OrderHistorySubItem;
