import CarouselImages from 'Assets/Images/Carousel';

interface IBannerItem {
    linkTo?: string;
    imageUrl: string;
    name?: string;
    description?: string;
}

const CarouselLov: IBannerItem[] = [{
    imageUrl: CarouselImages.Image1,
}, {
    imageUrl: CarouselImages.Image2,
}, {
    imageUrl: CarouselImages.Image3,
}, {
    imageUrl: CarouselImages.Image4,
}, {
    imageUrl: CarouselImages.Image5,
}, {
    imageUrl: CarouselImages.Image6,
}, {
    imageUrl: CarouselImages.Image7,
}, {
    imageUrl: CarouselImages.Image8,
}, {
    imageUrl: CarouselImages.Image9,
}, {
    imageUrl: CarouselImages.Image10,
}];

export default CarouselLov;
