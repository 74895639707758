/** @jsxImportSource @emotion/react */
import React from 'react';

import {
    OrderStyle,
    OrderStyleBold,
} from '../Styles/ComponentStyles';

interface RemarksProps {
    remarks?: string;
}

const CustomerRemarks = (props: RemarksProps): JSX.Element | null => {
    const { remarks } = props;

    if (!remarks) return null;

    return (
        <div css={{ marginTop: 20 }}>
            <span css={OrderStyleBold}>Remarks: </span>
            <span css={OrderStyle}>{remarks}</span>
        </div>
    );
};

CustomerRemarks.defaultProps = {
    remarks: '',
};

export default CustomerRemarks;
