import { UseCaseResponseStatus, IAddress, ICustomAttribute } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'dayjs';
import utc from 'dayjs/plugin/utc';

import uiSlice from 'Redux/slices/ui';
import { AsyncThunkAPI } from 'Redux/types';

import Toast from 'Lib/Toast';

import { ErrorTypes } from '.';

moment.extend(utc);

export interface ISignupThunkResponse {
    id: string;
    salutation?: string;
    idNo?: string;
    username?: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email?: string;
    birthday?: string;
    gender?: 'MALE' | 'FEMALE';
    address?: IAddress;
    customAttributes?: ICustomAttribute[];
}

export interface ISignupThunkParams {
    idNo: string;
    salutation: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2?: string;
    city: string;
    country: string;
    state: string;
    postCode: number;
    birthday?: Date;
    gender: 'MALE' | 'FEMALE';
    email: string;
    password: string;
    phoneNumber: string;
    onSignup?(): void;
}

export default createAsyncThunk<
    ISignupThunkResponse,
    ISignupThunkParams,
    AsyncThunkAPI
>('auth/signup', async (params, thunkAPI) => {
    let utcBirthday;
    // this is necessary because our date picker returns the time in local time, which we will need to manually
    // convert to UTC because everyone knows UTC is superior.
    if (params.birthday) {
        utcBirthday = new Date(Date.UTC(params?.birthday.getFullYear(), params?.birthday.getMonth(), params?.birthday.getDate()));
    }

    const { onSignup, ...filteredParams } = params;

    const response = await thunkAPI.extra.useCases.auth.signup.execute({
        ...filteredParams,
        birthday: moment(utcBirthday).toString(),
    });

    if (response.status === UseCaseResponseStatus.Success) {
        Toast.show('Sign up success!');

        if (onSignup) onSignup();
        return thunkAPI.dispatch(uiSlice.actions.uiSetSignUpModalVisible(false));
    }

    if (response.data.type === ErrorTypes.SignUpError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    if (response.data.type === ErrorTypes.SignUpError.MissingFields) {
        return thunkAPI.rejectWithValue(response.data.message || 'Some fields are empty. Please fill up all details.');
    }

    if (response.data.type === ErrorTypes.SignUpError.InvalidFields) {
        return thunkAPI.rejectWithValue(response.data.message || 'Invalid input. Please try again.');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
