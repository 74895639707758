/** @jsxImportSource @emotion/react */
import { AccountLabel } from 'Containers/User/Styles/AccountStyles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { View, ActivityIndicator } from 'react-native-web';
import {
    Button,
    Col,
    Container,
    // CustomInput,
    Form,
    // FormGroup,
    // Input,
    // Label,
    Row,
} from 'reactstrap';

import ErrorMessage from 'Components/Common/ErrorMessage';
import ValidationCheck from 'Components/Common/Validation';

import LOV from 'Lib/LOVs/ShippingAddressLovs';
import Utils from 'Lib/Utils';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import CartBreadcrumbs from './Components/CartBreadcrumbs';
import OrderSummary from './Components/OrderSummary';
import GoldPriceChangeAlert from './Components/GoldPriceChangeAlert';
import ModalUnavailableProducts from './Components/ModalUnavailableProducts';
// import SaveAccount from './Components/SaveAccount';

import NavActions from '../../Services/Navigation/Actions';

import { cartContainer, cartTitle } from './Styles/CartStyles';
import {
    // checkBoxContainer,
    continuePaymentButton,
    continuePaymentButtonLoading,
} from './Styles/ShippingAddressStyles';

const ShippingAddress = (props) => {
    const {
        cartItems,
        shippingAddress,
        customerRemarks,
        userInfo,
        setShippingFee,
        setShippingAddress,
        setCustomerRemarks,
        goldPrice,
        stockCheckLoading,
        stockCheckError,
    } = props;

    const [firstName, setFirstName] = useState(shippingAddress.firstName || userInfo.firstName || '');
    const [lastName, setLastName] = useState(shippingAddress.lastName || userInfo.lastName || '');
    const [email, setEmail] = useState(shippingAddress.email || userInfo.email || '');
    const [phoneNumber, setPhoneNumber] = useState(shippingAddress.phoneNumber || userInfo.phoneNumber || '');
    const [address1, setAddress1] = useState(shippingAddress.address1 || userInfo.address?.address1 || '');
    const [address2, setAddress2] = useState(shippingAddress.address2 || userInfo.address?.address2 || '');
    const [city, setCity] = useState(shippingAddress.city || userInfo.address?.city || '');
    const [state, setState] = useState(shippingAddress.state || userInfo.address?.state || 'Selangor');
    const [country, setCountry] = useState(shippingAddress.country || userInfo.address?.country || 'Malaysia');
    const [postCode, setPostCode] = useState(shippingAddress.postCode || userInfo.address?.postCode || '');
    const [remarks, setRemarks] = useState(customerRemarks);
    const [error, setError] = useState('');

    let total = 0;

    cartItems.forEach(item => {
        const { selectedVariant, quantity } = item;
        const variantPrice = Utils.Jemisys.getItemVariantPrice(item, selectedVariant, goldPrice);
        total += (variantPrice * quantity);
    });

    if (!cartItems.length) {
        NavActions.navToCart();
        return null;
    }

    const selectedCountry = LOV.find(item => item.country.toLowerCase() === country.toLowerCase());

    const shippingFee = selectedCountry.shippingFee || 0;

    const orderSummary = {
        subTotal: total,
        currency: 'MYR',
        promoCode: null,
        discountPrice: 0,
        totalCost: total + shippingFee,
        shippingFee,
    };

    const renderCountryOptions = () => {
        return LOV.map(item => {
            return (
                <option key={item.country}>{item.country}</option>
            );
        });
    };

    const renderStates = () => {
        const countryStates = LOV.find(item => item.country.toLowerCase() === country.toLowerCase());
        return countryStates.states.map(item => {
            return (
                <option key={item}>{item}</option>
            );
        });
    };

    const submitShippingAddress = () => {
        const { stockCheck } = props;

        if (stockCheckLoading) return;

        setError('');

        const countryStates = LOV.find(item => item.country.toLowerCase() === country.toLowerCase());

        const data = {
            firstName,
            lastName,
            address1,
            address2,
            remarks,
            city,
            postCode,
            phoneNumber,
            email,
        };
        const validateShippingData = Utils.Validator.validateShippingInfo(data);

        if (validateShippingData) {
            setError(validateShippingData);
        } else {
            stockCheck(cartItems, () => {
                setCustomerRemarks(remarks);
                setShippingFee(countryStates.shippingFee);
                setShippingAddress({
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    address1,
                    address2,
                    city,
                    state,
                    country,
                    postCode,
                });

                NavActions.navToOrderReview();
                window.scrollTo(0, 0);
            });
        }
    };

    return (
        <View>
            <Container fluid css={cartContainer}>
                <Form>
                    <CartBreadcrumbs currentItem={1} />
                    <Row>
                        <Col xs={12} xl={8}>
                            <Row>
                                <Col xs={12}>
                                    <div css={cartTitle}>Shipping Address</div>
                                </Col>
                            </Row>

                            <GoldPriceChangeAlert />

                            <Row>
                                <ValidationCheck
                                    label='First Name'
                                    labelCss={AccountLabel}
                                    value={firstName}
                                    type='name'
                                    name='firstname'
                                    placeholder='First name'
                                    onChange={(event) => {
                                        setFirstName(event.target.value);
                                    }}
                                    errorMessage='Invalid first name'
                                />
                                <Col md={6} style={{ padding: 0 }}>
                                    <ValidationCheck
                                        label='Last Name'
                                        labelCss={AccountLabel}
                                        value={lastName}
                                        type='name'
                                        name='lastname'
                                        placeholder='Last name'
                                        onChange={(event) => {
                                            setLastName(event.target.value);
                                        }}
                                        errorMessage='Invalid last name'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <ValidationCheck
                                    label='Address 1'
                                    labelCss={AccountLabel}
                                    value={address1}
                                    type='text'
                                    maxLength={50}
                                    name='address1'
                                    placeholder='Address 1'
                                    onChange={(event) => {
                                        setAddress1(event.target.value);
                                    }}
                                    errorMessage='Invalid address'
                                />
                            </Row>
                            <Row>
                                <ValidationCheck
                                    label='Address 2'
                                    labelCss={AccountLabel}
                                    value={address2}
                                    type='text'
                                    maxLength={50}
                                    name='address2'
                                    placeholder='Address 2'
                                    onChange={(event) => {
                                        setAddress2(event.target.value);
                                    }}
                                    errorMessage='Invalid address'
                                />
                            </Row>
                            <Row>
                                <ValidationCheck
                                    label='City'
                                    labelCss={AccountLabel}
                                    value={city}
                                    type='text'
                                    name='city'
                                    placeholder='City'
                                    onChange={(event) => {
                                        setCity(event.target.value);
                                    }}
                                    errorMessage='Invalid city'
                                />
                                <Col md={6} style={{ padding: 0 }}>
                                    <ValidationCheck
                                        label='State'
                                        labelCss={AccountLabel}
                                        value={state}
                                        type='select'
                                        name='state'
                                        id='state'
                                        placeholder='State'
                                        onChange={(event) => {
                                            setState(event.target.value);
                                        }}
                                        errorMessage='Please select your state'
                                    >
                                        {renderStates()}
                                    </ValidationCheck>
                                </Col>
                            </Row>
                            <Row>
                                <ValidationCheck
                                    label='Country'
                                    labelCss={AccountLabel}
                                    value={country}
                                    type='select'
                                    name='country'
                                    id='country'
                                    placeholder='Country'
                                    onChange={(event) => {
                                        setCountry(event.target.value);
                                    }}
                                    errorMessage='Please select your country'
                                >
                                    {renderCountryOptions()}
                                </ValidationCheck>
                                <Col md={6} style={{ padding: 0 }}>
                                    <ValidationCheck
                                        label='Postcode'
                                        labelCss={AccountLabel}
                                        value={postCode}
                                        type='text'
                                        name='postcode'
                                        placeholder='Postcode'
                                        onChange={(event) => {
                                            setPostCode(event.target.value);
                                        }}
                                        errorMessage='Invalid postcode'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <ValidationCheck
                                    label='Email'
                                    labelCss={AccountLabel}
                                    value={email}
                                    type='email'
                                    name='email'
                                    placeholder='E-mail'
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                    errorMessage='Invalid email'
                                />
                                <Col md={6} style={{ padding: 0 }}>
                                    <ValidationCheck
                                        label='Phone Number'
                                        labelCss={AccountLabel}
                                        value={phoneNumber}
                                        type='text'
                                        name='phonenumber'
                                        placeholder='Phone number'
                                        onChange={(event) => {
                                            setPhoneNumber(event.target.value);
                                        }}
                                        errorMessage='Invalid phone number'
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <ValidationCheck
                                    label='Remarks'
                                    labelCss={AccountLabel}
                                    value={remarks}
                                    type='text'
                                    maxLength={50}
                                    name='remarks'
                                    placeholder='Customer remarks'
                                    onChange={(event) => {
                                        setRemarks(event.target.value);
                                    }}
                                    errorMessage='Invalid remark, please try again.'
                                // validationDisabled
                                />
                            </Row>
                        </Col>
                        <Col xs={12} xl={4}>
                            <OrderSummary
                                data={orderSummary}
                                products={cartItems}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col css={{ textAlign: 'center' }}>
                            <ErrorMessage error={error || stockCheckError} />
                            <Button
                                css={stockCheckLoading ? continuePaymentButtonLoading : continuePaymentButton}
                                onClick={submitShippingAddress}
                            >
                                {stockCheckLoading ? (
                                    <ActivityIndicator color='white' />
                                ) : (
                                    'PROCEED'
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>

            <ModalUnavailableProducts />
        </View>
    );
};

const mapStateToProps = (state) => ({
    cartItems: Selectors.getCartItems(state),
    shippingAddress: Selectors.getCartShippingAddress(state),
    userInfo: Selectors.getUserInfo(state),
    customerRemarks: Selectors.getCartCustomerRemarks(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
    stockCheckLoading: Selectors.getUiStockCheckAttempting(state),
    stockCheckError: Selectors.getUiStockCheckError(state),
});

const mapDispatchToProps = (dispatch) => ({
    setShippingAddress: (shippingAddress) => dispatch(Actions.cartSetShippingAddress(shippingAddress)),
    setCustomerRemarks: (remarks) => dispatch(Actions.cartSetCustomerRemarks(remarks)),
    setShippingFee: (shippingFee) => dispatch(Actions.cartSetShippingFee(shippingFee)),
    stockCheck: (products, callback) => dispatch(Actions.uiStockCheck({ products, callback })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
