import { useEffect, useRef } from 'react';
import { ICartProduct, ICustomAttribute, IProduct, IProductVariant } from '@ddot/AddToCartCommon';
import TripleDES from 'crypto-js/tripledes';
import utf8enc from 'crypto-js/enc-utf8';
import moment from 'dayjs';

import { IGoldPrice } from 'Redux/slices/home/Entities';

import pjson from '../../package.json';

type SalesType = 'GOLD' | 'JEWELERY';
type Status = 'Success' | 'Failed';

enum SalesTypeEnum {
    GOLD = 'GOLD',
    JEWELERY = 'JEWELERY',
}

interface SignUpBasic {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface SignUpData {
    address1: string,
    address2: string;
    state: string,
    city: string,
    postCode: string,
    phoneNumber: string,
    gender: string,
    birthday: Date,
}

interface UserData {
    firstName: string,
    lastName: string,
    address1: string,
    address2: string,
    remarks: string,
    city: string;
    postCode: string,
    phoneNumber: string,
    email: string,

}

interface UserCredentials {
    username: string;
    password: string;
}

interface JemisysPriceRange {
    minPrice: number;
    maxPrice: number;
}

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
    getUserCredentialsFromLocalStorage: (): UserCredentials | null => {
        const fromStorage = LocalStorage.getItem<UserCredentials>('login');

        if (!fromStorage) return null;

        return {
            username: fromStorage.username,
            password: Crypto.decrypt(fromStorage.password),
        };
    },
};

const Product = {
    getSalesType: (customAttributes?: ICustomAttribute[]): SalesType | null => {
        if (!customAttributes) return null;
        return CustomAttr.getValueFromKey('Sale Type', customAttributes) as SalesType;
    },
    getMinPrice: (customAttributes?: ICustomAttribute[]): number | null => {
        if (!customAttributes) return 0;
        return CustomAttr.getValueFromKey('MinPrice', customAttributes) as number;
    },
    getMaxPrice: (customAttributes?: ICustomAttribute[]): number | null => {
        if (!customAttributes) return 0;
        return CustomAttr.getValueFromKey('MaxPrice', customAttributes) as number;
    },
    getLaborCost: (product: IProduct, selectedVariant?: IProductVariant): string | null => {
        const { customAttributes, variants } = product;

        const salesType = Product.getSalesType(customAttributes);

        if (salesType === SalesTypeEnum.GOLD) {
            if (!selectedVariant) return null;

            const { customAttributes: variantAttr } = selectedVariant;

            if (!variantAttr) return null;
            return CustomAttr.getValueFromKey('Labor Cost', variantAttr) as string;
        }

        if (salesType === SalesTypeEnum.JEWELERY) {
            if (!variants.length) return null;
            const customAttr = variants[0].customAttributes;

            if (!customAttr) return null;
            return CustomAttr.getValueFromKey('Labor Cost', customAttr) as string;
        }

        return null;
    },
    getPurity: (customAttributes?: ICustomAttribute[]): string | null => {
        if (!customAttributes) return '';
        return CustomAttr.getValueFromKey('Purity', customAttributes) as string;
    },
    getVariantLaborCost: (customAttributes: ICustomAttribute[]): string => {
        return CustomAttr.getValueFromKey('Labor Cost', customAttributes) as string;
    },
    getImage: (product: IProduct): string => {
        const { imageUrl } = product;

        if (!imageUrl) return '';
        return imageUrl[0];
    },
    getVariants: (product: IProduct): IProductVariant[] => {
        const { customAttributes, variants } = product;

        const salesType = Product.getSalesType(customAttributes);

        if (salesType === SalesTypeEnum.GOLD) return variants;
        if (salesType === SalesTypeEnum.JEWELERY) {
            return [];
        }

        return [];
    },
    getAvailableQuantity: (product: IProduct): number => {
        const { customAttributes, variants } = product;

        const salesType = Product.getSalesType(customAttributes);

        if (salesType === SalesTypeEnum.GOLD) return 1;
        if (salesType === SalesTypeEnum.JEWELERY) return variants.length;
        return 0;
    },
    getProductSize: (variant: IProductVariant): string => {
        const { customAttributes } = variant;

        if (!customAttributes || !customAttributes.length) return '';
        return CustomAttr.getValueFromKey('Size', customAttributes) as string;
    },
    getProductLength: (variant: IProductVariant): string => {
        const { customAttributes } = variant;

        if (!customAttributes || !customAttributes.length) return '';
        return CustomAttr.getValueFromKey('Length', customAttributes) as string;
    },
    getItemVariantSizeLengthString: (variant: IProductVariant): string => {
        const size = Product.getProductSize(variant);
        const length = Product.getProductLength(variant);
        const weight = variant.type.find((type) => type.name === 'Weight')?.value;

        let variantName = variant.sku;
        let sizeLength = '';

        if (weight) variantName = `${weight} gm - ${variantName}`;
        if (size && length) sizeLength = `${size} ${length}`;

        if (size) sizeLength = size;
        if (length) sizeLength = length;

        if (sizeLength) variantName = `${sizeLength} - ${variantName}`;

        return variantName;
    },
};

const Formatter = {
    currencyFormatter: (price?: number): string => {
        if (price === undefined) return '0';
        return Number.parseFloat(price.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
};

const CustomAttr = {
    getValueFromKey: (key: string, attributes: ICustomAttribute[]): string | number | null => {
        if (!attributes.length) return null;
        const item = attributes.find(i => i.name === key);

        if (item) return item.value;
        return null;
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

const Crypto = {
    encrypt: (unencryptedString: string): string => {
        const encrypted = TripleDES.encrypt(unencryptedString, pjson.name).toString();
        return encrypted;
    },
    decrypt: (encryptedString: string): string => {
        const decrypted = TripleDES.decrypt(encryptedString, pjson.name).toString(utf8enc);
        return decrypted;
    },
};

const emailCheck = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const phoneNumberCheck = /^[0-9]+$/;
const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
const numberCheck = /^[0-9]+$/;
const symbolCheck = /[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/;
// const addressCheck = /[_!"`'#%&:;<>=@{}~$()*+\\?[\]^|]+/;
const addressCheck = /["']+/;
const sqlCheck = /['"]/;
const stringCheck = (value = '') => !value || !value.trim().length || emojiRegex.test(value);

const Validator = {
    validatePassword: (password: string): string | null => {
        const { length } = password;
        if (stringCheck(password) || length < 6) {
            return 'Invalid password. Please try again.';
        }

        return null;
    },
    validateSignUpBasic: (data: SignUpBasic): string | null => {
        const {
            firstName, lastName, password, confirmPassword, email,
        } = data;

        if (stringCheck(firstName) || symbolCheck.test(firstName)) {
            return 'Invalid name. Please try again.';
        }
        if (stringCheck(lastName) || symbolCheck.test(lastName)) {
            return 'Invalid name. Please try again.';
        }
        if (stringCheck(email) || !emailCheck.test(email)) {
            return 'Invalid email. Please try again.';
        }

        if (Validator.validatePassword(password)) {
            return 'Invalid password. Please try again.';
        }

        if (password !== confirmPassword) {
            return 'Passwords do not match. Please try again.';
        }

        return null;
    },
    validateSignUp: (data: SignUpData): string | null => {
        const {
            address1, address2, state, city, postCode, phoneNumber, gender, birthday,
        } = data;

        if (stringCheck(address1) || sqlCheck.test(address1) || addressCheck.test(address1)) {
            return 'Invalid address. Please try again.';
        }
        if (address2.length && (stringCheck(address2) || sqlCheck.test(address2) || addressCheck.test(address2))) {
            return 'Invalid address. Please try again.';
        }

        if (stringCheck(state)) {
            return 'Invalid state.';
        }

        if (stringCheck(city) || sqlCheck.test(city)) {
            return 'Invalid city.';
        }

        if (stringCheck(postCode) || !numberCheck.test(postCode)) {
            return 'Invalid postcode.';
        }

        if (stringCheck(phoneNumber) || !phoneNumberCheck.test(phoneNumber)) {
            return 'Invalid phone number.';
        }

        if (gender === 'Gender') {
            return 'Invalid gender.';
        }

        // if (!(birthday instanceof Date) || !moment(birthday).isValid()) {
        //     return 'Invalid date of birthday. Please try again.';
        // }

        return null;
    },
    validateShippingInfo: (data: UserData): string | null => {
        const {
            firstName, lastName, address1, address2, city, postCode, phoneNumber, email, remarks,
        } = data;
        if (stringCheck(firstName) || symbolCheck.test(firstName)) {
            return 'Invalid name, please try again';
        }

        if (stringCheck(lastName) || symbolCheck.test(lastName)) {
            return 'Invalid name, please try again';
        }

        if (stringCheck(address1) || sqlCheck.test(address1) || addressCheck.test(address1)) {
            return 'Invalid address, please try again';
        }

        if (address2.length > 0) {
            if (stringCheck(address2) || sqlCheck.test(address2) || addressCheck.test(address2)) {
                return 'Invalid address, please try again.';
            }
        }

        if (stringCheck(city) || symbolCheck.test(city)) {
            return 'Invalid city, please try again.';
        }

        if (stringCheck(postCode) || !numberCheck.test(postCode)) {
            return 'Invalid postcode, please try again.';
        }

        if (stringCheck(phoneNumber) || !phoneNumberCheck.test(phoneNumber)) {
            return 'Invalid phone number, please try again.';
        }

        if (stringCheck(email) || !emailCheck.test(email)) {
            return 'Invalid email, please try again.';
        }

        if (symbolCheck.test(remarks)) {
            return 'Invalid remark, please try again.';
        }
        return null;
    },
    validateEmailAddress: (email: string): string | null => {
        if (stringCheck(email) || !emailCheck.test(email)) {
            return 'Invalid email. Please try again.';
        }

        return null;
    },
};

const React = {
    usePrevious: <T>(value: T): T | undefined => {
        const ref = useRef<T>();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    },
};

const Jemisys = {
    getCartItemPrice: (product: ICartProduct, goldPrice: IGoldPrice): number => {
        return Jemisys.getItemVariantPrice(product, product.selectedVariant, goldPrice);
    },
    getItemVariantPrice: (product: IProduct, variant: IProductVariant, goldPrice: IGoldPrice): number => {
        const { customAttributes } = product;
        const { customAttributes: variantAttributes, price: variantPrice } = variant;

        if (!customAttributes || !variantAttributes) return -1;

        const priceType = Product.getSalesType(customAttributes);
        const laborCost = Number(Product.getVariantLaborCost(variantAttributes));

        let price = -1;

        if (priceType === SalesTypeEnum.JEWELERY) price = variantPrice.currentPrice;
        else {
            const { gold750, gold916, gold999, gold9999 } = goldPrice;

            const weightAttr = variant.type.find(item => item.name === 'Weight');

            if (weightAttr) {
                const weight = Number(weightAttr.value as string);
                const purity = Product.getPurity(customAttributes);

                if (purity) {
                    if (purity !== '750' && purity !== '916' && purity !== '999' && purity !== '999.9') {
                        return -1;
                    }

                    if (purity === '750' && gold750) price = (gold750 * weight) + laborCost;
                    else if (purity === '916' && gold916) price = (gold916 * weight) + laborCost;
                    else if (purity === '999' && gold999) price = (gold999 * weight) + laborCost;
                    else if (purity === '999.9' && gold9999) price = (gold9999 * weight) + laborCost;

                    price = Math.round(price);
                }
            }
        }

        return price;
    },
    getItemPriceRange: (item: IProduct, goldPrice: IGoldPrice): JemisysPriceRange => {
        const { variants } = item;

        let minPrice = 0;
        let maxPrice = 0;

        variants.forEach(variant => {
            const calculatedPrice = Jemisys.getItemVariantPrice(item, variant, goldPrice);

            if (minPrice === 0) minPrice = calculatedPrice;
            if (maxPrice === 0) maxPrice = calculatedPrice;

            if (calculatedPrice < minPrice) minPrice = calculatedPrice;
            if (calculatedPrice > maxPrice) maxPrice = calculatedPrice;
        });

        return {
            minPrice,
            maxPrice,
        };
    },
    getAllCheckedOutItems: (cartItems: ICartProduct[]): ICartProduct[] => {
        // this function will sort out all jewellery products that have > 1 quantity into its
        // own product, as accepted by the Jemisys API
        const checkedOutItems: ICartProduct[] = [];

        cartItems.forEach((product) => {
            if (Product.getSalesType(product.customAttributes) === SalesTypeEnum.JEWELERY) {
                const selectedVariant = product.customAttributes?.find((attr) => attr.name === 'availableVariant')?.value.toString().split(',');
                if (selectedVariant) {
                    selectedVariant.forEach((variant) => {
                        const availableVariant = product.variants.find((v) => v.sku === variant);
                        if (availableVariant) {
                            checkedOutItems.push({
                                ...product,
                                quantity: 1,
                                selectedVariant: availableVariant,
                            });
                        } else {
                            throw new Error('Something went wrong');
                        }
                    });
                }
            } else {
                checkedOutItems.push(product);
            }
        });

        return checkedOutItems;
    },
};

export default {
    Auth,
    Product,
    Formatter,
    CustomAttr,
    LocalStorage,
    Crypto,
    SalesTypeEnum,
    Validator,
    React,
    Jemisys,
};
