import { JemisysErrorTypes } from '@ddot/AddToCartCommon';

import authLogin from './login';
import authStartup from './startup';
import authSignup from './signup';
import authLogout from './logout';
import authForgetPassword from './forgetPassword';
import authVerifyForgetPassword from './verifyForgetPassword';
import authResetPassword from './resetPassword';

export const ErrorTypes = JemisysErrorTypes.AuthErrorTypes;

export default {
    authLogin,
    authStartup,
    authSignup,
    authLogout,
    authForgetPassword,
    authVerifyForgetPassword,
    authResetPassword,
};
