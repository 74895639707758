import { UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import getUserInfo from 'Redux/slices/user/controllers/getUserInfo';
import getCart from 'Redux/slices/cart/controllers/getCart';
import getWishlist from 'Redux/slices/user/controllers/getWishlistLocalWeb';
import { AsyncThunkAPI } from 'Redux/types';
import Utils from 'Lib/Utils';
import Toast from 'Lib/Toast';

import { ErrorTypes } from '.';

export interface LoginThunkParams {
    username: string;
    password: string;
    onAuthorized?(): void;
}

export interface LoginThunkResponse {
    username: string;
    password: string;
    authToken: string;
}

export default createAsyncThunk<
    LoginThunkResponse,
    LoginThunkParams,
    AsyncThunkAPI
>('auth/login', async (params, thunkAPI) => {
    const { username, password, onAuthorized } = params;

    const handleError = (error: string) => {
        return thunkAPI.rejectWithValue(error);
    };

    const response = await thunkAPI.extra.useCases.auth.login.execute({
        userId: username,
        password,
    });

    if (response.status === UseCaseResponseStatus.Success) {
        const { authToken } = response.data;

        const userInfo = await thunkAPI.dispatch(getUserInfo({ authToken }));

        if (userInfo.error) {
            Toast.showError('Login failed, please try again later.');
            return thunkAPI.rejectWithValue('Get user info failed. Please try again.');
        }

        let res;
        res = await thunkAPI.dispatch(getCart());
        if (res.error) return handleError('Error in getting cart. Please try again.');

        res = await thunkAPI.dispatch(getWishlist());
        if (res.error) return handleError('Error in getting wishlist. Please try again.');

        Utils.Auth.storeAuthToken(authToken);

        if (onAuthorized) onAuthorized();

        return {
            username,
            password,
            authToken,
        };
    }

    const { type, message } = response.data;

    if (type === ErrorTypes.LoginError.InvalidPassword) {
        Utils.LocalStorage.removeItem('login');
        return handleError('Invalid username or password. Please try again.');
    }

    if (type === ErrorTypes.LoginError.NoUserFound) {
        Utils.LocalStorage.removeItem('login');
        return handleError('No account found. Register now!');
    }

    Utils.LocalStorage.removeItem('login');
    return handleError(message || 'Something went wrong. Please try again.');
});
