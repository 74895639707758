import { IProduct, IProductCategory } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';
import uiSlice from 'Redux/slices/ui';
import Selectors from 'Redux/Selectors';

interface GetProductsFromCategoryParams {
    category: IProductCategory | null;
}

interface GetProductsFromCategoryResponse {
    category: IProductCategory | null;
    products: IProduct[];
}

export default createAsyncThunk<
    GetProductsFromCategoryResponse, // this is the response type
    GetProductsFromCategoryParams, // this is the param type
    AsyncThunkAPI // will be always the same
>('products/getProductsFromCategory', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const productList = Selectors.getProductsList(state);
    const categoryList = Selectors.getProductsCategories(state);

    const { category } = params;

    let response: GetProductsFromCategoryResponse = {
        category: null,
        products: [],
    };

    if (category) {
        if (categoryList.find(item => item.id === category.id)) {
            response = {
                category,
                products: productList.filter(item => item.categoryId === category.id),
            };
        }
    }

    thunkAPI.dispatch(uiSlice.actions.uiSetProductsAndCategory(response));
    return response;
});
