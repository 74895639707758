/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import Image from 'Components/Image';

import Selectors from 'Redux/Selectors';

import {
    StyleImageContainer,
    ProductImg,
    Variation,
    Remarks,
    RowFlexCenter,
    ProductListItemStyle,
    MobileListStyle,
    MobileListStyleBold,
} from 'Containers/User/Styles/ComponentStyles';
import Utils from 'Lib/Utils';

function ProductsListComponent({ product }) {
    const { name, selectedVariant, quantity, imageUrl, remarks, customAttributes } = product;

    const goldPrice = useSelector(Selectors.getHomeGoldPrice);

    const variantPrice = Utils.Jemisys.getItemVariantPrice(product, selectedVariant, goldPrice);
    const totalCost = variantPrice * quantity;

    const salesType = Utils.Product.getSalesType(customAttributes);
    const variantName = Utils.Product.getItemVariantSizeLengthString(selectedVariant);

    const renderVariation = () => {
        if (salesType === 'JEWELERY') return false;

        return <div css={Variation}>{variantName}</div>;
    };

    return (
        <>
            <Row className='mt-5 d-none d-sm-flex'>
                <Col xs='4' css={StyleImageContainer}>
                    <Col xs='5' className='p-0'>
                        <Image
                            css={ProductImg}
                            url={imageUrl}
                            alt='Product Img'
                        />
                    </Col>
                    <Col xs='8' style={{ margin: 'auto' }}>
                        <div
                            css={ProductListItemStyle}
                            style={{ fontWeight: '700' }}
                        >
                            {name}
                        </div>
                        {renderVariation()}
                        {remarks && (<div css={Remarks}>{`Remarks : ${remarks}`}</div>)}
                    </Col>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{quantity}</div>
                </Col>
                <Col xs='3' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>
                        {Utils.Formatter.currencyFormatter(variantPrice)}
                    </div>
                </Col>
                {/* <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>
                        {Utils.Formatter.currencyFormatter(labourCost)}
                    </div>
                </Col> */}
                <Col xs='3' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>
                        {Utils.Formatter.currencyFormatter(totalCost)}
                    </div>
                </Col>
            </Row>
            <Row className='mt-5 mb-5 d-flex d-sm-none'>
                <Col xs='4'>
                    <Image
                        css={ProductImg}
                        url={imageUrl}
                        alt='Product Img'
                    />
                </Col>
                <Col xs='8'>
                    <div
                        css={ProductListItemStyle}
                        style={{ fontWeight: '700' }}
                    >
                        {name}
                    </div>
                    {renderVariation()}
                    <div css={MobileListStyle}>
                        <span css={MobileListStyleBold}>Quantity: </span>
                        {quantity}
                    </div>
                    <div css={MobileListStyle}>
                        <span css={MobileListStyleBold}>Price: </span>
                        {Utils.Formatter.currencyFormatter(variantPrice)}
                    </div>
                    {/* <div css={MobileListStyle}>
                        <span css={MobileListStyleBold}>Labour: </span>
                        {Utils.Formatter.currencyFormatter(labourCost)}
                    </div> */}
                    <div css={MobileListStyle}>
                        <span css={MobileListStyleBold}>Total: </span>
                        {Utils.Formatter.currencyFormatter(totalCost)}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ProductsListComponent;
