import { css } from '@emotion/react';
import fonts from 'Themes/fonts';
import colors from 'Themes/colors';

export const listingContainer = css({
    padding: '20px',

    '@media(min-width:1200px)': {
        padding: '40px 80px',
    },

    '@media(min-width:1400px)': {
        padding: '40px 120px',
    },
});

export const productListingTitle = css({
    font: '40px FRL-Bold, serif',
    color: colors.black,
    textAlign: 'center',

    '@media(min-width:768px)': {
        fontSize: '50px',
    },

    '@media(min-width:1200px)': {
        fontSize: '60px',
    },
});

export const productListingOptionsContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    maxWidth: '88%',
    zIndex: 1000,
});

export const productListingOptionsText = css({
    font: '18px Archivo-Regular, sans-serif',
    display: 'inline-block',
});

export const productListingOptionsIcon = css({
    width: '27px',
});

export const sortDropdownToggle = css({
    color: `${colors.black} !important`,
    backgroundColor: `${colors.white} !important`,
    border: '0px',
    padding: '0px',
    boxShadow: 'unset !important',
    '&:hover,&:focus': {
        color: `${colors.black} !important`,
        backgroundColor: `${colors.white} !important`,
        border: '0px',
        boxShadow: 'unset !important',
    },
});

export const productsContainer = css({
    padding: '50px 20px',

    '@media(min-width:1200px)': {
        padding: '50px 60px 60px 60px',
    },

    '@media(min-width:1400px)': {
        padding: '50px 100px 60px',
    },
});

export const productContainer = css({
    textAlign: 'center',
    margin: '30px 0px',
    borderWidth: 0,
});

export const productPrice = css({
    fontFamily: fonts.primaryBold,
    fontSize: 15,
    color: colors.primary,
    marginTop: '1.2em',

    '@media(min-width: 500px)': {
        fontSize: 18,
    },
});

export const productName = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '1.2em',
});

export const productImg = css({
    width: '100%',
    height: 150,
    objectFit: 'contain',

    '@media(min-width:720px)': {
        height: 200,
    },
});

export const productRating = css({
    display: 'block !important',
    marginTop: '0.6em',
});

export const addToCartButton = css({
    font: '12px Archivo-Regular, sans-serif',
    color: colors.black,
    backgroundColor: colors.white,
    border: '2px solid black',
    borderRadius: '0px',
    padding: '6px 22px',
    marginTop: '1.2rem',
    transition: 'ease 0.2s',
    '&:hover,&:focus': {
        border: '2px solid black',
        backgroundColor: colors.black,
        fill: colors.white,
    },
});

export const customPaginationText = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
    '&:hover': {
        color: colors.primary,
        backgroundColor: 'unset',
    },
});

export const filterDropdownMenu = css({
    // mimicing reactstrap dropdown style because the filter dropdown menu is custom made
    padding: '20px',
    backgroundColor: '#FFF',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '0.25rem',
    minWidth: '400px',
});
