/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'reactstrap';
import { ICartProduct } from '@ddot/AddToCartCommon';

import Image from 'Components/Image';

import Utils from 'Lib/Utils';

import {
    StyleImageContainer,
    ProductImg,
    Variation,
    RowFlexCenter,
    ProductListItemStyle,
    MobileListStyle,
    MobileListStyleBold,
} from '../Styles/ComponentStyles';

interface ProductProps {
    product: ICartProduct;
}

function ProductsListComponent(props: ProductProps): JSX.Element {
    const { product } = props;

    const { name, selectedVariant, quantity, imageUrl } = product;
    const { price } = selectedVariant;
    const { currentPrice = 0 } = price;

    // 17AUG - FIX THIS BECAUSE ORDERCONFIRMATION IS SHARING WITH THIS COMPONENT, BUT ORDERCONFIRMATION WILL NEED LABOR COST, BUT
    // ORDER HISTORY ALREADY HAS NET PRICE AND WILL NOT NEED LABOR COST
    const variantName = Utils.Product.getItemVariantSizeLengthString(selectedVariant);
    const variantPrice = currentPrice || 0;

    // we will not need to get labor cost, because this is the net price
    const totalPrice = currentPrice * quantity;

    const imageSrc = imageUrl ? imageUrl[0] : '';

    return (
        <>
            <Row className='mt-5 d-none d-md-flex'>
                <Col xs='5' css={StyleImageContainer}>
                    <Col xs='5' className='p-0'>
                        <Image
                            css={ProductImg}
                            url={imageSrc}
                            alt='Product Img'
                        />
                    </Col>
                    <Col xs='8'>
                        <div css={ProductListItemStyle} style={{ fontWeight: 'bold' }}>{name}</div>
                        <div css={Variation}>
                            {`Variation: ${variantName}`}
                        </div>
                    </Col>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{quantity}</div>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{Utils.Formatter.currencyFormatter(variantPrice)}</div>
                </Col>
                {/* <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{Utils.Formatter.currencyFormatter(labourCost)}</div>
                </Col> */}
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{Utils.Formatter.currencyFormatter(totalPrice)}</div>
                </Col>
            </Row>
            <Row className='mt-5 mb-5 d-flex d-md-none'>
                <Col xs='12' css={StyleImageContainer}>
                    <Col xs='4' className='p-0'>
                        <img
                            css={ProductImg}
                            src={imageSrc}
                            alt='Product Img'
                        />
                    </Col>
                    <Col xs='8'>
                        <div css={ProductListItemStyle} style={{ fontWeight: 'bold' }}>{name}</div>
                        <div css={Variation}>
                            {`Variation: ${variantName}`}
                        </div>
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Quantity: </span>
                            {quantity}
                        </div>
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Price: </span>
                            {Utils.Formatter.currencyFormatter(variantPrice)}
                        </div>
                        {/* <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Labour: </span>
                            {Utils.Formatter.currencyFormatter(labourCost)}
                        </div> */}
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Total: </span>
                            {Utils.Formatter.currencyFormatter(totalPrice)}
                        </div>
                    </Col>
                </Col>
            </Row>
        </>
    );
}

export default ProductsListComponent;
