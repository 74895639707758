/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';
import { ICartProduct, IShippingAddress } from '@ddot/AddToCartCommon';
import { IGoldPrice } from 'Redux/slices/home/Entities';
import Actions from 'Redux/Actions';

import CartBreadcrumbs from 'Containers/Cart/Components/CartBreadcrumbs';
import { Container, Row, Card, CardBody, Col, Input, Label } from 'reactstrap';

import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import Utils from 'Lib/Utils';

import NavActions from 'Services/Navigation/Actions';

import { Bank, AtmCard, Fpx } from 'Icons/Icons';
import CreditCardPayment from './Components/CreditCardPayment';
import OrderSummary from './Components/OrderSummary';
import GoldPriceChangeAlert from './Components/GoldPriceChangeAlert';
import {
    cartContainer,
    cartTitle,
    divider,
    paymentMethodCard,
    paymentCardLabel,
    paymentCardLabelSelected,
    paymentMethodIcon,
} from './Styles/CartStyles';

interface PaymentProps {
    cartItems: ICartProduct[];
    shippingAddress?: IShippingAddress;
    loading: boolean;
    shippingFee: number;
    goldPrice: IGoldPrice;
    orderId: string;
    getCyberSourceInfo(orderId: string): void;
}

const PaymentScreen = (props: PaymentProps): JSX.Element => {
    const {
        cartItems, shippingAddress, loading, shippingFee, goldPrice, orderId, getCyberSourceInfo,
    } = props;

    const [paymentMethod, setPaymentMethod] = useState('');

    useEffect(() => {
        const timeoutId = setTimeout(() => { NavActions.navToHome(); }, 600000);

        if (orderId) getCyberSourceInfo(orderId);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    let total = 0;

    cartItems.forEach(item => {
        const { selectedVariant, quantity } = item;
        const variantPrice = Utils.Jemisys.getItemVariantPrice(item, selectedVariant, goldPrice);
        total += (variantPrice * quantity);
    });

    const orderSummary = {
        subTotal: total,
        currency: 'MYR',
        promoCode: null,
        discountPrice: 0,
        totalCost: total + shippingFee,
        shippingFee,
    };

    // const paymentMethods = ['Bank Transfer', 'Credit or Debit Card', 'FPX Online Banking'];
    const paymentMethods = ['Credit or Debit Card'];

    const renderPaymentMethodIcons = (item: string) => {
        if (item === 'Bank Transfer') {
            return <Bank css={paymentMethodIcon} />;
        } if (item === 'Credit or Debit Card') {
            return <AtmCard css={paymentMethodIcon} />;
        } if (item === 'FPX Online Banking') {
            return <Fpx />;
        } return false;
    };

    const renderPaymentMethodChoices = () => {
        return paymentMethods.map(item => {
            return (
                <Card key={item} css={paymentMethodCard}>
                    <CardBody style={{ padding: 0 }}>
                        <Label css={paymentMethod === item ? paymentCardLabelSelected : paymentCardLabel} for={item}>
                            {renderPaymentMethodIcons(item)}
                            <Input onClick={() => setPaymentMethod(item)} style={{ marginBottom: 4, cursor: 'pointer' }} type='radio' name='paymentMethod' id={item} />
                            {item === 'FPX Online Banking' ? '' : item}
                        </Label>
                    </CardBody>
                </Card>
            );
        });
    };

    const renderPaymentMethodDetails = () => {
        // if (paymentMethod === 'Bank Transfer') {
        //     return <BankTransfer />;
        // } if (paymentMethod === 'FPX Online Banking') {
        //     return <FPX />;
        // }
        if (paymentMethod === 'Credit or Debit Card') {
            return <CreditCardPayment />;
        } return false;
    };

    return (
        <View style={{ minHeight: 570 }}>
            <Container fluid css={cartContainer}>
                <CartBreadcrumbs currentItem={3} />
                <Row>
                    <Col xs={12} xl={8}>
                        <Row>
                            <Col>
                                <div css={cartTitle}>Select Payment Method</div>
                            </Col>
                        </Row>

                        <GoldPriceChangeAlert />

                        <Row style={{ minHeight: 100 }}>
                            {renderPaymentMethodChoices()}
                            <div css={divider} />
                        </Row>
                        <Row style={{ padding: 0 }}>
                            {renderPaymentMethodDetails()}
                        </Row>
                    </Col>
                    <Col xs={12} xl={4}>
                        <OrderSummary
                            data={orderSummary}
                            products={cartItems}
                        />
                    </Col>
                </Row>
            </Container>
        </View>
    );
};

PaymentScreen.defaultProps = {
    shippingAddress: undefined,
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getCartPlaceOrderAttempting(state),
    error: Selectors.getCartPlaceOrderError(state),
    cartItems: Selectors.getCartItems(state),
    cartCurrency: Selectors.getCartCurrency(state),
    userInfo: Selectors.getUserInfo(state),
    shippingFee: Selectors.getCartShippingFee(state),
    shippingAddress: Selectors.getCartShippingAddress(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
    orderId: Selectors.getUiOrderId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getFpxBankList: () => dispatch(Actions.cartGetFpxBankList()),
    getCyberSourceInfo: (orderId: string) => dispatch(Actions.cartGetCyberSourceInfo({ orderId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);
