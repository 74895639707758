import { UseCaseResponseStatus, IFpxBankList } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Selectors from 'Redux/Selectors';

import { AsyncThunkAPI } from 'Redux/types';

import { OrderErrorTypes } from '.';

export interface IGetCyberSourceInfoThunkResponse {
    accessKey: string,
    profileId: string,
    transactionUuid: string,
    signedFieldNames: string,
    signedDateTime: string,
    locale: string,
    transactionType: string,
    referenceNumber: string,
    amount: string,
    currency: string,
    signature: string,
    billToAddressCity: string;
    billToAddressCountry: string;
    billToAddressLine1: string;
    billToAddressLine2: string;
    billToEmail: string;
    billToSurname: string;
    billToForename: string;
}

export interface IGetCyberSourceInfoThunkParams {
    orderId: string;
}

export default createAsyncThunk<
    IGetCyberSourceInfoThunkResponse,
    IGetCyberSourceInfoThunkParams,
    AsyncThunkAPI
>('order/getCyberSourceInfo', async (params, thunkAPI) => {
    const response = await thunkAPI.extra.useCases.order.getCyberSourceInfo.execute(params);

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    if (response.data.type === OrderErrorTypes.GetCyberSourceInfoError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
