import { UseCaseResponseStatus, IProduct, IProductCategory } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';

import { ErrorTypes } from '.';

export interface IGetProductsResponse {
    products: IProduct[];
    categories: IProductCategory[];
}

export default createAsyncThunk<
    IGetProductsResponse, // this is the response type
    void, // this is the param type
    AsyncThunkAPI // will be always the same
>('products/getProducts', async (params, thunkAPI) => {
    const response = await thunkAPI.extra.useCases.product.getProducts.execute();

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    if (response.data.type === ErrorTypes.GetProductError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
