/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Rating from 'react-rating';
import {
    Button,
    Col,
    Container,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    // Input,
} from 'reactstrap';
import { ICartProduct, IProduct, IProductVariant, IReview } from '@ddot/AddToCartCommon';

import { StarEmpty, StarFilled, Heart, CartBag, Checkout, HeartFilled } from 'Icons/Icons';
import ErrorMessage from 'Components/Common/ErrorMessage';

import { IAddToCartThunkParams } from 'Redux/slices/cart/controllers/addToCart';

import Utils from 'Lib/Utils';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';
import { IGoldPrice } from 'Redux/slices/home/Entities';

import LoginOrSignupModal from '../../User/LoginSignUpModal';
import VariantSelector from './VariantSelector';
import ProductImage from './ProductImage';
import NavActions from '../../../Services/Navigation/Actions';
import {
    addToCartButtonContainer,
    addToCartButton,
    buttonIcons,
    checkoutButton,
    productDetailsContainer,
    productDetailsText,
    productName,
    // productNoteText,
    productPrice,
    productDropdown,
    productPurityText,
    // productDescriptionTitle,
    // productDescription,
    productVariationText,
    productLaborChargeMsg,
    // remarkInput,
    wishlistButton,
    filledWishlistButton,
} from '../Styles/ProductDetailsStyles';

interface IProductDetails {
    product: IProduct;
    reviews?: IReview[];
    reviewIndex?: number;
    similarProducts?: IProduct[];
}

interface ProductProps {
    productDetails: IProductDetails;
    loading: boolean;
    error: string;
    wishlist: IProduct[];
    goldPrice: IGoldPrice;
    clearError(): void;
    addToWishlistLocalWeb(item: IProduct): void;
    addToCart(item: IAddToCartThunkParams): void;
    isLoggedIn: string | undefined;
    getWishlist(): void;
    removeFromWishlistLocalWeb(product: IProduct): void;
}

const ProductDetailsRow = (props: ProductProps) => {
    const { productDetails, loading, error, wishlist, goldPrice, isLoggedIn, getWishlist, removeFromWishlistLocalWeb, addToCart } = props;

    useEffect(() => {
        return () => {
            const { clearError } = props;
            clearError();
        };
    }, []);

    const { product, reviews } = productDetails;

    const {
        imageUrl,
        name,
        id,
        // description,
        currency,
        // overallReviewStars,
        customAttributes,
    } = product;

    const [selectedVariant, setSelectedVariant] = useState<IProductVariant | undefined>(undefined);
    const [quantityDropdownOpen, setQuantityDropdownOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [remarks, setRemarks] = useState('');
    const [modal, setModal] = useState(false);

    const toggleQuantityDropdown = () => setQuantityDropdownOpen((prevState) => !prevState);

    const { minPrice, maxPrice } = Utils.Jemisys.getItemPriceRange(product, goldPrice);
    const purity = Utils.Product.getPurity(customAttributes);
    const availableQuantity = Utils.Product.getAvailableQuantity(product);

    const onAddToWishlist = () => {
        const { addToWishlistLocalWeb } = props;

        if (isLoggedIn) {
            if (wishlist.includes(product)) {
                removeFromWishlistLocalWeb(product);
            } else addToWishlistLocalWeb(product);
        } else setModal(true);
    };

    const toggle = () => {
        setModal(!modal);
    };

    const onAuthorized = () => {
        setModal(false);
    };

    const onSignup = () => {
        setModal(true);
    };

    const onAddToCart = () => {
        if (isLoggedIn) {
            addToCart({ product, selectedVariant, quantity, remarks });
        } else setModal(true);
    };

    const directCheckout = () => {
        if (isLoggedIn) {
            if (Utils.Product.getSalesType(product.customAttributes) === 'JEWELERY') {
                addToCart({ product, selectedVariant, quantity, remarks });
                NavActions.navToShippingAddress();
            } else if (Utils.Product.getSalesType(product.customAttributes) === 'GOLD') {
                if (selectedVariant) {
                    addToCart({ product, selectedVariant, quantity, remarks });
                    NavActions.navToShippingAddress();
                } else addToCart({ product, selectedVariant, quantity, remarks });
            }
        } else setModal(true);
    };

    // const renderReview = () => {
    //     return (
    //         <Row css={productRatingRow}>
    //             <Col xs='auto'>
    //                 <Rating
    //                     initialRating={overallReviewStars}
    //                     emptySymbol={
    //                         <StarEmpty css={productRatingStars} />
    //                     }
    //                     fullSymbol={
    //                         <StarFilled css={productRatingStars} />
    //                     }
    //                     readonly
    //                 />
    //                 <span css={productRatingText}>(120)</span>
    //             </Col>
    //         </Row>
    //     );
    // };

    const renderPricing = () => {
        let price = '';

        if (!selectedVariant) {
            const min = `${minPrice} ${currency}`;
            const max = `${maxPrice} ${currency}`;

            if (maxPrice !== minPrice) {
                price = `${min} - ${max}`;
            } else price = max;
        } else {
            price = `${Utils.Jemisys.getItemVariantPrice(product, selectedVariant, goldPrice)} ${currency}`;
        }

        return (
            <div>
                <div css={productPrice}>
                    {price}
                </div>

                <div css={productLaborChargeMsg}>
                    *Price is including labor costs.
                </div>
            </div>
        );
    };

    const renderAddToWishlistButton = () => {
        useEffect(() => {
            if (isLoggedIn) getWishlist();
        }, []);

        if (loading) {
            return (
                <div>
                    <Spinner color='primary' />
                </div>
            );
        }

        if (wishlist.includes(product)) {
            return (
                <div
                    css={filledWishlistButton}
                    onClick={onAddToWishlist}
                    onKeyPress={onAddToWishlist}
                    role='button'
                    tabIndex={0}
                >
                    <HeartFilled css={buttonIcons} />
                    <span css={{ marginLeft: '10px' }}>Wish List</span>
                </div>
            );
        }

        return (
            <div
                css={wishlistButton}
                onClick={onAddToWishlist}
                onKeyPress={onAddToWishlist}
                role='button'
                tabIndex={0}
            >
                <Heart css={buttonIcons} />
                <span css={{ marginLeft: '10px' }}>Wish List</span>
            </div>
        );
    };

    const renderRemarks = () => {
        return false;
        // return (
        //     <Input
        //         css={remarkInput}
        //         placeholder='Remarks (optional)'
        //         value={remarks}
        //         maxLength={30}
        //         onChange={e => setRemarks(e.target.value)}
        //     />
        // );
    };

    const renderAddToCartButtons = () => {
        if (loading) {
            return (
                <div css={addToCartButtonContainer}>
                    <Spinner color='primary' />
                </div>
            );
        }

        return (
            <div>
                <ErrorMessage error={error} style={{ alignItems: 'flex-start', marginTop: 15 }} />
                <Button
                    onClick={onAddToCart}
                    css={addToCartButton}
                >
                    <CartBag css={buttonIcons} />
                    ADD TO CART
                </Button>
                <Button
                    onClick={directCheckout}
                    css={checkoutButton}
                >
                    <Checkout css={buttonIcons} />
                    CHECKOUT
                </Button>
                {/* <div css={productNoteText}>
                    *Free delivery for orders over RM100
                </div> */}
            </div>
        );
    };

    const renderDescription = () => {
        return false;

        // return (
        //     <Row>
        //         <Col>
        //             <div css={productDescriptionTitle}>Description & Details</div>
        //             <div css={productDescription}>
        //                 {description}
        //             </div>
        //             {/* <List css={productDescription} type='unstyled'>
        //                 <ul>
        //                     <li>Phasellus iaculis neque</li>
        //                     <li>Purus sodales ultricies</li>
        //                     <li>Vestibulum laoreet porttitor sem</li>
        //                     <li>Ac tristique libero volutpat at</li>
        //                 </ul>
        //             </List> */}
        //         </Col>
        //     </Row>
        // );
    };

    return (
        <Container fluid css={productDetailsContainer}>
            <Row>
                <Col xs={12} md={7} lg={6}>
                    <ProductImage images={imageUrl} />
                </Col>
                <Col xs={12} md={5} lg={6}>
                    <div css={productName}>{name}</div>
                    <div>{id}</div>
                    <div css={productPurityText}>
                        {`Purity: ${purity}`}
                    </div>
                    {/* {renderReview()} */}

                    {renderPricing()}

                    <VariantSelector
                        selectedVariant={selectedVariant}
                        product={product}
                        onVariantSelected={setSelectedVariant}
                    />

                    <div css={productDetailsText}>Quantity</div>
                    <Dropdown isOpen={quantityDropdownOpen} toggle={toggleQuantityDropdown} disabled={availableQuantity <= 1}>
                        <DropdownToggle css={productDropdown} caret>
                            {quantity}
                        </DropdownToggle>
                        <DropdownMenu>
                            {Array.from({ length: availableQuantity }, (x, i) => i + 1).map((x) => (
                                <DropdownItem
                                    css={productVariationText}
                                    key={x}
                                    onClick={() => setQuantity(x)}
                                >
                                    {x}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    {renderAddToWishlistButton()}
                    {renderRemarks()}
                    {renderAddToCartButtons()}
                </Col>
            </Row>
            {/* <hr css={{ width: '100%', border: '1px solid #BF7247', margin: '30px 0px' }} /> */}
            {renderDescription()}
            <LoginOrSignupModal modal={modal} onAuthorized={onAuthorized} toggle={toggle} onSignup={onSignup} />
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getCartAddToCartAttempting(state),
    error: Selectors.getCartAddToCartError(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
    wishlist: Selectors.getUserWishlist(state),
    isLoggedIn: Selectors.getAuthAuthToken(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    addToCart: (item: IAddToCartThunkParams) => dispatch(Actions.cartAddToCart(item)),
    addToWishlistLocalWeb: (product: IProduct) => dispatch(Actions.userAddToWishListLocalWeb({ product })),
    clearError: () => dispatch(Actions.cartClearAddToCartError()),
    getWishlist: () => dispatch(Actions.userGetWishlistLocalWeb()),
    removeFromWishlistLocalWeb: (product: IProduct) => dispatch(Actions.userRemoveFromWishlistLocalWeb({ product })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsRow);
