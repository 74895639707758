import { UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';

import uiSlice from 'Redux/slices/ui';

export interface IForgetPasswordThunkParams {
    email: string;
}

export interface IForgetPasswordThunkResponse {
    error: boolean;
    errorMessage?: string;
}

export default createAsyncThunk<
    IForgetPasswordThunkResponse,
    IForgetPasswordThunkParams,
    AsyncThunkAPI
>('auth/forgetPassword', async (params, thunkAPI) => {
    const { email } = params;

    const response = await thunkAPI.extra.useCases.auth.forgetPassword.execute({ email });

    if (response.status === UseCaseResponseStatus.Success) {
        if (response.data.error) {
            return thunkAPI.rejectWithValue('Something went wrong, plese try again');
        }
        thunkAPI.dispatch(uiSlice.actions.uiSetIsEmailSent(true));
        return response.data;
    }

    if (response.status === UseCaseResponseStatus.Error) {
        return thunkAPI.rejectWithValue('Something went wrong, please try again');
    }

    return thunkAPI.rejectWithValue('Something went wrong, please try again');
});
