/** @jsxImportSource @emotion/react */
import React from 'react';
import { connect } from 'react-redux';
import { IProductCategory } from '@ddot/AddToCartCommon';

import Breadcrumbs from 'Components/Breadcrumbs';

import NavActions from 'Services/Navigation/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';
import { IProductDetails } from 'Redux/slices/products/Entities';

interface BreadcrumbProps {
    productDetails: IProductDetails | null;
    categories: IProductCategory[];
}

interface BreadcrumbOption {
    key: string;
    label: string;
    disabled?: boolean;
    onClick?(): void;
}

const CustomBreadcrumb = (props: BreadcrumbProps): JSX.Element | null => {
    const { productDetails, categories } = props;

    if (productDetails && productDetails.product) {
        const { categoryId, name } = productDetails.product;

        const data: BreadcrumbOption[] = [{
            key: '1',
            label: 'Home',
            onClick: () => NavActions.navToHome(),
        }];

        if (categoryId) {
            const cat = categories.find(item => item.id === categoryId);

            if (cat) {
                data.push({
                    key: '2',
                    label: categoryId,
                    onClick: () => NavActions.navToProductListingWithCategory(cat.id),
                });
            }
        }

        data.push({
            key: '3',
            label: name,
            disabled: true,
        });

        return <Breadcrumbs items={data} style={{ marginTop: 25 }} />;
    }

    return null;
};

const mapStateToProps = (state: RootState) => ({
    productDetails: Selectors.getProductDetails(state),
    categories: Selectors.getProductsCategories(state),
});

export default connect(mapStateToProps)(CustomBreadcrumb);
