import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';
import Colors from 'Themes/colors';

export default {
    container: css({
        margin: '30px 0 30px',
        height: '100%',
    }),
    title: css({
        marginBottom: '20px',
        padding: '0px 40px',
        fontSize: 16,
        fontFamily: Fonts.primaryBold,
        color: Colors.black,
    }),
    label: css({
        fontFamily: Fonts.primary,
        fontSize: 14,
        fontWeight: 400,
        color: Colors.black,
    }),
};
