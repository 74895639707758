/** @jsxImportSource @emotion/react */
import CustomButton from 'Components/Button';
import React from 'react';
import { View } from 'react-native-web';
import { Container } from 'reactstrap';
import NavActions from 'Services/Navigation/Actions';
import {
    TopTitleContainer,
    WishTitle,
    SubTitle,
} from './Styles/OrderConfirmationStyles';

const OrderProcessing = (): JSX.Element | null => {
    return (
        <View>
            <Container fluid className='mb-5'>
                <div css={TopTitleContainer}>
                    <Container css={WishTitle}>PAYMENT PROCESSING</Container>
                    <Container className='mt-2' css={SubTitle}>
                        Your payment is being processed. Please check back later.
                    </Container>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomButton
                        style={{ marginTop: 30 }}
                        onClick={() => NavActions.navToHome()}
                        text='Return to front page'
                    />
                </div>
            </Container>
        </View>
    );
};

export default OrderProcessing;
