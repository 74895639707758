/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

import LoadingModal from 'Components/LoadingModal';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import {
    UserContainer,
    UserTitle,
    UserTabTittle,
    UserTabContainer,
    UserTab,
    UserTabSelected,
    UserTabList,
    UserTabRowCointainer,
    LogoutTitle,
    TabMobileView,
} from './Styles/MainStyles';
import { Person, OrderHistoryIcon, Setting, Logout } from '../../Icons/Icons';
import Account from './Tabs/Account';
import OrderHistory from './Tabs/OrderHistory';

interface UserProps {
    logoutLoading: boolean;
    logoutError: string;
    logout(): void;
}

const User = (props: UserProps) => {
    const TABS = ['account', 'account history', 'settings'];
    const [selectedTab, setselectedTab] = useState(TABS[0]);

    const { logoutLoading, logoutError } = props;

    const onLogout = () => {
        const { logout } = props;
        logout();
    };

    return (
        <View>
            <Container fluid css={UserContainer}>
                <Row css={UserTitle}> Account & Preferences </Row>
                <Row className='justify-content-center'>
                    <Col xs='3' className='border-right d-none d-sm-flex' css={UserTabContainer}>
                        <Row xs='1'>
                            <ul css={UserTabList}>
                                <li>
                                    <div
                                        onClick={() => setselectedTab(TABS[0])}
                                        onKeyPress={() =>
                                            setselectedTab(TABS[0])}
                                        role='button'
                                        tabIndex={0}
                                        css={UserTabRowCointainer}
                                    >
                                        <Row
                                            css={
                                                selectedTab === TABS[0]
                                                    ? UserTabSelected
                                                    : UserTab
                                            }
                                        >
                                            <Person />
                                            <span css={UserTabTittle} className='d-none d-sm-block'>
                                                Account
                                            </span>
                                        </Row>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        onClick={() => setselectedTab(TABS[1])}
                                        onKeyPress={() =>
                                            setselectedTab(TABS[1])}
                                        role='button'
                                        tabIndex={0}
                                        css={UserTabRowCointainer}
                                    >
                                        <Row
                                            css={
                                                selectedTab === TABS[1]
                                                    ? UserTabSelected
                                                    : UserTab
                                            }
                                        >
                                            <OrderHistoryIcon />
                                            <span css={UserTabTittle} className='d-none d-sm-block'>
                                                Order History
                                            </span>
                                        </Row>
                                    </div>
                                </li>
                                {/* <li>
                                    <div
                                        onClick={() => toggle(TABS[2])}
                                        onKeyPress={() =>
                                            setselectedTab(TABS[2])}
                                        role='button'
                                        tabIndex={0}
                                        css={UserTabRowCointainer}
                                    >
                                        <Row
                                            css={
                                                selectedTab === TABS[2]
                                                    ? UserTabSelected
                                                    : UserTab
                                            }
                                        >
                                            <Setting />
                                            <span css={UserTabTittle} className='d-none d-sm-block'>
                                                Settings
                                            </span>
                                        </Row>
                                    </div>
                                </li> */}
                                <li>
                                    <div
                                        onClick={onLogout}
                                        onKeyPress={onLogout}
                                        role='button'
                                        tabIndex={0}
                                        css={UserTabRowCointainer}
                                    >
                                        <Row
                                            css={UserTab}
                                        >
                                            <Logout />
                                            <span css={LogoutTitle} className='d-none d-sm-block'>
                                                Logout
                                            </span>
                                        </Row>
                                    </div>
                                </li>
                            </ul>
                        </Row>
                    </Col>

                    <Row css={TabMobileView} className='d-flex d-sm-none justify-content-center'>
                        <div
                            onClick={() => setselectedTab(TABS[0])}
                            onKeyPress={() =>
                                setselectedTab(TABS[0])}
                            role='button'
                            tabIndex={0}
                        >
                            <Row
                                css={
                                    selectedTab === TABS[0]
                                        ? UserTabSelected
                                        : UserTab
                                }
                            >
                                <Person />
                            </Row>
                        </div>
                        <div
                            onClick={() => setselectedTab(TABS[1])}
                            onKeyPress={() =>
                                setselectedTab(TABS[1])}
                            role='button'
                            tabIndex={0}
                        >
                            <Row
                                css={
                                    selectedTab === TABS[1]
                                        ? UserTabSelected
                                        : UserTab
                                }
                            >
                                <OrderHistoryIcon />
                            </Row>
                        </div>
                        <div
                            onClick={onLogout}
                            onKeyPress={onLogout}
                            role='button'
                            tabIndex={0}
                        >
                            <Row
                                css={
                                    selectedTab === TABS[2]
                                        ? UserTabSelected
                                        : UserTab
                                }
                            >
                                <Logout />
                            </Row>
                        </div>
                    </Row>

                    <Col xs='12' sm='9' fluid>
                        {selectedTab === TABS[0] && <Account />}
                        {selectedTab === TABS[1] && <OrderHistory />}
                    </Col>
                </Row>
                <LoadingModal loading={logoutLoading} />
            </Container>
        </View>
    );
};

const mapStateToProps = (state: RootState) => ({
    logoutLoading: Selectors.getAuthLogoutAttempting(state),
    logoutError: Selectors.getAuthLogoutError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
