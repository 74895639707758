import { css } from '@emotion/react';
import colors from 'Themes/colors';

export const footerContainer = css({
    padding: '20px',
    backgroundColor: colors.background,

    '@media(min-width: 576px)': {
        padding: '30px',
    },

    '@media(min-width: 992px)': {
        padding: '30px 60px',
    },

    '@media(min-width: 1200px)': {
        padding: '30px 120px',
    },
});

export const footerColumnsOuter = css({
    padding: '15px',

    '@media(min-width:576px)': {
        padding: '0px',
    },
});

export const footerColumns = css({
    paddingRight: '0px',
});

export const footerTitle = css({
    font: '16px Archivo-Medium, sans-serif',
    color: colors.black,
    letterSpacing: '2px',
    marginBottom: '14px',
});

export const footerText = css({
    font: '14px Archivo-Regular, sans-serif',
    // color: colors.primary,
    color: '#5C5C5C',
    display: 'inline-block',
    paddingLeft: '10px',

    '@media(min-width:576px) and (max-width:767px)': {
        fontSize: '12px',
    },
});

export const versionText = css({
    font: '12px Archivo-Regular, sans-serif',
    color: '#5C5C5C',
    display: 'inline-block',
    paddingLeft: '10px',
});

export const footerInput = css({
    marginTop: '1.2rem',
    width: '90%',
    borderRadius: '0px',
    border: '1px solid #949494',
    color: '#949494',
    font: '14px Archivo-Regular, sans-serif',
});

export const footerButton = css({
    font: '14px Archivo-Regular, sans-serif',
    color: colors.black,
    backgroundColor: colors.mildbrown,
    padding: '10px 20px',
    border: `2px solid ${colors.mildbrown}`,
    borderRadius: '0px',
    marginTop: '1.2rem',
    '&:hover': {
        color: colors.primary,
        backgroundColor: colors.background,
        border: `2px solid ${colors.mildbrown}`,
    },
    '&:focus': {
        color: colors.background,
        backgroundColor: colors.mildbrown,
        border: `2px solid ${colors.mildbrown}`,
    },
});

export const footerIcon = css({
    width: '24px',
});

export const footerRows = css({
    marginTop: '0.8em',
});
