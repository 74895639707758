/** @jsxImportSource @emotion/react */
import React from 'react';
import { Pressable } from 'react-native-web';
// import Rating from 'react-rating';
import { Button, Container } from 'reactstrap';
import { connect } from 'react-redux';

import { IProduct } from '@ddot/AddToCartCommon';

import Image from 'Components/Image';
import LoadingModal from 'Components/LoadingModal';
// import { StarEmpty, StarFilled } from 'Icons/Icons';
import Utils from 'Lib/Utils';
import NavActions from 'Services/Navigation/Actions';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';
import { IGoldPrice } from 'Redux/slices/home/Entities';

import {
    productImg,
    productPrice,
    productName,
    productContainer,
    // productRating,
    ButtonContainer,
    RemoveWishListButton,
} from '../Styles/WishListProductStyle';

interface ProductProps {
    product: IProduct;
    loading: boolean;
    goldPrice: IGoldPrice;
    removeFromWishlist(product: IProduct): void;
}

const WishListProduct = (props: ProductProps): JSX.Element | null => {
    const { product, loading, removeFromWishlist, goldPrice } = props;

    if (!product) return null;

    const {
        id,
        name,
        currency,
        // overallReviewStars,
    } = product;

    const onPress = () => {
        NavActions.navToProductDetails(id);
    };

    const onRemove = () => {
        removeFromWishlist(product);
    };

    const { minPrice, maxPrice } = Utils.Jemisys.getItemPriceRange(product, goldPrice);
    const image = Utils.Product.getImage(product);

    return (
        <Container fluid css={productContainer}>
            <Pressable onPress={onPress}>
                <Image css={productImg} url={image} alt='Product Img' />
                <div css={productName}>{name}</div>
                <div css={productPrice}>
                    {maxPrice !== minPrice ? `${minPrice} ${currency} -` : ''}
                    {`${maxPrice} ${currency}`}
                </div>
            </Pressable>
            {/* <Rating
                css={productRating}
                initialRating={overallReviewStars}
                emptySymbol={<StarEmpty />}
                fullSymbol={<StarFilled />}
                readonly
            /> */}
            <div css={ButtonContainer}>
                <Button css={RemoveWishListButton} onClick={onRemove}>
                    Remove from wishlist
                </Button>
            </div>

            <LoadingModal loading={loading} />
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getUserRemoveFromWishlistAttempting(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    removeFromWishlist: (product: IProduct) => dispatch(Actions.userRemoveFromWishlistLocalWeb({ product })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WishListProduct);
