/** @jsxImportSource @emotion/react */
import React from 'react';

import { Button } from 'reactstrap';
import { ActivityIndicator } from 'react-native-web';
import { css } from '@emotion/react';

import ErrorMessage from 'Components/Common/ErrorMessage';

import colors from 'Themes/colors';
import font from 'Themes/fonts';

interface ButtonProps {
    loading?: boolean;
    error?: string;
    text: string;
    onClick(): void;
    style?: React.CSSProperties;
    disabled?: boolean;
    negative?: boolean;
}

const CustomButton = (props: ButtonProps): JSX.Element => {
    const { loading, error, text, onClick, style, disabled, negative } = props;

    let buttonStyle = loading ? styles.buttonLoading : styles.button;
    if (negative) buttonStyle = loading ? styles.negativeButtonLoading : styles.negativeButton;

    if (loading) {
        return (
            <div css={buttonStyle} style={style}>
                <ActivityIndicator color='white' />
            </div>
        );
    }

    return (
        <div>
            <ErrorMessage error={error} style={style} />
            <Button css={buttonStyle} onClick={onClick} style={style} disabled={disabled}>
                {text}
            </Button>
        </div>
    );
};

CustomButton.defaultProps = {
    loading: false,
    error: '',
    style: {},
    disabled: false,
    negative: false,
};

const styles = {
    button: css({
        fontSize: 18,
        fontFamily: font.primary,
        color: colors.white,
        backgroundColor: colors.primary,
        border: `2px solid ${colors.primary}`,
        borderRadius: '5px',
        padding: '8px',
        height: 48,
        width: '100%',
        transition: 'ease 0.2s',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.white,
            border: `2px solid ${colors.primary}`,
            color: colors.primary,
        },
        '&:focus': {
            backgroundColor: colors.primary,
            border: `2px solid ${colors.primary}`,
            color: colors.white,
        },
    }),
    buttonLoading: css({
        display: 'flex',
        fontSize: 18,
        fontFamily: font.primary,
        color: colors.white,
        backgroundColor: colors.primary,
        border: `2px solid ${colors.primary}`,
        borderRadius: '5px',
        padding: '8px',
        height: 48,
        width: '100%',
        transition: 'ease 0.2s',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    negativeButton: css({
        fontSize: 18,
        fontFamily: font.primary,
        color: colors.orange,
        backgroundColor: colors.white,
        border: `2px solid ${colors.white}`,
        borderRadius: '5px',
        padding: '8px',
        height: 48,
        width: '100%',
        transition: 'ease 0.2s',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.orange,
            border: `2px solid ${colors.white}`,
            color: colors.white,
        },
        '&:focus': {
            backgroundColor: colors.white,
            border: `2px solid ${colors.white}`,
            color: colors.orange,
        },
    }),
    negativeButtonLoading: css({
        display: 'flex',
        fontSize: 18,
        fontFamily: font.primary,
        color: colors.orange,
        backgroundColor: colors.white,
        border: `2px solid ${colors.white}`,
        borderRadius: '5px',
        padding: '8px',
        height: 48,
        width: '100%',
        transition: 'ease 0.2s',
        justifyContent: 'center',
        alignItems: 'center',
    }),
};

export default CustomButton;
