import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseCaseResponseStatus, IOrder } from '@ddot/AddToCartCommon';

import Utils from 'Lib/Utils';
import { AsyncThunkAPI } from 'Redux/types';

import { ErrorTypes } from '.';

export default createAsyncThunk<
    IOrder,
    IOrder,
    AsyncThunkAPI
>('user/getOrderHistoryDetails', async (order, thunkAPI) => {
    const { customAttributes } = order;

    if (!customAttributes) {
        return thunkAPI.rejectWithValue('Something went wrong. Please refresh the page and try again.');
    }

    const orderNumber = Utils.CustomAttr.getValueFromKey('OrderNo', customAttributes) as string;
    const location = Utils.CustomAttr.getValueFromKey('Location', customAttributes) as string;

    const response = await thunkAPI.extra.useCases.user.getOrderHistoryDetails.execute({
        orderNumber, location,
    });

    if (response.status === UseCaseResponseStatus.Error) {
        const { type, message } = response.data;
        const { GetOrderHistoryDetailsError } = ErrorTypes;

        if (type === GetOrderHistoryDetailsError.MissingParams) {
            return thunkAPI.rejectWithValue('There is something wrong with this order. Please refresh the page and try again.');
        }

        return thunkAPI.rejectWithValue(message || 'Something went wrong. Please try again.');
    }

    const { products, shippingFee } = response.data;

    return {
        ...order,
        products,
        shippingFee,
    };
});
