/** @jsxImportSource @emotion/react */
import { Email, Facebook, Instagram, WeChat, Phone, Xiaohongshu } from 'Icons/Icons';
import React from 'react';
import { Input, Button, Container, Row, Col } from 'reactstrap';
import {
    footerButton,
    footerColumns,
    footerColumnsOuter,
    footerContainer,
    footerIcon,
    footerInput,
    footerRows,
    footerText,
    footerTitle,
    versionText,
} from '../Styles/FooterStyle';
import PackageJson from '../../../package.json';

const Footer = (): JSX.Element => {
    return (
        <Container fluid css={footerContainer}>
            <Row>
                {/* hidden */}
                <Col hidden xs={12} sm={4} css={footerColumnsOuter}>
                    <div css={footerTitle}>Newsletter</div>
                    <div css={footerText} className='p-0'>
                        Subscribe us to get best offer designs.
                    </div>
                    <Input
                        type='email'
                        name='email'
                        placeholder='Enter Your Email'
                        css={footerInput}
                    />
                    <Button css={footerButton}>SUBSCRIBE</Button>
                </Col>
                <Col xs={12} sm={4} css={footerColumnsOuter}>
                    <div css={footerTitle}>Follow Us</div>
                    <a href='https://facebook.com/SengFongJewellery5201313/' target='_blank' rel='noreferrer'>
                        <Row css={footerRows}>
                            <Col xs={1} css={footerColumns}>
                                <Facebook css={footerIcon} />
                            </Col>
                            <Col xs='auto' css={footerColumns}>
                                <div css={footerText}>SengFongJewellery</div>
                            </Col>
                        </Row>
                    </a>
                    <a href='https://www.instagram.com/sengfong_jwl/' target='_blank' rel='noreferrer'>
                        <Row css={footerRows}>
                            <Col xs={1} css={footerColumns}>
                                <Instagram css={footerIcon} />
                            </Col>
                            <Col xs='auto' css={footerColumns}>
                                <div css={footerText}>sengfong_jwl</div>
                            </Col>
                        </Row>
                    </a>
                    <Row css={footerRows}>
                        <Col xs={1} css={footerColumns}>
                            <WeChat css={footerIcon} />
                        </Col>
                        <Col xs='auto' css={footerColumns}>
                            <div css={footerText}>sengfongjwl / sengfongjwl2</div>
                        </Col>
                    </Row>
                    <a href='https://www.xiaohongshu.com/user/profile/6039b699000000000101c55e' target='_blank' rel='noreferrer'>
                        <Row css={footerRows}>
                            <Col xs={1} css={footerColumns}>
                                <Xiaohongshu css={footerIcon} />
                            </Col>
                            <Col xs='auto' css={footerColumns}>
                                <div css={footerText}>小红书</div>
                            </Col>
                        </Row>
                    </a>
                </Col>
                <Col xs={12} sm={4} css={footerColumnsOuter}>
                    <div css={footerTitle}>Contact Us</div>
                    <Row css={footerRows}>
                        <Col xs={1} css={footerColumns}>
                            <Phone css={footerIcon} />
                        </Col>
                        <div style={{ flex: 1 }}>
                            <Col xs={11} css={footerColumns}>
                                <a css={footerText} href='tel:+60143292282'>
                                    (+60) 14-329 2282
                                </a>
                            </Col>
                            <Col xs={11} css={footerColumns}>
                                <a css={footerText} href='tel:+60164304397'>
                                    (+60) 16-430 4397
                                </a>
                            </Col>
                        </div>
                    </Row>
                    <Row css={footerRows}>
                        <Col xs={1} css={footerColumns}>
                            <Email css={footerIcon} />
                        </Col>
                        <Col xs={11} css={footerColumns}>
                            <a
                                css={footerText}
                                href='mailto:sengfongjewellery@gmail.com'
                            >
                                sengfongjewellery@gmail.com
                            </a>
                        </Col>
                    </Row>

                    <Row css={footerRows}>
                        <Col xs={11} css={footerColumns}>
                            <div css={versionText} className='p-0'>
                                {`v${PackageJson.version}`}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;
