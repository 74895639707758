import { ICartProduct, UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AsyncThunkAPI } from 'Redux/types';

import cartSlice from 'Redux/slices/cart';

import { ErrorTypes } from '.';

export interface IStockCheckResponse {
    stockUnavailable: ICartProduct[];
}

export interface IStockCheckParams {
    products: ICartProduct[];
    callback?(): void;
}

export default createAsyncThunk<
    IStockCheckResponse,
    IStockCheckParams,
    AsyncThunkAPI
>('ui/stockCheck', async (params, thunkAPI) => {
    const { products, callback } = params;
    const response = await thunkAPI.extra.useCases.order.stockCheck.execute({ products });

    if (response.status === UseCaseResponseStatus.Success) {
        const { stockUnavailable, products: stockCheckedProducts } = response.data;

        if (stockUnavailable.length > 0) {
            // some items out of stock
            return { stockUnavailable };
        }

        // if all in stock, we can proceed
        if (callback) callback();

        thunkAPI.dispatch(cartSlice.actions.cartSetCartItemAvailableVariant(stockCheckedProducts));
        return { stockUnavailable };
    }

    if (response.data.type === ErrorTypes.StockCheckError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again');
    }

    return thunkAPI.rejectWithValue('Something went wrong, please try again later');
});
