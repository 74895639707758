import authSlice from './slices/auth';
import authControllers from './slices/auth/controllers';
import homeSlice from './slices/home';
import homeControllers from './slices/home/controllers';
import productSlice from './slices/products';
import productControllers from './slices/products/controllers';
import cartSlice from './slices/cart';
import cartControllers from './slices/cart/controllers';
import uiSlice from './slices/ui';
import userControllers from './slices/user/controllers';
import userSlice from './slices/user';
import uiControllers from './slices/ui/controllers';

export default {
    ...authSlice.actions,
    ...authControllers,
    ...homeSlice.actions,
    ...homeControllers,
    ...productSlice.actions,
    ...productControllers,
    ...cartSlice.actions,
    ...cartControllers,
    ...uiSlice.actions,
    ...userControllers,
    ...userSlice,
    ...uiControllers,
};
