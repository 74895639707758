import { css } from '@emotion/react';
import colors from '../../../Themes/colors';
import fonts from '../../../Themes/fonts';

export const productDetailsContainer = css({
    padding: '0px 20px',

    '@media(min-width:576px)': {
        padding: '0px 40px',
    },

    '@media(min-width:992px)': {
        padding: '30px 80px',
    },

    '@media(min-width:1200px)': {
        padding: '30px 150px',
    },

    '@media(min-width:1500px)': {
        padding: '40px 350px',
    },
});

export const productImgContainer = css({
    textAlign: 'center',
    padding: '40px 0px 0px',
});

export const productImg = css({
    width: '80%',

    '& .iiz__img': {
        width: '100%',
        height: 300,
        objectFit: 'contain',
    },
});

export const productCarousel = css({
    padding: '10px 20px',
    '& .BrainhubCarousel__arrows': {
        backgroundColor: colors.white,
        span: {
            borderColor: colors.black,
        },
        '&:hover': {
            backgroundColor: colors.white,
            span: {
                borderColor: colors.black,
            },
        },
        '&:disabled': {
            span: {
                borderColor: colors.gray,
            },
        },
    },
});

export const productImgCarousel = css({
    height: 100,
    width: '100%',
    objectFit: 'contain',

    '&:hover': {
        border: '1px solid black',
    },
});

export const productName = css({
    font: '35px Archivo-Medium, sans-serif',
    color: colors.black,
    marginTop: '20px',
    fontSize: '30px',

    '@media(min-width:768px)': {
        marginTop: '0px',
        fontSize: '35px',
    },
});

export const productPurityText = css({
    ...fonts.productNoteText,
    color: colors.primary,
});

export const productRatingRow = css({
    marginTop: '1.2rem',
    marginBottom: '1.2rem',
});

export const productRatingText = css({
    ...fonts.productRatingText,
    color: colors.black,
    marginLeft: '10px',
});

export const productRatingStars = css({
    width: '20px',
    height: '20px',

    '@media(min-width:576px)': {
        width: '24px',
        height: '24px',
    },
});

export const productPrice = css({
    ...fonts.productPriceText,
    color: colors.primary,
    marginTop: '1.2rem',
    fontSize: '20px',

    '@media(min-width:992px)': {
        fontSize: '25px',
    },
});

export const productLaborChargeMsg = css({
    font: '14px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: 5,
});

export const productDetailsText = css({
    font: '20px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '1.2em',
    fontSize: '18px',
    alignItems: 'center',
    display: 'flex',

    '@media(min-width:992px)': {
        fontSize: '20px',
    },
});

export const productVariationText = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
    padding: '5px 20px',
});

export const productDropdown = css({
    marginTop: '0.6em',
    color: colors.black,
    backgroundColor: colors.white,
    '&:hover,&:focus': { color: colors.black, backgroundColor: colors.white },
});

export const addToCartButtonContainer = css({
    display: 'flex',
    height: 140,
    alignItems: 'center',
    justifyContent: 'center',
});

export const addToCartButton = css({
    display: 'block',
    font: '18px Archivo-Regular, sans-serif',
    fontSize: '16px',
    color: colors.black,
    backgroundColor: colors.white,
    border: `2px solid ${colors.black}`,
    borderRadius: '3px',
    height: 45,
    width: '220px',
    padding: '6px',
    marginTop: '1.2rem',
    transition: 'ease 0.2s',
    '&:hover,&:focus': {
        border: `2px solid ${colors.black}`,
        backgroundColor: colors.black,
        fill: colors.white,
    },

    '&:focus': {
        border: `2px solid ${colors.primary}`,
        backgroundColor: colors.black,
        fill: colors.white,
    },

    '@media(min-width:992px)': {
        width: '300px',
        fontSize: '18px',
    },
});

export const checkoutButton = css({
    display: 'block',
    font: '18px Archivo-Regular, sans-serif',
    fontSize: '16px',
    color: colors.white,
    backgroundColor: colors.primary,
    border: '2px solid #BF7247',
    borderRadius: '3px',
    width: '220px',
    padding: '6px',
    height: 45,
    marginTop: '1.2rem',
    transition: 'ease 0.2s',
    fill: colors.white,
    '&:hover,&:focus': {
        border: '2px solid #BF7247',
        backgroundColor: colors.white,
        color: colors.primary,
        fill: colors.primary,
    },
    '&:active': {
        border: `2px solid ${colors.primary}`,
    },

    '@media(min-width:992px)': {
        width: '300px',
        fontSize: '18px',
    },
});

export const checkoutButtonLoading = css({
    display: 'block',
    font: '18px Archivo-Regular, sans-serif',
    fontSize: '16px',
    color: colors.white,
    backgroundColor: colors.primary,
    border: '2px solid #BF7247',
    borderRadius: '3px',
    width: '220px',
    padding: '6px',
    height: 45,
    marginTop: '1.2rem',
    transition: 'ease 0.2s',
    fill: colors.white,

    '&:hover,&:focus': {
        border: '2px solid #BF7247',
        backgroundColor: colors.primary,
        color: colors.white,
        fill: colors.white,
    },
    '&:active': {
        border: `2px solid ${colors.primary}`,
    },
    '@media(min-width:992px)': {
        width: '300px',
        fontSize: '18px',
    },
});

export const buttonIcons = css({
    width: '25px',
    height: '25px',
    marginRight: '5px',

    '@media(min-width:992px)': {
        width: '30px',
        height: '30px',
    },
});

export const wishlistButton = css({
    font: '20px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '1.2em',
    fontSize: '18px',
    alignItems: 'center',
    display: 'flex',
    fill: colors.black,
    '&:hover': {
        fill: colors.red,
    },
    '&:focus': {
        fill: colors.red,
    },
    '&:active': {
        fill: colors.red,
    },

    '@media(min-width:992px)': {
        fontSize: '20px',
    },
});

export const filledWishlistButton = css({
    font: '20px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '1.2em',
    fontSize: '18px',
    alignItems: 'center',
    display: 'flex',
    fill: colors.red,

    '@media(min-width:992px)': {
        fontSize: '20px',
    },
});

export const productNoteText = css({
    ...fonts.productNoteText,
    color: colors.gray,
    marginTop: '1.2em',
    fontSize: '13px',

    '@media(min-width:992px)': {
        fontSize: '14px',
    },
});

export const productDescriptionTitle = css({
    ...fonts.productDescriptionTitle,
    color: colors.black,
});

export const productDescription = css({
    ...fonts.productDescriptionText,
    color: colors.black,
    marginTop: '1.2em',
});

export const similarItemsContainer = css({
    padding: '0px 20px',

    '@media(min-width:576px)': {
        padding: '10px 40px',
    },

    '@media(min-width:992px)': {
        padding: '20px 60px',
    },

    '@media(min-width:1200px)': {
        padding: '20px 150px',
    },

    '@media(min-width:1500px)': {
        padding: '20px 300px',
    },
});

export const similarItemsTitle = css({
    ...fonts.similarItemsTitle,
    color: colors.black,
    textAlign: 'center',
    margin: '30px 0px 0px',

    '@media(min-width:576px)': {
        margin: '30px 0px',
    },
});

export const similarItemsCarousel = css({
    padding: '0px',
    '& .BrainhubCarousel__arrows': {
        backgroundColor: colors.white,
        span: {
            borderColor: colors.black,
            height: '10px',
            width: '10px',
        },
        '&:hover': {
            backgroundColor: colors.white,
            span: {
                borderColor: colors.black,
            },
        },
        '&:disabled': {
            span: {
                borderColor: colors.gray,
            },
        },

        '@media(min-width:576px)': {
            height: '30px',
            width: '30px',
        },
    },

    '@media(min-width:576px)': {
        padding: '10px 20px',
    },
});

export const productReviewsTitle = css({
    ...fonts.productReviewsTitle,
    color: colors.black,
    marginTop: '1.2em',
});

export const productReviewsContainer = css({
    padding: '0px 20px',

    '@media(min-width:576px)': {
        padding: '10px 40px',
    },

    '@media(min-width:992px)': {
        padding: '20px 60px',
    },

    '@media(min-width:1200px)': {
        padding: '20px 150px',
    },

    '@media(min-width:1500px)': {
        padding: '20px 300px',
    },
});

export const productReviewsCount = css({
    productRatingText,
    color: colors.gray,
    marginLeft: '10px',
});

export const reviewImg = css({
    width: '80%',
    borderRadius: '50%',
});

export const reviewAuthor = css({
    ...fonts.productReviewAuthor,
    color: colors.black,
});

export const reviewDate = css({
    ...fonts.productReviewDate,
    color: colors.gray,
});

export const reviewDescription = css({
    ...fonts.productDescriptionText,
    color: colors.black,
    marginTop: '1.2em',
});

export const reviewVariation = css({
    ...fonts.productNoteText,
    color: colors.gray,
    marginTop: '1.2em',
});

export const reviewLoadMoreButton = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.primary,
    backgroundColor: colors.lightbrown,
    border: '0px',
    borderRadius: '3px',
    padding: '12px 15px',
    margin: '25px 0px',
    transition: 'ease 0.2s',
    '&:hover,&:focus': {
        backgroundColor: colors.primary,
        color: colors.white,
    },
});

export const remarkInput = css({
    marginTop: 10,
    width: 250,
});
