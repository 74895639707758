import { UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';

import homeSlice from 'Redux/slices/home';
import NavActions from 'Services/Navigation/Actions';

export interface IHelloResponse {
    isMaintenance: boolean;
    goldPrice: {
        gold750: number;
        gold916: number;
        gold999: number;
    }
}

export default createAsyncThunk<
    IHelloResponse,
    void,
    AsyncThunkAPI
>('hello/getHello', async (params, thunkAPI) => {
    const response = await thunkAPI.extra.useCases.product.getHello.execute();

    if (response.status === UseCaseResponseStatus.Success) {
        const { isMaintenance, goldPrice: { gold750, gold916, gold999, gold9999 } } = response.data;

        thunkAPI.dispatch(homeSlice.actions.setIsMaintenance(isMaintenance));

        if (!isMaintenance) {
            thunkAPI.dispatch(homeSlice.actions.setGoldPrices({ gold750, gold916, gold999, gold9999 }));
        } else {
            NavActions.navResetToMaintenance();
        }

        return response.data;
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
