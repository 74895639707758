export default {
    black: '#000000',
    background: '#FCF3EE',
    mildbrown: '#DBAA8D',
    lightbrown: '#F0DFCB',
    lighterbrown: '#faf3eb',
    bordergrey: '#DEE2E6',
    orange: '#BF7247',
    textgrey: '#5C5C5C',
    grey: '#717171',
    lightgreen: '#CEE4C6',
    darkgreen: '#008425',
    lightred: '#ff4747',
    darkred: '#731e1e',

    primary: '#BF7247',
    white: '#ffffff',
    secondary: '#28a84b',
    darkBlue: '#081b48',
    lightBlue: '#B3D8FF',
    backgroundGray: '#f7f7f7',
    veryLightGray: '#F8F9FA',
    gray: '#717171',
    red: '#F0675B',
    deepBlue: '#25448b',

    green: '#32a852',

    disabledGray: '#B9B9B9',

    border: '#d7d7d7',
    success: '#00FF1A',

    lightGray: '#F3F3F3',
    placeholderColor: '#6c6c6c',

    successCheck: '#27B811',
    successText: '#28BD2E',
    secondaryText: '#A7A7A7',

    pending: '#F29339',

    error: '#ff0033',
};
