import { css } from '@emotion/react';
import colors from '../../../Themes/colors';

const { black, orange } = colors;

export const TopTitleContainer = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
});

export const WishTitle = css({
    display: 'flex',
    justifyContent: 'center',
    font: '40px FRL-Regular, serif',
    fontWeight: '700',
    color: orange,
});

export const SubTitle = css({
    display: 'flex',
    justifyContent: 'center',
    font: '18px Archivo-Regular, serif',
    color: black,
});

export const ContainerBorder = css({
    padding: '10px 10px',
    borderStyle: 'solid',
    borderColor: orange,
    borderRadius: '4px',
    '@media(min-width: 1000px)': {
        padding: '40px 140px',
    },
});

export const PrintContainer = css({
    font: '14px Archivo-Regular, serif',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'colum',
    alignItems: 'center',
});
