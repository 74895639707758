import { css } from '@emotion/react';
import Fonts from 'Themes/fonts';
import colors from 'Themes/colors';

export const productsRowContainer = css({
    padding: '40px 10% 0px 10%',
    backgroundColor: colors.white,
    textAlign: 'center',
    marginBottom: 30,

    '@media(min-width:992px)': {
        padding: '80px 15% 40px 15%',
    },

    '@media(min-width:1200px)': {
        padding: '80px 15% 40px 15%',
    },

    '@media(min-width:1500px)': {
        padding: '80px 15% 40px 15%',
    },
});

export const productItem = css({
    cursor: 'pointer',
    paddingBottom: 10,
    paddingTop: 10,
    borderWidth: 1,
    borderColor: 'transparent',
    margin: 5,

    '@media(min-width:576)': {
        margin: 10,
    },

    ':hover': {
        WebkitBoxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
    },
});

export const productPrice = css({
    fontFamily: Fonts.tertiaryBold,
    fontSize: 15,
    color: colors.primary,
    marginTop: 10,
    textAlign: 'center',

    '@media(min-width:576px)': {
        fontSize: '18px',
    },
});

export const productName = css({
    font: 'Archivo-Regular, sans-serif',
    fontFamily: Fonts.tertiary,
    fontSize: 16,
    marginTop: 5,
    color: '#949494',
    textAlign: 'center',

    '@media(min-width:576px)': {
        fontSize: '18px',
        marginBottom: '0px',
    },
});

export const productImg = css({
    width: '100%',
    height: 200,
    objectFit: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const viewMoreContainer = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '40px 0px 0px',
    width: '100%',

    '@media(min-width:768px)': {
        margin: '0px',
    },
});

export const viewMoreText = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.primary,
});

export const viewMoreCollection = css({
    font: '24px FRL-Regular, serif',
    color: '#231F20',
    marginTop: '1.2em',
});
