import { UseCaseResponseStatus, IFpxBankList } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Selectors from 'Redux/Selectors';

import { AsyncThunkAPI } from 'Redux/types';

import { OrderErrorTypes } from '.';

export default createAsyncThunk<
    IFpxBankList[], // response
    void,
    AsyncThunkAPI
>('order/getFpxBankList', async (params, thunkAPI) => {
    const response = await thunkAPI.extra.useCases.order.getFpxBankList.execute();

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }

    if (response.data.type === OrderErrorTypes.GetFpxBankListError.ServerError) {
        return thunkAPI.rejectWithValue(response.data.message || 'Something went wrong. Please try again.');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
