import React from 'react';

import { View, Pressable, Text, StyleSheet } from 'react-native-web';

import { RightChevron } from 'Icons/Icons';
import Fonts from 'Themes/fonts';
import Colors from 'Themes/colors';

interface BreadcrumbProps {
    items: {
        key: string;
        label: string;
        onClick?(): void;
        disabled?: boolean;
    }[];
    style?: React.CSSProperties;
}

const Breadcrumbs = (props: BreadcrumbProps): JSX.Element => {
    const { items, style } = props;

    const renderBody = () => {
        return items.map((item, index) => {
            const { key, label, onClick, disabled } = item;

            const isLastItem = index === (items.length - 1);

            const labelStyle = disabled ? styles.itemTextDisabled : styles.itemText;

            return (
                <View style={styles.row} key={key}>
                    <Pressable
                        style={styles.item}
                        onPress={onClick}
                        disabled={disabled}
                    >
                        <Text style={labelStyle}>
                            {label}
                        </Text>
                    </Pressable>

                    {!isLastItem && (
                        <RightChevron style={{ fill: Colors.disabledGray, marginLeft: 5 }} />
                    )}
                </View>
            );
        });
    };

    return (
        <View style={[styles.container, style]}>
            {renderBody()}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2.4em',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    row: {
        flexDirection: 'row',
        marginHorizontal: 2.5,
        alignItems: 'center',
    },
    item: {
        alignItems: 'center',
        cursor: 'pointer',
    },
    itemText: {
        fontFamily: Fonts.primary,
        color: Colors.black,
        fontSize: 16,
    },
    itemTextDisabled: {
        fontFamily: Fonts.primary,
        fontSize: 16,
        color: Colors.disabledGray,
    },
});

Breadcrumbs.defaultProps = {
    style: {},
};

export default Breadcrumbs;
