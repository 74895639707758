/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import { Container, Row } from 'reactstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import PlaceholderImage from 'Assets/Images/PlaceholderImage';

import {
    productCarousel,
    productImg,
    productImgCarousel,
    productImgContainer,
} from '../Styles/ProductDetailsStyles';

const ProductImage = (props) => {
    const { images } = props;

    const [currentImageUrl, setCurrentImageUrl] = useState(images[0]);
    const [imageError, setImageError] = useState(false);

    const handleImageError = (e) => {
        e.target.src = PlaceholderImage.NoImagePlaceholder;
    };

    useEffect(() => {
        if (images.length) {
            setCurrentImageUrl(images[0]);
        }
    }, [images]);

    const itemList = images.map((imageUrl) => (
        <img
            src={imageUrl}
            alt=''
            onError={(e) => handleImageError(e)}
            css={[
                productImgCarousel,
                {
                    border:
                        currentImageUrl === imageUrl
                            ? '1px solid black'
                            : '',
                },
            ]}
            onMouseEnter={() => setCurrentImageUrl(imageUrl)}
        />
    ));

    const zoomImageSrc = imageError ? PlaceholderImage.NoImagePlaceholder : currentImageUrl;

    return (
        <Container css={productImgContainer}>
            <InnerImageZoom
                css={productImg}
                src={zoomImageSrc}
                zoomScale={1.6}
                zoomType='hover'
            />

            <img
                style={{ height: 0, width: 0, opacity: 0 }}
                src={currentImageUrl}
                onError={() => setImageError(true)}
                onLoad={() => setImageError(false)}
                alt='error handle'
            />

            <Row>
                <Carousel
                    draggable={false}
                    offset={20}
                    plugins={[
                        {
                            resolve: slidesToShowPlugin,
                            options: {
                                numberOfSlides: 4,
                            },
                        },
                        'arrows',
                    ]}
                    css={productCarousel}
                    slides={itemList}
                />
            </Row>
        </Container>
    );
};

export default ProductImage;
