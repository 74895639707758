/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Pressable, Text, ActivityIndicator } from 'react-native-web';

import NavActions from 'Services/Navigation/Actions';

import {
    hoverColEntry,
    hoverCatalogContainer,
} from '../Styles/HeaderStyle';

interface CatalogEntry {
    id: string;
    name: string;
}

interface HoverCatalogProps {
    onMouseEnter(): void;
    onMouseLeave(): void;
    show?: boolean;
    loading: boolean;
    catalog: CatalogEntry[] | null;
    onDismissHover(): void;
}

const HoverCatalog = (props: HoverCatalogProps): JSX.Element | null => {
    const {
        loading, show, catalog, onMouseEnter, onMouseLeave, onDismissHover,
    } = props;

    let columns = [];

    const renderBody = () => {
        if (loading) {
            return (
                <div style={{ height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator />
                </div>
            );
        }

        if (!catalog) return null;

        columns = catalog.map((item) => {
            const { id, name } = item;
            return (
                <Col key={id} style={{ width: 125, margin: 10 }}>
                    <Pressable onPress={() => {
                        NavActions.navToProductListingWithCategory(item.id);
                        onDismissHover();
                    }}
                    >
                        <Text css={hoverColEntry}>{name}</Text>
                    </Pressable>
                </Col>
            );
        });

        return <Row xs={columns.length}>{columns}</Row>;
    };

    if (show && catalog) {
        return (
            <Container
                fluid
                css={hoverCatalogContainer}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {renderBody()}
            </Container>
        );
    }

    return null;
};

HoverCatalog.defaultProps = {
    show: false,
};

export default HoverCatalog;
