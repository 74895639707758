import React from 'react';
import { ReactComponent as starFilled } from './star-filled.svg';
import { ReactComponent as starEmpty } from './star-empty.svg';
import { ReactComponent as cartBag } from './cart-bag.svg';
import { ReactComponent as person } from './person.svg';
import { ReactComponent as orderHistory } from './order-history.svg';
import { ReactComponent as setting } from './setting.svg';
import { ReactComponent as logout } from './logout.svg';
import { ReactComponent as back } from './back.svg';
import { ReactComponent as filter } from './filter.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as heart } from './heart.svg';
import { ReactComponent as checkout } from './checkout.svg';
import { ReactComponent as pencil } from './pencil.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as print } from './print.svg';
import { ReactComponent as leftArrow } from './left-arrow.svg';
import { ReactComponent as rightChevron } from './right-chevron.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as atmCard } from './atm-card.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as facebook } from './facebook.svg';
import { ReactComponent as instagram } from './instagram.svg';
import { ReactComponent as navigation } from './navigation.svg';
import { ReactComponent as phone } from './phone.svg';
import { ReactComponent as twitter } from './twitter.svg';
import { ReactComponent as cone } from './traffic-cone.svg';
import { ReactComponent as bank } from './bank.svg';
import { ReactComponent as sadEmoji } from './sad-emoji.svg';
import { ReactComponent as maintenance } from './maintenance.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as wechat } from './wechat.svg';
import { ReactComponent as fpx } from './fpx.svg';
import { ReactComponent as xiaohongshu } from './xiaohongshu.svg';
import { ReactComponent as heartFilled } from './heart-filled.svg';

export const StarFilled = starFilled;
export const StarEmpty = starEmpty;
export const CartBag = cartBag;
export const Person = person;
export const OrderHistoryIcon = orderHistory;
export const Setting = setting;
export const Logout = logout;
export const Back = back;
export const Filter = filter;
export const Close = close;
export const Heart = heart;
export const Checkout = checkout;
export const Pencil = pencil;
export const Trash = trash;
export const Print = print;
export const LeftArrow = leftArrow;
export const RightChevron = rightChevron;
export const Edit = edit;
export const AtmCard = atmCard;
export const Email = email;
export const Facebook = facebook;
export const Instagram = instagram;
export const Navigation = navigation;
export const Phone = phone;
export const Twitter = twitter;
export const TrafficCone = cone;
export const Bank = bank;
export const SadEmoji = sadEmoji;
export const Maintenance = maintenance;
export const Search = search;
export const WeChat = wechat;
export const Fpx = fpx;
export const Xiaohongshu = xiaohongshu;
export const HeartFilled = heartFilled;
