const primary = 'Archivo-Regular, sans-serif';
const secondary = 'FRL-Regular, serif';
const archivoRegular = 'Archivo-Regular, sans-serif';
const archivoMedium = 'Archivo-Medium, sans-serif';
const archivoBold = 'Archivo-Bold, sans-serif';
const frlRegular = 'FRL-Regular, serif';
const frlMedium = 'FRL-Medium, serif';
const frlBold = 'FRL-Bold, serif';

export default {
    primary,
    secondary,
    primaryBold: archivoBold,
    tertiary: archivoRegular,
    tertiaryBold: archivoBold,

    sectionTitle: {
        fontFamily: primary,
        fontSize: 15,
        fontWeight: '600',
    },

    productRatingText: {
        fontFamily: archivoRegular,
        fontSize: '18px',
    },

    productPriceText: {
        fontFamily: archivoMedium,
        fontSize: '25px',
        fontStyle: 'italic',
    },

    productNoteText: {
        fontFamily: archivoRegular,
        fontSize: '14px',
    },

    productDescriptionTitle: {
        fontFamily: archivoMedium,
        fontSize: '20px',
    },

    productDescriptionText: {
        fontFamily: archivoRegular,
        fontSize: '16px',
    },

    similarItemsTitle: {
        fontFamily: archivoMedium,
        fontSize: '30px',
    },

    productReviewsTitle: {
        fontFamily: archivoRegular,
        fontSize: '20px',
    },

    productReviewAuthor: {
        fontFamily: archivoMedium,
        fontSize: '16px',
    },

    productReviewDate: {
        fontFamily: archivoMedium,
        fontSize: '14px',
    },

    cartTitle: {
        fontFamily: archivoBold,
        fontSize: '25px',
    },

    cartItems: {
        fontFamily: archivoBold,
        fontSize: '20px',
    },

    orderSummaryText: {
        fontFamily: archivoMedium,
        fontSize: '18px',
    },

    saveAccountTitle: {
        fontFamily: archivoBold,
        fontSize: '18px',
    },
};
