/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Row, Label, FormGroup, Col, Button } from 'reactstrap';

import ErrorMessage from 'Components/Common/ErrorMessage';
import ValidationCheck from 'Components/Common/Validation';
import LoadingButton from 'Components/Button';

import UpdatePassword from 'Containers/User/Components/UpdatePassword';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import LOV from 'Lib/LOVs/ShippingAddressLovs';
import NavActions from 'Services/Navigation/Actions';

import {
    AccountTitle,
    AccountLabel,
    SaveButton,
    CancelButton,
} from '../Styles/AccountStyles';

function Account(props) {
    const [error, setError] = useState('');

    const { isEditing, setIsEditing, userInfo, updateUserInfo, updateUserInfoLoading, updateUserInfoError } = props;

    if (!userInfo) {
        NavActions.navResetToHome();
        return null;
    }

    const { firstName, lastName, phoneNumber, email: userEmail, address } = userInfo;

    const { value: firstname, setValue: setfirstname, bind: bindfirstname, reset: resetFirstName } = useInput(firstName);
    const { value: lastname, setValue: setlastname, bind: bindlastname, reset: resetLastName } = useInput(lastName);
    const { value: email, setValue: setemail, bind: bindemail, reset: resetEmail } = useInput(userEmail);
    const { value: phonenumber, setValue: setphonenumber, bind: bindphonenumber, reset: resetPhoneNumber } = useInput(phoneNumber);
    const { value: address1, setValue: setaddress1, bind: bindaddress1, reset: resetAddress1 } = useInput(address?.address1);
    const { value: address2, setValue: setaddress2, bind: bindaddress2, reset: resetAddress2 } = useInput(address?.address2);
    const { value: city, setValue: setCity, bind: bindCity, reset: resetCity } = useInput(address?.city);
    const { value: state, setValue: setState, bind: bindState, reset: resetState } = useInput(address?.state);
    const { value: postcode, setValue: setpostcode, bind: bindpostcode, reset: resetpostCode } = useInput(address?.postCode);
    const { value: country, setValue: setCountry, bind: bindCountry, reset: resetCountry } = useInput(address?.country);

    const onSaveChanges = () => {
        setError('');

        const postCodeCheck = /^[0-9]+$/;
        const emailCheck = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const phoneNumberCheck = /^[0-9]+$/;
        const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
        const symbolCheck = /[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/;
        const sqlCheck = /['"']/;
        const addressCheck = /["']+/;
        const stringCheck = (value) => !value || !value.trim().length || emojiRegex.test(value);

        if (stringCheck(firstName) || symbolCheck.test(firstname)) {
            setError('Invalid first name. Please try again.');
        } else if (stringCheck(lastName) || symbolCheck.test(lastname)) {
            setError('Invalid last name. Please try again.');
        } else if (stringCheck(address1) || addressCheck.test(address1) || sqlCheck.test(address1)) {
            setError('Invalid address. Please try again.');
        } else if (address2 && (stringCheck(address2) || sqlCheck.test(address2))) {
            setError('Invalid address. Please try again.');
        } else if (emojiRegex.test(email) || !emailCheck.test(email)) {
            setError('Invalid email, please fill it in correctly');
        } else if (emojiRegex.test(phonenumber) || !phoneNumberCheck.test(phonenumber)) {
            setError('Invalid phone number, please fill it in correctly.');
        } else if (emojiRegex.test(postcode) || !postCodeCheck.test(postcode)) {
            setError('Invalid postcode, please fill it in correctly.');
        } else if (stringCheck(city) || sqlCheck.test(city)) {
            setError('Invalid city. Please try again.');
        } else {
            const data = {
                firstName: firstname,
                lastName: lastname,
                email,
                address1,
                address2,
                city,
                state,
                country,
                postCode: postcode,
                phoneNumber,
            };
            updateUserInfo(data);
        }
    };

    const onCancelPressed = () => {
        resetFirstName();
        resetLastName();
        resetpostCode();
        resetPhoneNumber();
        resetAddress1();
        resetAddress2();
        resetCity();
        resetCountry();
        resetState();
        resetEmail();

        setIsEditing(false);
        setError('');
    };

    const renderCountryOptions = () => {
        return LOV.map(item => {
            return (
                <option value={item.country} key={item.country}>{item.country}</option>
            );
        });
    };

    const renderStateOptions = () => {
        return LOV.map(item => {
            if (item.country === country) {
                return item.states.map(states => {
                    return <option key={states}>{states}</option>;
                });
            } return null;
        });
    };

    return (
        <div>
            <Form>
                <Row css={AccountTitle}>Account</Row>
                <Row>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='First Name'
                            labelCss={AccountLabel}
                            value={firstname}
                            type='text'
                            name='firstname'
                            {...bindfirstname}
                            {...resetFirstName}
                            disabled={!isEditing}
                            errorMessage='Invalid first name'
                        />
                    </Col>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Last Name'
                            labelCss={AccountLabel}
                            value={lastname}
                            type='text'
                            name='lastname'
                            {...bindlastname}
                            {...resetLastName}
                            disabled={!isEditing}
                            errorMessage='Invalid last name'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Email'
                            labelCss={AccountLabel}
                            value={email}
                            type='email'
                            name='email'
                            {...bindemail}
                            {...resetEmail}
                            disabled
                            errorMessage='Invalid email'
                        />
                    </Col>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Phone Number'
                            labelCss={AccountLabel}
                            value={phonenumber}
                            type='text'
                            name='phonenumber'
                            {...bindphonenumber}
                            {...resetPhoneNumber}
                            disabled={!isEditing}
                            errorMessage='Invalid phone number'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Country'
                            labelCss={AccountLabel}
                            value={state}
                            type='select'
                            name='country'
                            {...bindState}
                            {...resetState}
                            disabled={!isEditing}
                            errorMessage='Invalid country'
                        >
                            {renderCountryOptions()}
                        </ValidationCheck>
                    </Col>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='State'
                            labelCss={AccountLabel}
                            value={state}
                            type='select'
                            name='state'
                            {...bindState}
                            {...resetState}
                            disabled={!isEditing}
                            errorMessage='Please select your state'
                        >
                            {renderStateOptions()}
                        </ValidationCheck>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='City'
                            labelCss={AccountLabel}
                            value={city}
                            type='text'
                            name='city'
                            {...bindCity}
                            {...resetCity}
                            disabled={!isEditing}
                            errorMessage='Invalid city'
                        />
                    </Col>
                    <Col md={6} lg={4}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Postcode'
                            labelCss={AccountLabel}
                            value={postcode}
                            type='text'
                            name='postcode'
                            {...bindpostcode}
                            {...resetpostCode}
                            disabled={!isEditing}
                            errorMessage='Invalid postcode'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={10} lg={8}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Address 1'
                            labelCss={AccountLabel}
                            value={address1}
                            type='text'
                            maxLength={50}
                            name='address1'
                            {...bindaddress1}
                            {...resetAddress1}
                            disabled={!isEditing}
                            errorMessage='Invalid address'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={10} lg={8}>
                        <ValidationCheck
                            formGroupClassName='p1-4 pr-4'
                            label='Address 2'
                            labelCss={AccountLabel}
                            value={address2}
                            type='text'
                            maxLength={50}
                            name='address2'
                            {...bindaddress2}
                            {...resetAddress2}
                            disabled={!isEditing}
                            errorMessage='Invalid address'
                        // validationDisabled
                        />
                    </Col>

                </Row>
                <Row className='mt-3'>
                    <Col xs='auto' md={6} lg={4}> </Col>
                    <Col xs='auto' md={6} lg={4}>
                        {!isEditing ? <Button type='button' onClick={() => setIsEditing(true)} css={SaveButton}>Edit Profile</Button>
                            : (
                                <div>
                                    <ErrorMessage
                                        error={error || updateUserInfoError}
                                        style={{ marginBottom: 15 }}
                                    />
                                    <Row>
                                        <Button
                                            className='mr-3'
                                            type='button'
                                            onClick={onCancelPressed}
                                            css={CancelButton}
                                        >
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            className='mr-3'
                                            type='button'
                                            onClick={onSaveChanges}
                                            text='Save Changes'
                                            loading={updateUserInfoLoading}
                                            style={{ padding: 10, width: 160 }}
                                        />
                                    </Row>
                                </div>
                            )}
                    </Col>
                </Row>
                <UpdatePassword />
            </Form>
        </div>
    );
}

const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const [invalid, setInvalid] = useState(false);

    const onReset = () => {
        setValue(initialValue);
        setInvalid(false);
    };

    return {
        value,
        setValue,
        reset: onReset,
        bind: {
            value,
            onChange: (e) => setValue(e.target.value),
            invalid,
            setInvalid,
        },
    };
};

const mapStateToProps = (state) => ({
    userInfo: Selectors.getUserInfo(state),
    updateUserInfoLoading: Selectors.getUserUpdateInfoAttempting(state),
    updateUserInfoError: Selectors.getUserUpdateInfoError(state),
    isEditing: Selectors.getUserIsEditingProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateUserInfo: (data) => dispatch(Actions.userUpdateUserInfo({ ...data })),
    setIsEditing: (isEditing) => dispatch(Actions.uiSetProfileIsEditing(isEditing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
