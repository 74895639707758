import React from 'react';

import { View, Text, StyleSheet } from 'react-native-web';
import { toast } from 'react-toastify';

interface ToastProps {
    text1: string;
    text2?: string;
}

const CustomToast = (props: ToastProps) => {
    const { text1, text2 } = props;

    return (
        <View>
            <Text style={styles.title}>
                {text1}
            </Text>
            <Text style={styles.text}>
                {text2}
            </Text>
        </View>
    );
};

const show = (text1: string, text2?: string): void => {
    toast.success(<CustomToast text1={text1} text2={text2} />);
};

const showInfo = (text1: string, text2?: string): void => {
    toast.info(<CustomToast text1={text1} text2={text2} />);
};

const showError = (text1: string, text2?: string): void => {
    toast.error(<CustomToast text1={text1} text2={text2} />);
};

const styles = StyleSheet.create({
    title: {
        fontSize: 16,
        fontWeight: '600',
        color: 'white',
    },
    text: {
        marginTop: 5,
        fontSize: 15,
        color: 'white',
    },
});

CustomToast.defaultProps = {
    text2: '',
};

export default {
    show,
    showInfo,
    showError,
};
