import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IForgetPasswordThunkResponse } from './controllers/forgetPassword';
import { IVerifyForgetPasswordThunkResponse } from './controllers/verifyForgetPassword';

import { IAuth } from './Entities';

import controllers from './controllers';

const initialState: IAuth = {
    actions: {
        login: false,
        startingUp: false,
        signUp: false,
        logout: false,
        forgetPassword: false,
        verifyForgetPassword: false,
        resetPassword: false,
    },
    credentials: {
        authToken: '',
    },
    error: {
        login: '',
        startingUp: '',
        signUp: '',
        logout: '',
        forgetPassword: '',
        verifyForgetPassword: '',
        resetPassword: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authClearLogin: (state) => {
            state.actions.login = false;
            state.error.login = '';
        },
        authSetAuthToken: (state, action: PayloadAction<string>) => {
            state.credentials.authToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(controllers.authStartup.pending, (state) => {
            state.actions.startingUp = true;
        });
        builder.addCase(controllers.authStartup.fulfilled, (state) => {
            state.actions.startingUp = false;
        });
        builder.addCase(controllers.authStartup.rejected, (state, action) => {
            state.actions.startingUp = false;

            if (action.payload) state.error.startingUp = action.payload;
        });
        builder.addCase(controllers.authSignup.pending, (state) => {
            state.actions.signUp = true;
            state.error.signUp = '';
        });
        builder.addCase(controllers.authSignup.fulfilled, (state) => {
            state.actions.signUp = false;
            state.error.signUp = '';
        });
        builder.addCase(controllers.authSignup.rejected, (state, action) => {
            state.actions.signUp = false;
            if (action.payload) {
                state.error.signUp = action.payload;
            }
        });
        builder.addCase(controllers.authLogin.pending, (state) => {
            state.actions.login = true;
            state.error.login = '';
        });
        builder.addCase(controllers.authLogin.fulfilled, (state, action) => {
            state.actions.login = false;
            if (action.payload) state.credentials.authToken = action.payload.authToken;
        });
        builder.addCase(controllers.authLogin.rejected, (state, action) => {
            state.actions.login = false;
            if (action.payload) state.error.login = action.payload;
        });
        builder.addCase(controllers.authLogout.pending, (state) => {
            state.actions.logout = true;
            state.error.logout = '';
        });
        builder.addCase(controllers.authLogout.fulfilled, () => initialState);
        builder.addCase(controllers.authLogout.rejected, (state, action) => {
            state.actions.logout = false;

            if (action.payload) state.error.logout = action.payload;
        });
        builder.addCase(controllers.authForgetPassword.pending, (state) => {
            state.actions.forgetPassword = true;
            state.error.forgetPassword = '';
        });
        builder.addCase(controllers.authForgetPassword.fulfilled, (state, action: PayloadAction<IForgetPasswordThunkResponse | void>) => {
            state.actions.forgetPassword = false;
            if (action.payload) {
                if (action.payload.errorMessage) {
                    state.error.forgetPassword = action.payload.errorMessage;
                }
            }
        });
        builder.addCase(controllers.authForgetPassword.rejected, (state, action) => {
            state.actions.forgetPassword = false;
            if (action.payload) {
                state.error.forgetPassword = action.payload;
            }
        });
        builder.addCase(controllers.authVerifyForgetPassword.pending, (state) => {
            state.actions.verifyForgetPassword = true;
            state.error.verifyForgetPassword = '';
        });
        builder.addCase(controllers.authVerifyForgetPassword.fulfilled, (state, action: PayloadAction<IVerifyForgetPasswordThunkResponse>) => {
            state.actions.verifyForgetPassword = false;
            if (action.payload.errorMessage) {
                state.error.verifyForgetPassword = action.payload.errorMessage;
            }
        });
        builder.addCase(controllers.authVerifyForgetPassword.rejected, (state, action) => {
            state.actions.verifyForgetPassword = false;
            if (action.payload) {
                state.error.verifyForgetPassword = action.payload;
            }
        });
        builder.addCase(controllers.authResetPassword.pending, (state) => {
            state.actions.resetPassword = true;
            state.error.resetPassword = '';
        });
        builder.addCase(controllers.authResetPassword.fulfilled, (state) => {
            state.actions.resetPassword = false;
        });
        builder.addCase(controllers.authResetPassword.rejected, (state, action) => {
            state.actions.resetPassword = false;
            if (action.payload) {
                state.error.resetPassword = action.payload;
            }
        });
    },
});

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
