import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface PickerProps {
    children: JSX.Element,
    onChange(date: Date): void;
    value: Date;
    maxDate: Date;
    placeholder: string;
}

const Picker = (props: PickerProps): JSX.Element => {
    const { children, onChange, value, maxDate, placeholder } = props;

    return (
        <DatePicker
            customInput={children}
            selected={value}
            onChange={onChange}
            dateFormat='dd/MM/yy'
            maxDate={maxDate}
            placeholderText={placeholder}
            fixedHeight
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
        />
    );
};

export default Picker;
