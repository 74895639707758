import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';
import colors from 'Themes/colors';

export const headerContainer = css({
    '@media(min-width: 576px)': {
        minHeight: '60px',
    },

    '@media(min-width: 992px)': {
        minHeight: '60px',
    },

    '@media(min-width: 1200px)': {
        minHeight: '100px',
    },
});

export const menuContainer = css({
    padding: '0px 10px',
    backgroundColor: colors.background,

    '@media(min-width: 576px)': {
        padding: '0px 0px',
    },

    '@media(min-width: 992px)': {
        padding: '0px 0px',
    },

    '@media(min-width: 1200px)': {
        padding: '0px 90px',
    },
});

export const headerCollapseContainer = css({
    backgroundColor: colors.white,
});

export const headerCollapseSticky = css({
    top: 60,
    position: 'fixed',
    boxShadow: '0px 10px 8px -10px #888888',
    zIndex: 99,
    width: '100%',
});

export const sticky = css({
    top: 0,
    position: 'fixed',
    boxShadow: '0px 10px 8px -10px #888888',
    zIndex: 99,
    width: '100%',
});

export const headerLogo = css({
    padding: '30px 0px',
    width: 100,
    height: 100,

    '@media(min-width: 992px)': {
        width: '200px',
    },
});

export const headerColumnContainer = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .col': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',

    },
});

export const headerMenuHeader = css({
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'space-around',
    display: 'flex',
});

export const headerSearchHeader = css({
    marginTop: 10,
});

export const headerText = css({
    // font: '18px Archivo-Bold, sans-serif',
    fontSize: 14,
    fontFamily: Fonts.primaryBold,
    color: colors.black,
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',

    '@media(min-width: 720px)': {
        fontSize: 16,
    },

    '@media(min-width: 900px)': {
        fontSize: 18,
    },
});

export const headerMenuItem = css({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',

    '&:before, &:after': {
        content: '""',
        position: 'absolute',
        bottom: '-10px',
        left: 0,
        right: 0,
        height: 4,
        backgroundColor: colors.primary,
    },
    '&:before': {
        opacity: 0,
        transform: 'translateY(-10px)',
        transition: 'transform 0s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0s',
    },
    '&:after': {
        opacity: 0,
        transform: 'translateY(5px)',
        transition: 'transform 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0.2s',
    },
    '&:hover, &:focus': {
        '&:before, &:after': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
        '&:before': {
            transition: 'transform 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0.2s',
        },
        '&:after': {
            transition: 'transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0s 0.2s',
        },
    },
});

export const headerTextHighlight = css(headerText, {
    color: colors.primary,
});

export const headerIconContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& .col': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});

export const headerIcon = css({
    width: '24px',
    cursor: 'pointer',
});

export const hoverCatalogContainer = css({
    padding: '15px 50px',
    backgroundColor: '#E9DCCD',
    position: 'absolute',
    zIndex: '1',

    '@media(min-width: 900px)': {
        padding: '15px 100px',
    },
});

export const hoverColTitle = css({
    font: '16px Archivo-Medium, sans-serif',
    color: '#231F20',
});

export const hoverColEntry = css({
    font: '12px Archivo-Regular, sans-serif',
    color: '#5C5C5C',
    marginTop: '1.2em',
    cursor: 'pointer',
});

export const hoverTriangle = css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
});

export const headerCollapse = css({
    font: '16px Archivo-Bold, sans-serif',
    color: colors.black,
    padding: '12px 20px',
    borderTop: `1px solid ${colors.black}`,
    borderBottom: `1px solid ${colors.black}`,
});

export const headerCollapseOpen = css({
    font: '16px Archivo-Bold, sans-serif',
    color: colors.black,
    padding: '12px 20px',
    borderTop: `1px solid ${colors.black}`,
});

export const collapseEntry = css({
    font: '16px Archivo-Bold, sans-serif',
    color: colors.black,
    padding: '0px 20px 12px',
    width: 'fit-content',
});

export const collapseEntry2 = css(collapseEntry, {
    marginLeft: '30px',
});
