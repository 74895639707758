import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
    width?: number;
    row?: number;
    height?: number;
}

const PageSkeleton = ({ width = undefined, row = 5, height = 30 }: Props): JSX.Element => {
    return (
        <div style={{ padding: 15 }}>
            {[...Array(row)].map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton key={i} height={height} width={width} />
            ))}
        </div>
    );
};

PageSkeleton.defaultProps = {
    width: null,
    row: 5,
    height: 30,
};

export default PageSkeleton;
