import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TableSkeleton = () => {
    return (
        <div style={{ padding: 15 }}>
            <Skeleton height={55} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
        </div>
    );
};

export default TableSkeleton;
