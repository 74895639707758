import React from 'react';
import { View } from 'react-native';
import { Switch, Route, Redirect } from 'react-router-dom';

import HomeScreen from 'Containers/Home';
import AboutUsScreen from 'Containers/Home/AboutUs';
import TermsAndConditionsScreen from 'Containers/Home/TermsAndCondition';

import UserScreen from 'Containers/User';
import Wishlist from 'Containers/User/Wishlist';
import OrderConfirmation from 'Containers/User/OrderConfirmation';
import OrderFailed from 'Containers/User/OrderFailed';
import OrderProcessing from 'Containers/User/OrderProcessing';

import ResetPassword from 'Containers/Auth/ResetPassword';

import ProductListingScreen from 'Containers/Products/ProductListing';
import ProductDetailsScreen from 'Containers/Products/ProductDetails';

import CartScreen from 'Containers/Cart/Cart';
import ShippingScreen from 'Containers/Cart/ShippingAddress';
import OrderReview from 'Containers/Cart/OrderReview';
import PaymentMethodScreen from 'Containers/Cart/Payment';

import ErrorScreen from 'Containers/Error';

import UnsubscribeScreen from 'Containers/Unsubscribed';
import MaintenanceScreen from 'Containers/Maintenance';
import SubmitCyberSourceInfoScreen from 'Containers/Cart/Components/PaymentWebView';

import PrivateRoute from './PrivateRoute';

import Header from './Components/Header';
import Footer from './Components/Footer';

const PublicBucket = () => {
    return (
        <View style={{ justifyContent: 'space-between', height: '100vh' }}>
            <Header />

            <Switch>
                <Route exact path='/about-us' component={AboutUsScreen} />
                <Route exact path='/termsandconditions' component={TermsAndConditionsScreen} />

                <Route exact path='/categories/' component={ProductListingScreen} />
                <Route path='/search/:query' component={ProductListingScreen} />
                <Route exact path='/categories/:categoryId' component={ProductListingScreen} />
                <Route exact path='/product/:productId' component={ProductDetailsScreen} />

                <PrivateRoute exact path='/user' component={UserScreen} />
                <Route exact path='/wishlist' component={Wishlist} />

                <Route exact path='/orderconfirmation/:orderId' component={OrderConfirmation} />
                <Route exact path='/orderfailed' component={OrderFailed} />
                <Route exact path='/orderprocessing' component={OrderProcessing} />

                <Route exact path='/cart' component={CartScreen} />
                <Route exact path='/submitCyberSourceInfo' component={SubmitCyberSourceInfoScreen} />
                <Route exact path='/shipping' component={ShippingScreen} />
                <Route exact path='/order' component={OrderReview} />
                <Route exact path='/payment' component={PaymentMethodScreen} />

                <Route path='/forget-password/:hash' component={ResetPassword} />

                <Route exact path='/' component={HomeScreen} />

                <Route path='*'>
                    <Redirect to='/' />
                </Route>
            </Switch>

            <Footer />
        </View>
    );
};

// const PrivateBucket = () => {
//     return (
//         <View>
//             <Switch>

//             </Switch>
//         </View>
//     );
// };

const Routes = () => {
    return (
        <View>
            <Switch>
                <Route exact path='/error' component={ErrorScreen} />

                <Route exact path='/unsubscribed' component={UnsubscribeScreen} />

                <Route exact path='/maintenance' component={MaintenanceScreen} />

                <Route component={PublicBucket} />

                <Route path='*'>
                    <Redirect to='/' />
                </Route>
            </Switch>
        </View>
    );
};

export default Routes;
