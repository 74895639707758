import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import homeGetHello from 'Redux/slices/home/controllers/getHello';
import Selectors from 'Redux/Selectors';

import Utils from 'Lib/Utils';
import { RootState } from 'Redux/store';
import { IGoldPrice } from 'Redux/slices/home/Entities';

interface Props {
    goldPrice: IGoldPrice;
    getGoldPrice(): void;
}

const GoldPriceChangeAlert = (props: Props): JSX.Element | null => {
    const [visible, setVisible] = useState(false);

    const { goldPrice, getGoldPrice } = props;
    const prevGoldPrice = Utils.React.usePrevious(goldPrice);

    useEffect(() => {
        getGoldPrice();
    }, []);

    useEffect(() => {
        if (prevGoldPrice && prevGoldPrice !== goldPrice) setVisible(true);
    }, [goldPrice]);

    if (!visible) return null;

    return (
        <div style={{ marginTop: 10 }}>
            <Alert color='warning' isOpen={visible} toggle={() => setVisible(false)}>
                Some prices have changed! Please check the new prices of your items.
            </Alert>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    goldPrice: Selectors.getHomeGoldPrice(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getGoldPrice: () => dispatch(homeGetHello()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoldPriceChangeAlert);
