/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { Row, Col } from 'reactstrap';

interface AddressProps {
    name: string;
    address1: string;
    address2: string;
    email?: string;
    phoneNumber?: string;
    billingAddress: string;
    paymentMethod?: string;
}

const AddressComponent = (props: AddressProps): JSX.Element | null => {
    const {
        name, address1, address2, email, phoneNumber, billingAddress, paymentMethod,
    } = props;

    const renderPaymentMethod = () => {
        if (!paymentMethod) return null;

        return (
            <Row>
                <Col xs='3'>
                    <div className='pt-2'>Payment Method</div>
                </Col>
                <Col xs='9'>
                    <div>
                        <div className='pt-2'>{paymentMethod}</div>
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <div className='mt-3' css={styles.addressFonts}>
            <Row>
                <Col xs='3'>
                    <div className='pt-2'>Shipping Address</div>
                </Col>
                <Col xs='9'>
                    <div>
                        <div className='pt-2'>{name}</div>
                        <div className='pt-2'>{address1}</div>
                        {address2 && (<div className='pt-2'>{address2}</div>)}
                        {email && (<div className='pt-2'>{email}</div>)}
                        {phoneNumber && (<div className='pt-2'>{phoneNumber}</div>)}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs='3'>
                    <div className='pt-2'>Billing Address</div>
                </Col>
                <Col xs='9'>
                    <div className='pt-2'>{billingAddress}</div>
                </Col>
            </Row>
            {renderPaymentMethod()}
        </div>
    );
};

const styles = {
    addressFonts: css({
        font: '13px Archivo-Regular, sans-serif',
        color: 'black',
        '@media(min-width: 600px)': {
            font: '15px Archivo-Regular, sans-serif',
        },
        '@media(min-width: 900px)': {
            font: '20px Archivo-Regular, sans-serif',
        },
    }),
};

AddressComponent.defaultProps = {
    email: '',
    phoneNumber: '',
    paymentMethod: '',
};

export default AddressComponent;
