import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProduct, UseCaseResponseStatus } from '@ddot/AddToCartCommon';

import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';
import { ErrorTypes } from '.';

interface GetWishlistResponse {
    wishlist: IProduct[];
}

export default createAsyncThunk<
    GetWishlistResponse,
    void,
    AsyncThunkAPI
>('user/getWishlistLocalWeb', async (params, thunkAPI) => {
    const state = thunkAPI.getState();

    const userId = Selectors.getUserId(state);
    const products = Selectors.getProductsList(state);

    const response = await thunkAPI.extra.useCases.user.getWishlistLocalWeb.execute({ userId, products });

    if (response.status === UseCaseResponseStatus.Success) {
        return response.data;
    }
    if (response.data.type === ErrorTypes.GetWishlistLocalWebError.NoWishlistFound) {
        return { wishlist: [] };
    } if (response.data.type === ErrorTypes.GetWishlistLocalWebError.SystemError) {
        return thunkAPI.rejectWithValue(response.data.title || 'Something went wrong, please try again later');
    }

    return thunkAPI.rejectWithValue('Something went wrong. Please try again.');
});
