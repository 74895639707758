import { IProduct, IProductCategory, IOrder } from '@ddot/AddToCartCommon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStockCheckResponse } from './controllers/stockCheckAndLock';
import { IGetOrderThunkResponse } from './controllers/getOrderDetails';

import controllers from './controllers';

import { IUi } from './Entities';

interface ProductAndCategory {
    products: IProduct[];
    category: IProductCategory | null;
}

const initialState: IUi = {
    actions: {
        stockCheck: false,
        searchProducts: false,
        getOrderDetails: false,
    },
    auth: {
        signUpModalVisible: false,
        isEmailSent: false,
        userId: '',
        email: '',
        uuid: '',
    },
    cart: {
        stockUnavailableVisible: false,
    },
    stockCheck: {
        modalVisible: false,
        itemsOutOfStock: [],
    },
    productList: {
        sort: undefined,
        currentCategory: null,
        currentSearchQuery: '',
        successSearchQuery: '',
        products: [],
    },
    filteredProducts: [],
    orderDetails: {
        id: '',
        products: [],
        customerId: '',
        orderDate: '',
        totalPrice: -1,
        currency: '',
        deliveryInfo: {
            address: {
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                state: '',
                city: '',
                postCode: '',
                country: '',
            },
        },
    },
    user: {
        isEditingProfile: false,
    },
    error: {
        stockCheck: '',
        searchProducts: '',
        getOrderDetails: '',
    },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        uiResetProductList: (state) => {
            state.productList = initialState.productList;
        },
        uiResetStockCheck: (state) => {
            state.stockCheck.modalVisible = false;
            state.stockCheck.itemsOutOfStock = [];
        },
        uiSetProductsAndCategory: (state, action: PayloadAction<ProductAndCategory>) => {
            state.productList.currentCategory = action.payload.category;
            state.productList.products = action.payload.products;
            // when category is switched, we make sure search becomes empty
            state.productList.currentSearchQuery = '';
            state.productList.successSearchQuery = '';
        },
        uiSetSearchQuery: (state, action: PayloadAction<string>) => {
            state.productList.currentSearchQuery = action.payload;
        },
        uiSetCartStockUnavailable: (state, action: PayloadAction<boolean>) => {
            state.cart.stockUnavailableVisible = action.payload;
        },
        uiSetOrder: (state, action: PayloadAction<IOrder>) => {
            state.orderDetails = action.payload;
        },
        uiSetOrderId: (state, action: PayloadAction<string>) => {
            state.orderDetails.id = action.payload;
        },
        uiClearOrder: (state) => {
            state.orderDetails = initialState.orderDetails;
        },
        uiClearStockCheckUnavailableProducts: (state) => {
            state.stockCheck.itemsOutOfStock = [];
        },
        uiSetSignUpModalVisible: (state, action: PayloadAction<boolean>) => {
            state.auth.signUpModalVisible = action.payload;
        },
        uiSetProfileIsEditing: (state, action: PayloadAction<boolean>) => {
            state.user.isEditingProfile = action.payload;
        },
        uiSetIsEmailSent: (state, action: PayloadAction<boolean>) => {
            state.auth.isEmailSent = action.payload;
        },
        uiSetForgetPasswordUserId: (state, action: PayloadAction<string>) => {
            state.auth.userId = action.payload;
        },
        uiSetForgetPasswordEmail: (state, action: PayloadAction<string>) => {
            state.auth.email = action.payload;
        },
        uiSetForgetPasswordUuid: (state, action: PayloadAction<string>) => {
            state.auth.uuid = action.payload;
        },
        uiSetFilteredProducts: (state, action: PayloadAction<IProduct[]>) => {
            state.filteredProducts = action.payload;
        },
        uiClearFilteredProducts: (state) => {
            state.filteredProducts = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(controllers.uiSortProducts.fulfilled, (state, action) => {
            state.productList.sort = action.payload.sort;
            state.productList.products = action.payload.products;
        });
        builder.addCase(controllers.uiStockCheck.pending, (state) => {
            state.actions.stockCheck = true;
            state.error.stockCheck = '';
            state.stockCheck.itemsOutOfStock = [];
        });
        builder.addCase(controllers.uiStockCheck.fulfilled, (state, action: PayloadAction<IStockCheckResponse>) => {
            state.actions.stockCheck = false;
            if (action.payload.stockUnavailable.length) {
                state.stockCheck.itemsOutOfStock = action.payload.stockUnavailable;
            }
        });
        builder.addCase(controllers.uiStockCheckAndLock.pending, (state) => {
            state.actions.stockCheck = true;
            state.error.stockCheck = '';
        });
        builder.addCase(controllers.uiStockCheckAndLock.fulfilled, (state, action: PayloadAction<IStockCheckResponse>) => {
            state.actions.stockCheck = false;
            if (action.payload.stockUnavailable.length) {
                state.stockCheck.itemsOutOfStock = action.payload.stockUnavailable;
            }
        });
        builder.addCase(controllers.uiStockCheckAndLock.rejected, (state, action) => {
            state.actions.stockCheck = false;
            if (action.payload) {
                state.error.stockCheck = action.payload;
            }
        });
        builder.addCase(controllers.uiSearchProducts.pending, (state) => {
            state.actions.searchProducts = true;
            state.error.searchProducts = '';
        });
        builder.addCase(controllers.uiSearchProducts.fulfilled, (state, action) => {
            state.actions.searchProducts = false;
            if (action.payload) {
                state.productList.successSearchQuery = action.payload.query;
                state.productList.products = action.payload.products;
            }
        });
        builder.addCase(controllers.uiSearchProducts.rejected, (state, action) => {
            state.actions.searchProducts = false;
            if (action.payload) {
                state.error.searchProducts = action.payload;
            }
        });
        builder.addCase(controllers.uiGetOrderDetails.pending, (state) => {
            state.actions.getOrderDetails = true;
            state.error.getOrderDetails = '';
        });
        builder.addCase(controllers.uiGetOrderDetails.fulfilled, (state, action: PayloadAction<IGetOrderThunkResponse>) => {
            state.actions.getOrderDetails = false;
            if (action.payload) state.orderDetails = action.payload.order;
        });
        builder.addCase(controllers.uiGetOrderDetails.rejected, (state, action) => {
            state.actions.getOrderDetails = false;
            if (action.payload) state.error.getOrderDetails = action.payload;
        });
    },
});

export default {
    actions: uiSlice.actions,
    reducers: uiSlice.reducer,
};
