/** @jsxImportSource @emotion/react */
import OrderHistorySubItem from 'Containers/User/Components/OrderHistorySubItem';
import Utils from 'Lib/Utils';
import React from 'react';
import { Col, Row } from 'reactstrap';
import colors from 'Themes/colors';
import { orderTotal } from '../Styles/CartStyles';
import {
    orderSummaryContainer,
    orderSummaryRows,
    orderSummaryText,
    orderSummaryTitle,
    orderSummaryColumnRight,
} from '../Styles/ShippingAddressStyles';

const OrderSummary = ({ data, products }) => {
    const {
        shippingFee = '',
        subTotal,
        currency,
        promoCode,
        discountPrice,
        totalCost,
    } = data;

    return (
        <div css={orderSummaryContainer}>
            <div css={orderSummaryTitle}>Order Summary</div>
            <hr
                css={{
                    width: '100%',
                    border: '1px solid #BF7247',
                }}
            />
            <Row css={orderSummaryRows}>
                {products.map((product) => (
                    <OrderHistorySubItem product={product} key={product.selectedVariant.sku} />
                ))}
            </Row>
            <hr
                css={{
                    width: '100%',
                    border: '1px solid #BF7247',
                }}
            />
            <Row css={orderSummaryRows}>
                <Col xs={5} css={orderSummaryText}>
                    Subtotal
                </Col>
                <Col xs={7} css={[orderSummaryText, orderSummaryColumnRight]}>
                    {`${Utils.Formatter.currencyFormatter(subTotal)} ${currency}`}
                </Col>
            </Row>
            {/* <Row css={orderSummaryRows}>
                <Col xs={8} css={orderSummaryText}>
                    Total Labor Cost
                </Col>
                <Col xs={4} css={[orderSummaryText, orderSummaryColumnRight]}>
                    {`${Utils.Formatter.currencyFormatter(laborCost)} ${currency}`}
                </Col>
            </Row> */}
            <Row css={orderSummaryRows}>
                <Col xs={8} css={orderSummaryText}>
                    Shipping Cost
                </Col>
                <Col xs={4} css={[orderSummaryText, orderSummaryColumnRight]}>
                    {`${Utils.Formatter.currencyFormatter(shippingFee)} ${currency}`}
                </Col>
            </Row>
            <Row css={orderSummaryRows}>
                <Col xs={12} className='d-sm-none' css={orderSummaryText}>Discount</Col>
                <Col xs={8} className='d-sm-none pr-0 mb-2' css={orderSummaryText}>
                    {promoCode && (
                        <span
                            css={{ color: colors.gray }}
                        >
                            {` (Promo code :${promoCode})`}
                        </span>
                    )}
                </Col>
                <Col sm={8} className='d-none d-sm-flex' css={orderSummaryText}>
                    {promoCode && 'Discount'}
                    {promoCode && (
                        <span
                            css={{ color: colors.gray }}
                        >
                            {` (Promo code :${promoCode})`}
                        </span>
                    )}
                </Col>
                <Col xs={4} css={[orderSummaryText, orderSummaryColumnRight]}>
                    {promoCode && `-${Utils.Formatter.currencyFormatter(discountPrice)} ${currency}`}
                </Col>
            </Row>
            <Row css={orderSummaryRows}>
                <Col xs={5} css={orderTotal}>
                    Total Cost
                </Col>
                <Col xs={7} css={[orderTotal, orderSummaryColumnRight]}>
                    {`${Utils.Formatter.currencyFormatter(totalCost)} ${currency}`}
                </Col>
            </Row>
        </div>
    );
};

export default OrderSummary;
