import { UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';
import Selectors from 'Redux/Selectors';

import Toast from 'Lib/Toast';

import Utils from 'Lib/Utils';

import NavActions from 'Services/Navigation/Actions';

import { ErrorTypes } from '.';

export interface IResetPasswordThunkParams {
    password: string;
}

export default createAsyncThunk<
    null,
    IResetPasswordThunkParams,
    AsyncThunkAPI
>('auth/resetPassword', async (params, thunkAPI) => {
    const { password } = params;

    const state = thunkAPI.getState();
    const email = Selectors.getUiAuthEmail(state);
    const uuid = Selectors.getUiAuthUuid(state);

    if (!password) {
        return thunkAPI.rejectWithValue('Please enter password.');
    }

    const passwordValidateError = Utils.Validator.validatePassword(password);

    if (passwordValidateError) return thunkAPI.rejectWithValue(passwordValidateError);

    const response = await thunkAPI.extra.useCases.auth.resetPassword.execute({
        email,
        uuid,
        newPassword: password,
    });

    if (response.status === UseCaseResponseStatus.Success) {
        Toast.show('Password updated');
        NavActions.navToHome();
        return null;
    }

    const { type, title } = response.data;
    const { ResetPasswordError } = ErrorTypes;

    if (type === ResetPasswordError.NoUserFound) {
        return thunkAPI.rejectWithValue('Account is invalid. Please refresh the page and login again.');
    }

    return thunkAPI.rejectWithValue(title || 'Something went wrong. Please try again.');
});
