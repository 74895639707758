import { ICartProduct, IOrder, IProduct, IProductCategory, IProductCollection, IProductWidget, IShippingAddress, IUser, IFpxBankList } from '@ddot/AddToCartCommon';
import { IProductDetails } from './slices/products/Entities';
import { IGoldPrice } from './slices/home/Entities';
import { ICyberSourceInfo } from './slices/cart/Entities';

import auth from './slices/auth/Selectors';
import home from './slices/home/Selectors';
import products from './slices/products/Selectors';
import cart from './slices/cart/Selectors';
import user from './slices/user/Selectors';
import ui from './slices/ui/Selectors';

import { RootState } from './store';

const getAuthStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);

const getAuthLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const getAuthLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const getAuthSignUpAttempting = (state: RootState): boolean => auth.getSignUpAttempting(state.auth);
const getAuthSignUpError = (state: RootState): string => auth.getSignUpError(state.auth);

const getAuthLogoutAttempting = (state: RootState): boolean => auth.getLogoutAttempting(state.auth);
const getAuthLogoutError = (state: RootState): string => auth.getLogoutError(state.auth);

const getAuthForgetPasswordAttempting = (state: RootState): boolean => auth.getForgetPasswordAttempting(state.auth);
const getAuthForgetPasswordError = (state: RootState): string => auth.getForgetPasswordError(state.auth);

const getAuthVerifyForgetPasswordAttempting = (state: RootState): boolean => auth.getVerifyPasswordAttempting(state.auth);
const getAuthVerifyForgetPasswordError = (state: RootState): string => auth.getVerifyPasswordError(state.auth);

const getAuthResetPasswordAttempting = (state: RootState): boolean => auth.getResetPasswordAttempting(state.auth);
const getAuthResetPasswordError = (state: RootState): string => auth.getResetPasswordError(state.auth);

const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);

const getHomeGoldPriceAttempting = (state: RootState): boolean => home.getGoldPriceAttempting(state.home);
const getHomeGoldPrice = (state: RootState): IGoldPrice => home.getGoldPrice(state.home);
const getHomeIsMaintenance = (state: RootState): boolean => home.getIsMaintenance(state.home);

const getHomeFeaturedProductsAttempting = (state: RootState): boolean => home.getFeaturedProductsAttempting(state.home);
const getHomeFeaturedProductsError = (state: RootState): string => home.getFeaturedProductsError(state.home);
const getHomeFeaturedProducts = (state: RootState): IProduct[] => home.getFeaturedProducts(state.home);

const getHomeProductWidgetsAttempting = (state: RootState): boolean => home.getProductWidgetAttempting(state.home);
const getHomeProductWidgets = (state: RootState): IProductWidget[] | undefined => home.getProductWidget(state.home);

const getProductsListAttempting = (state: RootState): boolean => products.getProductsAttempting(state.products);
const getProductsListError = (state: RootState): string => products.getProductsError(state.products);
const getProductsList = (state: RootState): IProduct[] => products.getProductsList(state.products);

const getProductDetailsAttempting = (state: RootState): boolean => products.getDetailsAttempting(state.products);
const getProductDetailsError = (state: RootState): string => products.getDetailsError(state.products);
const getProductDetails = (state: RootState): IProductDetails | null => products.getDetails(state.products);

const getProductsCollectionsAttempting = (state: RootState): boolean => products.getCollectionsAttempting(state.products);
const getProductsCollectionsError = (state: RootState): string => products.getCollectionsError(state.products);
const getProductsCollections = (state: RootState): IProductCollection[] => products.getCollections(state.products);

const getProductsCategoriesAttempting = (state: RootState): boolean => products.getCategoriesAttempting(state.products);
const getProductsCategoriesError = (state: RootState): string => products.getCategoriesError(state.products);
const getProductsCategories = (state: RootState): IProductCategory[] => products.getCategories(state.products);

const getCartCartAttempting = (state: RootState): boolean => cart.getCartAttempting(state.cart);
const getCartCartError = (state: RootState): string => cart.getCartError(state.cart);
const getCartId = (state: RootState): string | null => cart.getCartId(state.cart);
const getCartItems = (state: RootState): ICartProduct[] => cart.getCartItems(state.cart);

const getCartAddToCartAttempting = (state: RootState): boolean => cart.getAddToCartAttempting(state.cart);
const getCartAddToCartError = (state: RootState): string => cart.getAddToCartError(state.cart);

const getCartShippingFee = (state: RootState): number => cart.getShippingFee(state.cart);
const getCartShippingAddress = (state: RootState): IShippingAddress | undefined => cart.getShippingAddress(state.cart);
const getCartTotalItemPrice = (state: RootState): number => cart.getTotalItemPrice(state.cart);
const getCartCurrency = (state: RootState): string => cart.getCurrency(state.cart);
const getCartPromoCode = (state: RootState): string => cart.getPromoCode(state.cart);
const getCartCustomerRemarks = (state: RootState): string => cart.getRemarks(state.cart);

const getCartUpdateQuantityAttempting = (state: RootState): boolean => cart.getUpdateQuantityAttempting(state.cart);
const getCartUpdateQuantityError = (state: RootState): string => cart.getUpdateQuantityError(state.cart);

const getCartPlaceOrderAttempting = (state: RootState): boolean => cart.getPlaceOrderAttempting(state.cart);
const getCartPlaceOrderError = (state: RootState): string => cart.getPlaceOrderError(state.cart);

const getCartFpxBankListAttempting = (state: RootState): boolean => cart.getFpxBankListAttempting(state.cart);
const getCartFpxBankListError = (state: RootState): string => cart.getFpxBankListError(state.cart);
const getCartFpxBankList = (state: RootState): IFpxBankList[] => cart.getFpxBankList(state.cart);

const getCartCyberSourceInfoAttempting = (state: RootState): boolean => cart.getCyberSourceInfoAttempting(state.cart);
const getCartCyberSourceInfoError = (state: RootState): string => cart.getCyberSourceInfoError(state.cart);
const getCartCyberSourceInfo = (state: RootState): ICyberSourceInfo => cart.getCyberSourceInfo(state.cart);

const getUserInfoAttempting = (state: RootState): boolean => user.getUserInfoAttempting(state.user);
const getUserId = (state: RootState): string => user.getUserId(state.user);
const getUserInfo = (state: RootState): IUser | undefined => user.getUserInfo(state.user);
const getUserEmail = (state: RootState): string | undefined => user.getUserEmail(state.user);

const getUserUpdateInfoAttempting = (state: RootState): boolean => user.getUpdateInfoAttempting(state.user);
const getUserUpdateInfoError = (state: RootState): string => user.getUpdateInfoError(state.user);

const getUserWishlistAttempting = (state: RootState): boolean => user.getWishlistAttempting(state.user);
const getUserWishlistError = (state: RootState): string => user.getWishlistError(state.user);
const getUserWishlist = (state: RootState): IProduct[] => user.getWishlist(state.user);

const getUserRemoveFromWishlistAttempting = (state: RootState): boolean => user.getRemoveFromWishlistAttempting(state.user);
const getUserRemoveFromWishlistError = (state: RootState): string => user.getRemoveFromWishlistError(state.user);

const getUserUpdatePasswordAttempting = (state: RootState): boolean => user.getUpdatePasswordAttempting(state.user);
const getUserUpdatePasswordError = (state: RootState): string => user.getUpdatePasswordError(state.user);

const getUserOrderHistoryAttempting = (state: RootState): boolean => user.getOrderHistoryAttempting(state.user);
const getUserOrderHistoryError = (state: RootState): string => user.getOrderHistoryError(state.user);
const getUserOrderHistory = (state: RootState): IOrder[] => user.getOrderHistory(state.user);

const getUserOrderHistoryDetailsAttempting = (state: RootState): boolean => user.getOrderHistoryDetailsAttempting(state.user);
const getUserOrderHistoryDetailsError = (state: RootState): string => user.getOrderHistoryDetailsError(state.user);

const getUiAuthSignUpModalVisible = (state: RootState): boolean => ui.getAuthSignUpModalVisible(state.ui);
const getUiAuthIsEmailSent = (state: RootState): boolean => ui.getAuthIsEmailSent(state.ui);
const getUiAuthUserId = (state: RootState): string => ui.getAuthUserId(state.ui);

const getUiAuthEmail = (state: RootState): string => ui.getAuthEmail(state.ui);
const getUiAuthUuid = (state: RootState): string => ui.getAuthUuid(state.ui);

const getUiProductList = (state: RootState): IProduct[] => ui.getProductList(state.ui);
const getUiProductCurrentCategory = (state: RootState): IProductCategory | null => ui.getProductCurrentCategory(state.ui);

const getUiSearchQuery = (state: RootState): string => ui.getSearchQuery(state.ui);
const getUiSuccessSearchQuery = (state: RootState): string => ui.getSuccessfulSearchQuery(state.ui);

const getUiSearchProductsAttempting = (state: RootState): boolean => ui.getSearchProductsAttempting(state.ui);
const getUiSearchProductsError = (state: RootState): string | undefined => ui.getSearchProductsError(state.ui);

const getUiCartStockUnavailableVisible = (state: RootState): boolean => ui.getStockUnavailableVisible(state.ui);

const getUiStockCheckAttempting = (state: RootState): boolean => ui.getStockCheckAttempting(state.ui);
const getUiStockCheckUnavailableProduct = (state: RootState): ICartProduct[] => ui.getStockCheckUnavailableProduct(state.ui);
const getUiStockCheckError = (state: RootState): string => ui.getStockCheckError(state.ui);

const getUiOrderDetails = (state: RootState): IOrder => ui.getOrderDetails(state.ui);

const getUiFilteredProducts = (state: RootState): IProduct[] => ui.getFilteredProducts(state.ui);

const getUiOrderId = (state: RootState): string => ui.getOrderId(state.ui);

const getUiGetOrderDetailsAttempting = (state: RootState): boolean => ui.getGetOrderDetailsAttempting(state.ui);
const getUiGetOrderDetailsError = (state: RootState): string => ui.getGetOrderDetailsError(state.ui);

const getUserIsEditingProfile = (state: RootState): boolean => ui.getIsEditingProfile(state.ui);

export default {
    getAuthStartupAttempting,

    getAuthLoginAttempting,
    getAuthLoginError,

    getAuthSignUpAttempting,
    getAuthSignUpError,

    getAuthLogoutAttempting,
    getAuthLogoutError,

    getAuthForgetPasswordAttempting,
    getAuthForgetPasswordError,

    getAuthVerifyForgetPasswordAttempting,
    getAuthVerifyForgetPasswordError,

    getAuthResetPasswordAttempting,
    getAuthResetPasswordError,

    getAuthAuthToken,

    getHomeGoldPriceAttempting,
    getHomeGoldPrice,

    getHomeIsMaintenance,

    getHomeFeaturedProductsAttempting,
    getHomeFeaturedProductsError,
    getHomeFeaturedProducts,

    getHomeProductWidgetsAttempting,
    getHomeProductWidgets,

    getProductsListAttempting,
    getProductsListError,
    getProductsList,

    getProductDetailsAttempting,
    getProductDetailsError,
    getProductDetails,

    getProductsCollectionsAttempting,
    getProductsCollectionsError,
    getProductsCollections,

    getProductsCategoriesAttempting,
    getProductsCategoriesError,
    getProductsCategories,

    getCartCartAttempting,
    getCartCartError,
    getCartId,
    getCartItems,

    getCartAddToCartAttempting,
    getCartAddToCartError,

    getCartShippingFee,
    getCartShippingAddress,
    getCartTotalItemPrice,
    getCartCurrency,
    getCartPromoCode,
    getCartCustomerRemarks,

    getCartUpdateQuantityAttempting,
    getCartUpdateQuantityError,

    getCartPlaceOrderAttempting,
    getCartPlaceOrderError,

    getCartFpxBankListAttempting,
    getCartFpxBankListError,
    getCartFpxBankList,

    getCartCyberSourceInfoAttempting,
    getCartCyberSourceInfoError,
    getCartCyberSourceInfo,

    getUserInfoAttempting,
    getUserId,
    getUserInfo,
    getUserEmail,

    getUserUpdateInfoAttempting,
    getUserUpdateInfoError,

    getUserWishlistAttempting,
    getUserWishlistError,
    getUserWishlist,

    getUserRemoveFromWishlistAttempting,
    getUserRemoveFromWishlistError,

    getUserUpdatePasswordAttempting,
    getUserUpdatePasswordError,

    getUserOrderHistoryAttempting,
    getUserOrderHistoryError,
    getUserOrderHistory,

    getUserOrderHistoryDetailsAttempting,
    getUserOrderHistoryDetailsError,

    getUserIsEditingProfile,

    getUiAuthSignUpModalVisible,
    getUiAuthIsEmailSent,
    getUiAuthUserId,

    getUiAuthEmail,
    getUiAuthUuid,

    getUiProductList,
    getUiProductCurrentCategory,

    getUiSearchQuery,
    getUiSuccessSearchQuery,

    getUiSearchProductsAttempting,
    getUiSearchProductsError,

    getUiCartStockUnavailableVisible,

    getUiStockCheckAttempting,
    getUiStockCheckUnavailableProduct,
    getUiStockCheckError,

    getUiFilteredProducts,

    getUiOrderDetails,

    getUiGetOrderDetailsAttempting,
    getUiGetOrderDetailsError,

    getUiOrderId,
};
