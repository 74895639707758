/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'reactstrap';

import LoadingModal from 'Components/LoadingModal';
import ErrorMessage from 'Components/Common/ErrorMessage';
import PageSkeleton from 'Components/Skeletons/PageSkeleton';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import { LeftArrow } from 'Icons/Icons';

import CartBreadcrumbs from './Components/CartBreadcrumbs';
import GoldPriceChangeAlert from './Components/GoldPriceChangeAlert';
import EditableProductList from './Components/EditableProductList';
import CartOrderSummary from './Components/CartOrderSummary';
import ModalUnavailableProducts from './Components/ModalUnavailableProducts';
import {
    cartContainer,
    cartTitle,
    continueShopping,
    outOfStockText,
} from './Styles/CartStyles';
import NavActions from '../../Services/Navigation/Actions';

const Cart = (props) => {
    const {
        cartItems,
        cartLoading,
        updateCartQuantityLoading,
        updateCartQuantityError,
        stockUnavailableVisible,
        dismissStockUnavailable,
        getCart,
    } = props;

    useEffect(() => {
        if (!cartLoading && !cartItems.length) getCart();

        return () => {
            dismissStockUnavailable();
        };
    }, []);

    const renderCart = () => {
        const { cartError } = props;

        if (cartLoading) return <PageSkeleton />;
        if (cartError) {
            return (
                <div style={{ marginTop: 20 }}>
                    <ErrorMessage error={cartError} />
                </div>
            );
        }

        return (
            <div>
                <GoldPriceChangeAlert />

                {stockUnavailableVisible && (
                    <div css={outOfStockText}>
                        Some items are out of stock. They have been removed from your cart.
                    </div>
                )}

                <EditableProductList products={cartItems} />

                <ErrorMessage
                    error={updateCartQuantityError}
                    style={{ marginTop: 30, marginBottom: -10 }}
                    textStyle={{ textAlign: 'left' }}
                />

                <Row className='mt-5 ml-2'>
                    <div
                        css={{ cursor: 'pointer' }}
                        onClick={NavActions.navToHome}
                        onKeyPress={NavActions.navToHome}
                        role='button'
                        tabIndex='0'
                    >
                        <LeftArrow />
                        <span css={continueShopping}>
                            Continue Shopping
                        </span>
                    </div>
                </Row>
            </div>
        );
    };

    return (
        <View>
            <Container fluid css={cartContainer}>
                <CartBreadcrumbs currentItem={0} />
                <Row>
                    <Col xs={12} xl={8}>
                        <Row>
                            <Col xs={8} md={10}>
                                <div css={cartTitle}>Shopping Cart</div>
                            </Col>
                            <Col xs={4} md={2} css={{ textAlign: 'end' }}>
                                <div css={cartItems}>
                                    {`${cartItems.length} Items`}
                                </div>
                            </Col>
                        </Row>

                        {renderCart()}
                    </Col>
                    <Col xs={12} xl={4}>
                        <CartOrderSummary />
                    </Col>
                </Row>
            </Container>

            <ModalUnavailableProducts />
            <LoadingModal loading={updateCartQuantityLoading} text='Updating..' />
        </View>
    );
};

const mapStateToProps = (state) => ({
    cartLoading: Selectors.getCartCartAttempting(state),
    cartError: Selectors.getCartCartError(state),
    cartId: Selectors.getCartId(state),
    cartItems: Selectors.getCartItems(state),
    updateCartQuantityLoading: Selectors.getCartUpdateQuantityAttempting(state),
    updateCartQuantityError: Selectors.getCartUpdateQuantityError(state),
    stockUnavailableVisible: Selectors.getUiCartStockUnavailableVisible(state),
});

const mapDispatchToProps = (dispatch) => ({
    dismissStockUnavailable: () => dispatch(Actions.uiSetCartStockUnavailable(false)),
    getCart: () => dispatch(Actions.cartGetCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
