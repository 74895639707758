/** @jsxImportSource @emotion/react */
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { customPaginationText } from '../Styles/ProductListingStyles';

const MAX_PAGES_ON_DISPLAY = 5;

interface PaginationProps {
    currentPage: number;
    onUpdatePage(newPage: number): void;
    maxPages: number;
}

const CustomPagination = (props: PaginationProps): JSX.Element => {
    const { currentPage, onUpdatePage, maxPages } = props;

    const renderPaginationItem = (index: number) => {
        return (
            <PaginationItem key={index} active={currentPage === index}>
                <PaginationLink css={customPaginationText} onClick={() => onUpdatePage(index)}>
                    {index}
                </PaginationLink>
            </PaginationItem>
        );
    };

    const renderPageList = () => {
        let list: Array<JSX.Element> = [];

        for (let i = 1; i <= maxPages; i += 1) {
            list.push(renderPaginationItem(i));
        }

        if (maxPages >= 5) {
            if (currentPage >= 3) {
                if (maxPages - currentPage < 3) {
                    list = list.slice(maxPages - MAX_PAGES_ON_DISPLAY, maxPages);
                } else {
                    list = list.slice((currentPage - 3), (currentPage + 2));
                }
            } else {
                list = list.slice(0, MAX_PAGES_ON_DISPLAY);
            }
        }

        return list;
    };

    const renderBack = () => {
        let disabled = false;

        if (currentPage === 1) disabled = true;

        return (
            <>
                <PaginationItem disabled={disabled}>
                    <PaginationLink css={customPaginationText} first onClick={() => onUpdatePage(1)} />
                </PaginationItem>
                <PaginationItem disabled={disabled}>
                    <PaginationLink css={customPaginationText} previous onClick={() => onUpdatePage(currentPage - 1)} />
                </PaginationItem>
            </>
        );
    };

    const renderNext = () => {
        let disabled = false;

        if (currentPage === maxPages) disabled = true;

        return (
            <>
                <PaginationItem disabled={disabled}>
                    <PaginationLink css={customPaginationText} next onClick={() => onUpdatePage(currentPage + 1)} />
                </PaginationItem>
                <PaginationItem disabled={disabled}>
                    <PaginationLink css={customPaginationText} last onClick={() => onUpdatePage(maxPages)} />
                </PaginationItem>
            </>
        );
    };

    return (
        <Pagination>
            {renderBack()}
            {renderPageList()}
            {renderNext()}
        </Pagination>
    );
};

export default CustomPagination;
