import React from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import PropTypes from 'prop-types';

interface PropsType {
    error: string;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
}

const ErrorMessage = (props: PropsType): JSX.Element | null => {
    const { error, style, textStyle } = props;

    if (!error) return null;

    return (
        <View style={[styles.container, style]}>
            <Text style={[styles.errorText, textStyle]} numberOfLines={3}>
                {error}
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        marginVertical: 5,
    },
    errorText: {
        color: 'red',
        fontSize: 13,
        textAlign: 'center',
    },
});

ErrorMessage.propTypes = {
    error: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

ErrorMessage.defaultProps = {
    error: '',
    style: {},
    textStyle: {},
};

export default ErrorMessage;
