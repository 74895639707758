/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, KeyboardEvent } from 'react';

import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

import Button from 'Components/Button';

import Utils from 'Lib/Utils';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import {
    AccountSubTitle,
    AccountLabel,
} from '../Styles/AccountStyles';

interface Props {
    loading: boolean;
    error: string;
    onChangePassword(params: ChangePasswordThunkParams): void;
}

interface ChangePasswordThunkParams {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const UpdatePassword = (props: Props): JSX.Element => {
    const { loading, error, onChangePassword } = props;

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const prevLoading = Utils.React.usePrevious(loading);

    useEffect(() => {
        if (prevLoading && !loading && !error) {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');

            window.scrollTo(0, 0);
        }
    }, [loading]);

    const onChangePasswordPressed = () => {
        onChangePassword({
            oldPassword, newPassword, confirmPassword,
        });
    };

    const onEnterPressed = (e: KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        if (key === 'Enter') onChangePasswordPressed();
    };

    return (
        <div>
            <Row css={AccountSubTitle} className='pt-3'>Change Password</Row>
            <Row>
                <Col md={6} lg={4}>
                    <FormGroup className='pl-4 pr-4'>
                        <Label css={AccountLabel}>Old Password</Label>
                        <Input
                            type='password'
                            name='oldpassword'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6} lg={4}>
                    <FormGroup className='pl-4 pr-4'>
                        <Label css={AccountLabel}>New Password</Label>
                        <Input
                            type='password'
                            name='newpassword'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6} lg={4}>
                    <FormGroup className='pl-4 pr-4'>
                        <Label css={AccountLabel}>
                            Confirm New Password
                        </Label>
                        <Input
                            type='password'
                            name='confirmnewpassword'
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            onKeyDown={onEnterPressed}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Button
                    text='Reset password'
                    onClick={onChangePasswordPressed}
                    loading={loading}
                    error={error}
                    style={{ marginLeft: 40, marginTop: 10, width: 200 }}
                />
            </Row>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getUserUpdatePasswordAttempting(state),
    error: Selectors.getUserUpdatePasswordError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    onChangePassword: (params: ChangePasswordThunkParams) => dispatch(Actions.userUpdatePassword(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
