/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Selectors from 'Redux/Selectors';

import Image from 'Components/Image';

import Utils from 'Lib/Utils';

import {
    StyleImageContainer,
    ProductImg,
    Variation,
    RowFlexCenter,
    ProductListItemStyle,
    MobileListStyle,
    MobileListStyleBold,
} from '../Styles/ComponentStyles';

function ProductsListComponent(props) {
    const { product, goldPrice } = props;
    const { name, selectedVariant, quantity, imageUrl, currency } = product;

    const variantName = Utils.Product.getItemVariantSizeLengthString(selectedVariant);

    const price = `${Utils.Jemisys.getItemVariantPrice(product, selectedVariant, goldPrice)} ${currency}`;

    return (
        <>
            <Row className='mt-5 d-none d-md-flex'>
                <Col xs='5' css={StyleImageContainer}>
                    <Col xs='5' className='p-0'>
                        <Image
                            css={ProductImg}
                            url={imageUrl}
                            alt='Product Img'
                        />
                    </Col>
                    <Col xs='8'>
                        <div css={ProductListItemStyle} style={{ fontWeight: '700' }}>{name}</div>
                        <div css={Variation}>
                            {`Variation: ${variantName}`}
                        </div>
                    </Col>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{quantity}</div>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{Utils.Formatter.currencyFormatter(price)}</div>
                </Col>
                {/* <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{Utils.Formatter.currencyFormatter(labourCost)}</div>
                </Col> */}
                <Col xs='2' css={RowFlexCenter}>
                    <div css={ProductListItemStyle}>{Utils.Formatter.currencyFormatter(price)}</div>
                </Col>
            </Row>
            <Row className='mt-5 mb-5 d-flex d-md-none'>
                <Col xs='12' css={StyleImageContainer}>
                    <Col xs='4' className='p-0'>
                        <img
                            css={ProductImg}
                            src={imageUrl}
                            alt='Product Img'
                        />
                    </Col>
                    <Col xs='8'>
                        <div css={ProductListItemStyle} style={{ fontWeight: '700' }}>{name}</div>
                        <div css={Variation}>
                            {`Variation: ${variantName}`}
                        </div>
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Quantity: </span>
                            {quantity}
                        </div>
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Price: </span>
                            {Utils.Formatter.currencyFormatter(price)}
                        </div>
                        {/* <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Labour: </span>
                            {Utils.Formatter.currencyFormatter(labourCost)}
                        </div> */}
                        <div css={MobileListStyle}>
                            <span css={MobileListStyleBold}>Total: </span>
                            {Utils.Formatter.currencyFormatter(price)}
                        </div>
                    </Col>
                </Col>
            </Row>
        </>
    );
}

const mapStateToProps = (state) => ({
    goldPrice: Selectors.getHomeGoldPrice(state),
});

export default connect(mapStateToProps)(ProductsListComponent);
