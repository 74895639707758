import { ICartProduct, UseCaseResponseStatus } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';

import Toast from 'Lib/Toast';
import Selectors from 'Redux/Selectors';
import Utils from 'Lib/Utils';

import NavActions from 'Services/Navigation/Actions';
import { AsyncThunkAPI } from 'Redux/types';

import { ErrorTypes } from '.';

interface IRemoveFromCartThunkParams {
    product: ICartProduct;
}

export interface IRemoveFromCartThunkResponse {
    removedProduct: ICartProduct;
}

export default createAsyncThunk<
    IRemoveFromCartThunkResponse,
    IRemoveFromCartThunkParams,
    AsyncThunkAPI
>('cart/removeFromCart', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const { product } = params;
    const salesType = Utils.Product.getSalesType(product.customAttributes);

    const variant = {
        ...product.selectedVariant,
        sku: (salesType === Utils.SalesTypeEnum.JEWELERY) ? product.id : product.selectedVariant.sku,
    };

    const actualProduct: ICartProduct = {
        ...product,
        selectedVariant: {
            ...variant,
        },
    };

    const userId = Selectors.getUserId(state);

    const response = await thunkAPI.extra.useCases.cart.removeFromCartLocalWeb.execute({
        userId,
        product: actualProduct,
    });

    if (response.status === UseCaseResponseStatus.Success) {
        Toast.show('Product removed');

        return {
            removedProduct: product,
        };
    }

    const { type, title } = response.data;

    if (type === ErrorTypes.RemoveFromCartLocalWebError.NoCartFound) {
        const errorMessage = 'No cart found. Please try again.';

        Toast.showError(errorMessage);
        // NavActions.navToHome();
        return thunkAPI.rejectWithValue(errorMessage);
    }

    const errorMessage = title || 'Something went wrong. Please try again.';
    Toast.showError(errorMessage);

    return thunkAPI.rejectWithValue(errorMessage);
});
