/** @jsxImportSource @emotion/react */
import { ProductListTitleStyle, RowFlexCenter } from 'Containers/User/Styles/ComponentStyles';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { tableHeaderOverwrite } from '../Styles/OrderReviewStyles';

import ProductsListComponent from './ProductsListComponent';

function ProductList({ products }) {
    return (
        <div>
            <Row className='mt-4 d-none d-sm-flex'>
                <Col xs='4'>
                    <div css={[ProductListTitleStyle, tableHeaderOverwrite]}>Product Details</div>
                </Col>
                <Col xs='2' css={RowFlexCenter}>
                    <div css={[ProductListTitleStyle, tableHeaderOverwrite]}>Quantity</div>
                </Col>
                <Col xs='3' css={RowFlexCenter}>
                    <div css={[ProductListTitleStyle, tableHeaderOverwrite]}>Price</div>
                </Col>
                {/* <Col xs='2' css={RowFlexCenter}>
                    <div css={[ProductListTitleStyle, tableHeaderOverwrite]}>Labour Cost</div>
                </Col> */}
                <Col xs='3' css={RowFlexCenter}>
                    <div css={[ProductListTitleStyle, tableHeaderOverwrite]}>Total</div>
                </Col>
            </Row>
            {products.map((product) => (
                <ProductsListComponent key={product.id} product={product} />
            ))}
        </div>
    );
}

export default ProductList;
