import { css } from '@emotion/react';
import colors from 'Themes/colors';
import fonts from 'Themes/fonts';

const { black, orange, bordergrey } = colors;

export const OrdersContainer = css({
    padding: '0px',
    '@media(min-width: 400px)': {
        padding: '0px 0.5em',
    },
    '@media(min-width: 808px)': {
        padding: '0px 2em',
    },
});
export const OrderTabs = css({
    display: 'flex',
    justifyContent: 'left',
    borderBottom: '1px',
    borderBottomStyle: 'solid',
    borderColor: bordergrey,
});
export const OrderTab = css({
    cursor: 'pointer',
    padding: '0.1em 0px',
    margin: '0px 20px 0px 0px',
    '@media(min-width: 808px)': {
        margin: '0px 73px 0px 0px',
        padding: '0.5em 0px',
    },
});
export const OrderTabSelected = css({
    cursor: 'pointer',
    borderBottom: '4px',
    borderBottomStyle: 'solid',
    borderColor: orange,
    padding: '0.1em 0px',
    margin: '0px 20px 0px 0px',
    '@media(min-width: 808px)': {
        margin: '0px 73px 0px 0px',
        padding: '0.5em 0px',
    },
});
export const TabTitle = css({
    font: '16px Archivo-Regular, serif',
    color: orange,
    '&:hover': { color: black },
    fontWeight: '700',
    whiteSpace: 'nowrap',
});
export const TabTitleActive = css({
    font: '16px Archivo-Regular, serif',
    color: black,
    fontWeight: '700',
    whiteSpace: 'nowrap',
});
export const orderEmpty = css({
    font: fonts.primary,
    fontSize: 16,
    marginTop: 20,
});
