/** @jsxImportSource @emotion/react */
import { IProduct } from '@ddot/AddToCartCommon';
import React, { useState, useEffect } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';
import { Container, Button } from 'reactstrap';

import Skeleton from 'Components/Skeletons/PageSkeleton';
import ErrorMessage from 'Components/Common/ErrorMessage';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import WishListProduct from './Components/WishListProduct';
import LoginSignUpModal from './LoginSignUpModal';
import {
    WishTitle,
    SubTitle,
    WishProductGrid,
    WishProduct,
    WishlistEmpty,
} from './Styles/MainStyles';

interface WishlistProps {
    loading: boolean;
    error: string;
    wishlist: IProduct[];
    productListLoading: boolean;
    userInfoLoading: boolean;
    userId?: string;
    getWishlist(): void;
}

const Wishlist = (props: WishlistProps) => {
    const {
        loading, error, wishlist, productListLoading, userInfoLoading, userId, getWishlist,
    } = props;

    const [modal, toggle] = useState(false);

    useEffect(() => {
        if (!productListLoading && !userInfoLoading) getWishlist();
    }, [productListLoading, userInfoLoading]);

    const openModal = () => {
        toggle(true);
    };

    const onLoginSuccess = () => {
        toggle(false);
        getWishlist();
    };

    const onSignup = () => {
        toggle(true);
    };

    const renderBody = () => {
        if (loading || productListLoading || userInfoLoading) return <Skeleton />;
        if (error) return <ErrorMessage error={error} style={{ marginTop: 20, marginBottom: 20 }} />;

        if (!wishlist.length) {
            return (
                <div className='mx-5' css={WishlistEmpty}>
                    You don&apos;t have any items in your wishlist. Add one now!
                </div>
            );
        }

        return (
            <div>
                <div className='mx-5'>
                    <hr
                        css={{ width: '100%', border: '1px solid #BF7247' }}
                    />
                </div>
                <div css={WishProductGrid}>
                    {wishlist.map((item) => (
                        <div css={WishProduct} key={item.id}>
                            <WishListProduct product={item} />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSignIn = () => {
        if (userInfoLoading) return <Skeleton row={1} />;
        if (userId) return false;

        return (
            <div className='mt-2' css={SubTitle}>
                `To view items saved in your account, please
                <Button color='link' onClick={openModal}>sign in</Button>
            </div>
        );
    };

    return (
        <View style={{ marginBottom: 30 }}>
            <Container fluid>
                <div className='mt-5 mx-5'>
                    <div css={WishTitle}>Wish List </div>
                    {renderSignIn()}
                </div>
                {renderBody()}
            </Container>
            <LoginSignUpModal
                modal={modal}
                toggle={toggle}
                onAuthorized={onLoginSuccess}
                onSignup={onSignup}
            />
        </View>
    );
};

Wishlist.defaultProps = {
    userId: '',
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getUserWishlistAttempting(state),
    error: Selectors.getUserWishlistError(state),
    wishlist: Selectors.getUserWishlist(state),
    productListLoading: Selectors.getProductsListAttempting(state),
    userInfoLoading: Selectors.getUserInfoAttempting(state),
    userId: Selectors.getUserId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getWishlist: () => dispatch(Actions.userGetWishlistLocalWeb()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
