/** @jsxImportSource @emotion/react */
import React from 'react';

import { IProductWidget } from '@ddot/AddToCartCommon';
import { Container, Col, Row } from 'reactstrap';
import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';
import Colors from 'Themes/colors';

import FeaturedProduct from './FeaturedProduct';

interface WidgetProps {
    widget: IProductWidget;
}

const ProductWidget = (props: WidgetProps): JSX.Element | null => {
    const { widget } = props;
    const { title, description, products } = widget;

    if (!widget) return null;

    const renderProducts = () => {
        return products.map(item => (
            <Col xs={6} sm={4} md={3} key={item.id} className='p-0'>
                <FeaturedProduct product={item} />
            </Col>
        ));
    };

    return (
        <Container fluid css={styles.container}>
            <div css={styles.title}>{title}</div>
            <Row xs={1} sm={4} md={5} style={{ marginTop: 20 }}>
                {renderProducts()}
            </Row>
        </Container>
    );
};

const styles = {
    container: css({
        padding: 20,
        paddingLeft: '10%',
        paddingRight: '10%',
        backgroundColor: Colors.white,
        border: '1px',
        borderWidth: 1,
        borderColor: Colors.border,
        textAlign: 'center',
        marginTop: 10,

        '@media(min-width:992px)': {
            paddingLeft: '15%',
            paddingRight: '15%',
        },
    }),
    title: css({
        fontFamily: Fonts.primaryBold,
        fontSize: 35,
        color: Colors.primary,
    }),
};

export default ProductWidget;
