import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Routes from 'Navigation/Routes';

import Actions from 'Redux/Actions';

interface FullProps {
    startup(): void;
}

const Full = (props: FullProps) => {
    useEffect(() => {
        const { startup } = props;

        startup();
    }, []);

    return (
        <>
            <Routes />
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        startup: () => dispatch(Actions.authStartup()),
    };
};

export default connect(null, mapDispatchToProps)(Full);
