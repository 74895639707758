import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native-web';

import { TrafficCone, Facebook, Instagram } from 'Icons/Icons';

import Fonts from 'Themes/fonts';
import Colors from 'Themes/colors';

const ErrorScreen = (): JSX.Element => {
    const openUrl = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <View style={styles.container}>
            <TrafficCone style={{ height: 100, width: 100 }} />

            <Text style={styles.title}>
                Sorry, something went wrong on our end.
            </Text>

            <Text style={styles.text}>
                Please refresh the page in a little bit, check out our social media platforms for updates.
            </Text>

            <View style={styles.socialCol}>
                <Pressable style={styles.socialItem} onPress={() => openUrl('https://facebook.com/SengFongJewellery5201313/')}>
                    <Facebook style={{ width: 24 }} />
                    <Text style={styles.socialText}>
                        SengFongJewellery
                    </Text>
                </Pressable>

                <Pressable style={styles.socialItem} onPress={() => openUrl('https://www.instagram.com/sengfong_jwl/')}>
                    <Instagram style={{ width: 24 }} />
                    <Text style={styles.socialText}>
                        sengfong_jwl
                    </Text>
                </Pressable>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100vh',
        width: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: Colors.background,
    },
    title: {
        marginTop: 20,
        fontFamily: Fonts.primaryBold,
        fontSize: 18,
        textAlign: 'center',
    },
    text: {
        marginTop: 20,
        fontFamily: Fonts.primary,
        textAlign: 'center',
        fontSize: 16,
    },
    socialCol: {
        marginTop: 20,
    },
    socialItem: {
        marginTop: 15,
        flexDirection: 'row',
        alignItems: 'center',
    },
    socialText: {
        fontFamily: Fonts.primary,
        fontSize: 15,
        marginLeft: 10,
    },
});

export default ErrorScreen;
