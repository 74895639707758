import { IProduct } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Selectors from 'Redux/Selectors';

import { AsyncThunkAPI } from 'Redux/types';

interface ISearchProductThunkParams {
    query: string;
}

interface ISearchProductThunkResponse {
    query: string;
    products: IProduct[];
}

export default createAsyncThunk<
    ISearchProductThunkResponse,
    ISearchProductThunkParams,
    AsyncThunkAPI
>('ui/searchProducts', async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const productList = Selectors.getProductsList(state);
    const { query } = params;

    if (!query || !query.trim().length) {
        return {
            query: '',
            products: [],
        };
    }

    let searchedProducts: IProduct[];

    try {
        searchedProducts = productList.filter((p) => (p.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || p.id.toLowerCase().indexOf(query.toLowerCase()) >= 0));

        return {
            query,
            products: searchedProducts,
        };
    } catch (error: any) {
        const errorMessage = error || 'Something went wrong. Please try again.';

        return thunkAPI.rejectWithValue(errorMessage);
    }
});
