import { css } from '@emotion/react';
import colors from '../../../Themes/colors';
import fonts from '../../../Themes/fonts';

export const orderReviewSectionTitleContainer = css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.2em',
});

export const tableHeaderOverwrite = css({
    fontSize: '14px',

    '@media(min-width:992px)': {
        fontSize: '16px',
    },
});

export const orderReviewSectionTitle = css({
    font: '18px Archivo-Bold, sans-serif',
    color: colors.black,
});

export const orderReviewSection = css({
    padding: '15px 10px',

    '@media(min-width:576px)': {
        padding: '15px 0px 10px 40px',
    },
});

export const orderReviewText = css({
    font: '16px Archivo-Regular, sans-serif',
    color: colors.black,
    marginTop: '0.5em',
});

export const orderReviewEditText = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.black,
    marginLeft: '5px',
});

export const placeOrderButton = css({
    font: '18px Archivo-Regular, sans-serif',
    color: colors.white,
    backgroundColor: colors.primary,
    border: `2px solid ${colors.primary}`,
    borderRadius: '3px',
    padding: '12px',
    width: '100%',
    transition: 'ease 0.2s',
    '&:hover': {
        backgroundColor: colors.white,
        border: `2px solid ${colors.primary}`,
        color: colors.primary,
    },
    '&:focus': {
        backgroundColor: colors.primary,
        border: `2px solid ${colors.primary}`,
        color: colors.white,
    },
});

export const placeOrderInfo = css({
    font: '14px Archivo-Regular, sans-serif',
    color: colors.gray,
    marginTop: '1.2em',
});
