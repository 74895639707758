import Image1 from './Image1.jpg';
import Image2 from './Image2.jpg';
import Image3 from './Image3.jpg';
import Image4 from './Image4.jpg';
import Image5 from './Image5.jpg';
import Image6 from './Image6.jpg';
import Image7 from './Image7.jpg';
import Image8 from './Image8.jpg';
import Image9 from './Image9.jpg';
import Image10 from './Image10.jpeg';

export default {
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
};
