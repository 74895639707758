import { css } from '@emotion/react';
import fonts from 'Themes/fonts';
import colors from '../../../Themes/colors';

const { black, lightbrown, lighterbrown, lightred } = colors;

export const UserContainer = css({
    backgroundColor: '#F6F7F8',
    paddingBottom: '40px',
});

export const UserTitle = css({
    padding: '22px 22px 0px',
    font: '18px Archivo-Bold, serif',
    color: black,
    marginBottom: '20px',
    '@media(min-width: 568px)': {
        padding: '42px 92px 0px',
        font: '25px Archivo-Bold, serif',
        marginBottom: '50px',
    },
});

export const UserTabTittle = css({
    font: '14px Archivo-Regular, serif',
    color: black,
    padding: '0px 8px',
    '@media(min-width: 768px)': {
        font: '18px Archivo-Regular, serif',
    },
});

export const LogoutTitle = css({
    font: '14px Archivo-Regular, serif',
    color: lightred,
    padding: '0px 8px',
    '@media(min-width: 768px)': {
        font: '18px Archivo-Regular, serif',
    },
});

export const UserTabStyle = css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
});
export const LogoutContainer = css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

export const UserTabContainer = css({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '5px',
    '@media(min-width: 870px)': {
        padding: '15px',
    },
});

export const UserTabList = css({
    listStyleType: 'none',
    padding: '0px',
    margin: '0px',
});

export const UserTabSelected = css({
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    background: lightbrown,
    padding: '20px',
    borderRadius: '20px',
    margin: '10px 0px',
    cursor: 'pointer',
    '@media(min-width: 575px)': {
        padding: '11px 0px 11px 10px',
        justifyContent: 'start',
        width: 'clamp(40px,80%,180px)',
    },
    '@media(min-width: 870px)': {
        padding: '11px 10px 11px 20px',
    },
});

export const UserTab = css({
    justifyContent: 'center',
    padding: '20px',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    '&:hover': { background: lighterbrown, borderRadius: '18px' },
    borderRadius: '20px',
    margin: '10px 0px',
    cursor: 'pointer',

    '@media(min-width: 575px)': {
        padding: '11px 0px 11px 10px',
        justifyContent: 'start',
        width: 'clamp(40px,80%,180px)',
    },
    '@media(min-width: 870px)': {
        padding: '11px 10px 11px 20px',
    },
});

export const UserTabRowCointainer = css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
});

export const WishTitle = css({
    font: '25px Archivo-Bold, serif',
    color: black,
});

export const SubTitle = css({
    font: '18px Archivo-Regular, serif',
    color: black,
});

export const WishlistEmpty = css({
    fontFamily: fonts.primary,
    color: black,
    marginTop: 20,
});

export const WishProductGrid = css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '@media(min-width: 880px)': {
        gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media(min-width: 1200px)': {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    padding: '10px',
});

export const WishProduct = css({
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});

export const FilterContainer = css({
    display: 'flex',
    alignItems: 'center',
});

export const OptionsContainer = css({
    display: 'flex',
    justifyContent: 'space-between',
});

export const TabMobileView = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
});
