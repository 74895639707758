import { UseCaseResponseStatus, JemisysErrorTypes } from '@ddot/AddToCartCommon';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkAPI } from 'Redux/types';

import uiSlice from 'Redux/slices/ui';

export interface IVerifyForgetPasswordThunkParams {
    hash: string;
}

export interface IVerifyForgetPasswordThunkResponse {
    email?: string;
    userId?: string;
    errorMessage?: string;
}

export default createAsyncThunk<
    IVerifyForgetPasswordThunkResponse,
    IVerifyForgetPasswordThunkParams,
    AsyncThunkAPI
>('auth/verifyForgetPassword', async (params, thunkAPI) => {
    const { hash } = params;

    const decodedHash = decodeURI(hash);

    const response = await thunkAPI.extra.useCases.auth.verifyForgetPassword.execute({ uuid: decodedHash });

    if (response.status === UseCaseResponseStatus.Success) {
        if (response.data.errorMessage) {
            thunkAPI.rejectWithValue(response.data.errorMessage || 'Something went wrong. Please try again.');
            return response.data;
        } if (response.data.email && response.data.userId && decodedHash) {
            thunkAPI.dispatch(uiSlice.actions.uiSetForgetPasswordUserId(response.data.userId));
            thunkAPI.dispatch(uiSlice.actions.uiSetForgetPasswordEmail(response.data.email));
            thunkAPI.dispatch(uiSlice.actions.uiSetForgetPasswordUuid(decodedHash));
            return response.data;
        }
    } else {
        const { type, message } = response.data;

        const ErrorTypes = JemisysErrorTypes.AuthErrorTypes.VerifyForgetPasswordError;

        if (type === ErrorTypes.ExpiredLink) {
            return thunkAPI.rejectWithValue('The reset password URL has expired. Please request for a new URL again.');
        }

        if (type === ErrorTypes.ServerError) {
            return thunkAPI.rejectWithValue(message || 'Something went wrong. Please try again.');
        }
    }

    return thunkAPI.rejectWithValue('Something went wrong, please try again');
});
