import React from 'react';
import { ActivityIndicator, View, Text, Modal, StyleSheet } from 'react-native-web';

import Colors from 'Themes/colors';
import Fonts from 'Themes/fonts';

interface LoadingProps {
    text?: string;
    loading: boolean;
}

const LoadingModal = (props: LoadingProps): JSX.Element | null => {
    const { loading, text } = props;

    if (!loading) return null;

    return (
        <Modal visible={loading} transparent>
            <View style={styles.container}>
                <ActivityIndicator color={Colors.primary} />
                <Text style={styles.text}>
                    {text}
                </Text>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        width: 300,
        backgroundColor: 'white',
        borderColor: Colors.border,
        borderWidth: 1,
        borderRadius: 10,
        margin: 'auto',
    },
    text: {
        fontFamily: Fonts.primary,
        marginTop: 10,
        textAlign: 'center',
    },
});

LoadingModal.defaultProps = {
    text: '',
};

export default LoadingModal;
