/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { View } from 'react-native-web';
import { connect } from 'react-redux';

import ErrorMessage from 'Components/Common/ErrorMessage';
import LoadingScreen from 'Components/Skeletons/PageSkeleton';

import Actions from 'Redux/Actions';
import Selectors from 'Redux/Selectors';

import { IProductDetails } from 'Redux/slices/products/Entities';
import { IProduct } from '@ddot/AddToCartCommon';
import { RootState } from 'Redux/store';

import { css } from '@emotion/react';

import Fonts from 'Themes/fonts';
import Colors from 'Themes/colors';

import { Col, Container, Row } from 'reactstrap';

import Banner from './Components/Banner';
import CustomBreadcrumb from './Components/CustomBreadcrumb';
import ProductDetailsRow from './Components/ProductDetailsRow';
// import Reviews from './Components/Reviews';
// import SimilarItems from './Components/SimilarItems';

import FeaturedProduct from '../Home/Components/FeaturedProduct';

interface ComponentProps {
    loading: boolean;
    error: string;
    productDetails: IProductDetails | null;
    match: {
        params: { productId: string }
    };
    setProductDetails(productDetails: IProductDetails): void;
    productList: IProduct[];
    setProductDetailsError(error: string): void;
    setProductDetailsAttempt(): void;
}

const ProductDetails = (props: ComponentProps): JSX.Element => {
    const { loading, error, productDetails, match: { params: { productId } }, setProductDetails, productList, setProductDetailsError, setProductDetailsAttempt } = props;

    useEffect(() => {
        setProductDetailsAttempt();
        if (productList.length) {
            const thisProduct = productList.find(product => product.id === productId);
            if (thisProduct) setProductDetails({ product: thisProduct });
            if (!thisProduct) setProductDetailsError('Product does not exist, please try again');
        }
    }, [productId, productList]);

    const renderProducts = () => {
        const thisProduct = productList.find(product => product.id === productId);
        const products = productList.filter(product => product.categoryId === thisProduct?.categoryId);

        const start = products.length > 4 ? Math.floor(Math.random() * (products.length - 3)) : 0;
        const end = start + 4;

        return products.slice(start, end).map(item => (
            <Col xs={6} sm={4} md={3} key={item.id} className='p-0'>
                <FeaturedProduct product={item} />
            </Col>
        ));
    };

    const renderBody = () => {
        if (loading) return <LoadingScreen />;
        if (error) return <ErrorMessage error={error} />;
        if (!productDetails) return false;

        return (
            <div style={{ marginBottom: 30 }}>
                <ProductDetailsRow
                    productDetails={productDetails}
                />
                {/* <SimilarItems
                    similarItems={productDetails.similarProducts}
                /> */}
                {/* <Reviews
                    loading={false}
                    productDetails={productDetails}
                    error=''
                /> */}
            </div>
        );
    };

    return (
        <View>
            <Banner />
            <CustomBreadcrumb />
            {renderBody()}
            <Container fluid css={styles.container}>
                <div css={styles.title}>Products you might like</div>
                <Row xs={1} sm={4} md={5} style={{ marginTop: 20 }}>
                    {renderProducts()}
                </Row>
            </Container>
        </View>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getProductDetailsAttempting(state),
    error: Selectors.getProductDetailsError(state),
    productDetails: Selectors.getProductDetails(state),
    productList: Selectors.getProductsList(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setProductDetailsAttempt: () => dispatch(Actions.setProductDetailsAttempt()),
    setProductDetails: (productDetails: IProductDetails) => dispatch(Actions.setProductDetails(productDetails)),
    setProductDetailsError: (error: string) => dispatch(Actions.setProductDetailsError(error)),
});

const styles = {
    container: css({
        padding: 20,
        paddingLeft: '10%',
        paddingRight: '10%',
        backgroundColor: Colors.white,
        border: '1px',
        borderWidth: 1,
        borderColor: Colors.border,
        textAlign: 'center',
        marginTop: 10,

        '@media(min-width:992px)': {
            paddingLeft: '15%',
            paddingRight: '15%',
        },
    }),
    title: css({
        fontFamily: Fonts.primaryBold,
        fontSize: 25,
        color: Colors.primary,
    }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
