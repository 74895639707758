import React, { useEffect } from 'react';

import { View, Text } from 'react-native-web';
import { connect } from 'react-redux';

import Fonts from 'Themes/fonts';
import colors from 'Themes/colors';
import { Maintenance } from 'Icons/Icons';

import Selectors from 'Redux/Selectors';
import { RootState } from 'Redux/store';

import NavActions from 'Services/Navigation/Actions';

interface IProps {
    isMaintenance: boolean;
}

const MaintenanceScreen = (props: IProps): JSX.Element => {
    const { isMaintenance } = props;

    useEffect(() => {
        if (!isMaintenance) NavActions.navResetToHome();
    }, [isMaintenance]);

    return (
        <View style={maintenanceStyles.mainContainer}>
            <Maintenance style={{ height: 300, width: 300 }} />
            <div style={maintenanceStyles.bottomContainer}>
                <Text style={maintenanceStyles.mainText}>
                    Sorry, we are undergoing maintenance.
                </Text>
                <Text style={maintenanceStyles.subText}>
                    We are working to getting it running as soon as possible!
                </Text>
            </div>
        </View>
    );
};

const maintenanceStyles = {
    mainContainer: {
        height: '100vh',
        width: '100%',
        flex: 1,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.background,
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
    } as const,
    mainText: {
        fontFamily: Fonts.primaryBold,
        fontSize: 18,
    },
    subText: {
        fontFamily: Fonts.primary,
        fontSize: 14,
        marginTop: 20,
    },
};

const mapStateToProps = (state: RootState) => ({
    isMaintenance: Selectors.getHomeIsMaintenance(state),
});

export default connect(mapStateToProps)(MaintenanceScreen);
