const MAX_NUMBER_OF_PRODUCTS_PER_PAGE = 12;

const GUEST_ID = '0160437817';
const GUEST_NAME = 'guest';

const CYBERSOURCE_PAYMENT_URL = process.env.REACT_APP_CYBERSOURCE_PAYMENT_URL;

export default {
    MAX_NUMBER_OF_PRODUCTS_PER_PAGE,

    GUEST_ID,
    GUEST_NAME,

    CYBERSOURCE_PAYMENT_URL,
};
