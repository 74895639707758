/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { SerializedStyles } from '@emotion/react';

import Images from 'Assets/Images/PlaceholderImage';

interface ImageProps {
    url: string;
    alt?: string;
    style?: React.CSSProperties;
    css?: SerializedStyles;
}

const Image = (props: ImageProps): JSX.Element => {
    const { url, alt, style } = props;

    const [error, setError] = useState(!url);

    let imageUrl = url;
    let imageStyle = style;

    if (error) {
        imageUrl = Images.NoImagePlaceholder;
        imageStyle = {
            ...style,
            objectFit: 'none',
        };
    }

    return (
        <img
            onError={() => setError(true)}
            src={imageUrl}
            alt={alt || 'Image'}
            {...props}
            style={imageStyle}
        />
    );
};

Image.defaultProps = {
    alt: '',
    style: {},
    css: {},
};

export default Image;
