/** @jsxImportSource @emotion/react */
import React from 'react';
import { connect } from 'react-redux';

import { ActivityIndicator } from 'react-native-web';

import Selectors from 'Redux/Selectors';
import { IGoldPrice } from 'Redux/slices/home/Entities';
import { RootState } from 'Redux/store';

import styles from '../Styles/PromoStyles';

interface PromoProps {
    loading: boolean;
    goldPrice: IGoldPrice;
}

const Promo = (props: PromoProps) => {
    const { loading, goldPrice } = props;
    const { gold750, gold916, gold999, gold9999 } = goldPrice;

    const renderPrice = (label: string, amount: number | null) => {
        if (amount && amount > 0) {
            return (
                <div css={styles.promoBarText} style={{ marginLeft: 10, marginRight: 10 }}>
                    {`Gold ${label}: RM${amount}`}
                </div>
            );
        }
        return null;
    };

    const renderBody = () => {
        if (loading) {
            return (
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                    <ActivityIndicator />
                </div>
            );
        }

        return (
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                {renderPrice('750', gold750)}
                {renderPrice('916', gold916)}
                {renderPrice('999', gold999)}
                {renderPrice('999.9', gold9999)}
            </div>
        );
    };

    return (
        <div css={styles.promoBar}>
            {renderBody()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getHomeGoldPriceAttempting(state),
    goldPrice: Selectors.getHomeGoldPrice(state),
});

export default connect(mapStateToProps)(Promo);
