/** @jsxImportSource @emotion/react */
import React from 'react';
import {
    OrderTabs,
    OrderTab,
    OrderTabSelected,
    TabTitle,
    TabTitleActive,
} from '../Styles/OrderHistoryStyles';

function TabsComponent({ selectedTab, TAB_OPTIONS, setSelectedTab }) {
    return (
        <div css={OrderTabs}>
            <div
                onClick={() => setSelectedTab(TAB_OPTIONS[0])}
                onKeyPress={() => setSelectedTab(TAB_OPTIONS[0])}
                role='button'
                tabIndex='0'
                css={
                    selectedTab === TAB_OPTIONS[0] ? OrderTabSelected : OrderTab
                }
            >
                <span
                    css={
                        selectedTab === TAB_OPTIONS[0]
                            ? TabTitleActive
                            : TabTitle
                    }
                >
                    In Progress
                </span>
            </div>
            <div
                onClick={() => setSelectedTab(TAB_OPTIONS[1])}
                onKeyPress={() => setSelectedTab(TAB_OPTIONS[1])}
                role='button'
                tabIndex='0'
                css={
                    selectedTab === TAB_OPTIONS[1] ? OrderTabSelected : OrderTab
                }
            >
                <span
                    css={
                        selectedTab === TAB_OPTIONS[1]
                            ? TabTitleActive
                            : TabTitle
                    }
                >
                    Shipped
                </span>
            </div>
            {/* <div
                onClick={() => setSelectedTab(TAB_OPTIONS[2])}
                onKeyPress={() => setSelectedTab(TAB_OPTIONS[2])}
                role='button'
                tabIndex='0'
                css={
                    selectedTab === TAB_OPTIONS[2] ? OrderTabSelected : OrderTab
                }
            >
                <span
                    css={
                        selectedTab === TAB_OPTIONS[2]
                            ? TabTitleActive
                            : TabTitle
                    }
                >
                    Cancelled
                </span>
            </div> */}
        </div>
    );
}

export default TabsComponent;
