/** @jsxImportSource @emotion/react */
import { Close } from 'Icons/Icons';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import NavActions from 'Services/Navigation/Actions';

import LOV from 'Lib/LOVs/ShippingAddressLovs';
import ValidationCheck from 'Components/Common/Validation';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Input,
    Form,
    Container,
    CustomInput,
    Spinner,
    Label,
} from 'reactstrap';

import ErrorMessage from 'Components/Common/ErrorMessage';
import Utils from 'Lib/Utils';

import Selectors from 'Redux/Selectors';
import CustomButton from 'Components/Button';
import DatePicker from 'Components/DatePicker';
import Actions from 'Redux/Actions';

import {
    leftContainer,
    rightContainer,
    ContainerAlignCenter,
    leftTitle,
    InputStyle,
    CheckBoxContainer,
    ButtonStyleLeft,
    CloseContainer,
    RightTitle,
    CheckBoxContainerRight,
    CheckBoxOuterContainerRight,
    ForgotPasswordStyle,
    ButtonStyleRight,
    LoginContainer,
    nestedSignupModal,
    nestedSignupModalTitle,
    nestedSignupModalInput,
    nestedSignupModalSubmitButton,
    loginInput,
    formGroupCss,
    signUpError,
} from './Styles/LoginStyles';

const LoginSignUpModal = (props) => {
    const {
        modal,
        toggle,
        loading,
        error,
        onAuthorized,
        onSignup,
        signup,
        signingup,
        signupError,
        signUpModalVisible,
        forgetPasswordLoading,
        forgetPasswordError,
        sendForgetPasswordEmail,
        forgetPasswordEmailSent,
        setIsEmailSent,
    } = props;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedCountryStates, setselectedCountryStates] = useState([]);
    const [signupFirstName, setSignupFirstName] = useState('');
    const [signupLastName, setSignupLastName] = useState('');
    const [signupEmail, setSignupEmail] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const [signupConfirmedPassword, setSignupConfirmedPassword] = useState('');
    const [signupAddress1, setSignupAddress1] = useState('');
    const [signupAddress2, setSignupAddress2] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('Malaysia');
    const [selectedState, setSelectedState] = useState('Selangor');
    const [signupCity, setSignupCity] = useState('');
    const [signupPostcode, setSignupPostcode] = useState('');
    const [signupPhoneNumber, setSignupPhoneNumber] = useState('');
    const [signupSalutation, setSignupSalutation] = useState('');
    const [signupGender, setSignupGender] = useState('Gender');
    const [signupIdNo, setSignupIdNo] = useState('');
    const [signupDateOfBirth, setSignupDateOfBirth] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [signUpBasicError, setSignUpBasicError] = useState('');
    const [signUpValidateError, setSignUpValidateError] = useState('');

    const [sendEmailModal, setSendEmailModal] = useState(false);
    const [forgetPasswordEmail, setForgetPasswordEmail] = useState('');
    const [forgetPasswordEmailValidateError, setForgetPasswordEmailValidateError] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            const country = LOV.find(item => item.country === selectedCountry);
            setselectedCountryStates(country?.states);
            setSelectedState(country?.states[0]);
        }
    }, [selectedCountry]);

    const toggleSignupModal = () => {
        const { setSignUpModalVisible } = props;
        setSignUpModalVisible(!signUpModalVisible);
    };

    const toggleSendEmailModal = () => {
        setIsEmailSent(false);
        setForgetPasswordEmail('');
        setForgetPasswordEmailValidateError('');
        setSendEmailModal(!sendEmailModal);
    };

    const onClosePressed = () => {
        toggle(toggle(true));
    };

    const handleSignupClick = () => {
        setSignUpBasicError('');

        const validationError = Utils.Validator.validateSignUpBasic({
            firstName: signupFirstName,
            lastName: signupLastName,
            email: signupEmail,
            password: signupPassword,
            confirmPassword: signupConfirmedPassword,
        });

        if (validationError) {
            setSignUpBasicError(validationError);
        } else if (!agreeTerms) {
            setSignUpBasicError('Please agree to the Terms and Privacy Policy.');
        } else {
            toggle();
            toggleSignupModal();
        }
    };

    const afterSignup = () => {
        const country = LOV.find(item => item.country === selectedCountry);

        onSignup();
        setselectedCountryStates(country?.states);
        setSignupFirstName('');
        setSignupLastName('');
        setSignupEmail('');
        setSignupPassword('');
        setSignupConfirmedPassword('');
        setSignupAddress1('');
        setSignupAddress2('');
        setSignupCity('');
        setSelectedState('Selangor');
        setSelectedCountry('Malaysia');
        setSignupPostcode('');
        setSignupPhoneNumber('');
        setSignupIdNo('');
        setSignupSalutation('');
        setSignupGender('');
        setSignupDateOfBirth('');
        setAgreeTerms(false);
        setUsername('');
        setPassword('');
        setSignUpBasicError('');
        setSignUpValidateError('');
    };

    const handleSubmitSignup = () => {
        setSignUpValidateError('');

        const data = {
            firstName: signupFirstName,
            lastName: signupLastName,
            email: signupEmail,
            password: signupPassword,
            address1: signupAddress1,
            address2: signupAddress2,
            city: signupCity,
            state: selectedState,
            country: selectedCountry,
            postCode: signupPostcode,
            phoneNumber: signupPhoneNumber,
            idNo: signupIdNo,
            salutation: signupSalutation,
            gender: signupGender,
            birthday: signupDateOfBirth,
            onSignup: afterSignup,
        };

        const validationError = Utils.Validator.validateSignUp(data);

        if (validationError) {
            setSignUpValidateError(validationError);
        } else signup(data);
    };

    const handleInputChange = (e, inputField, setInputField) => {
        const { value } = e.target;
        setInputField(value);
    };

    const handleLoginOnKeyDown = (e) => {
        const { key } = e;
        if (key === 'Enter') onLoginPressed();
    };

    const handleSignUpBasicOnKeyDown = (e) => {
        const { key } = e;
        if (key === 'Enter') handleSignupClick();
    };

    const onLoginPressed = () => {
        const { login } = props;

        login({ username, password, onAuthorized });
    };

    const renderCountryOptions = () => {
        return LOV.map(item => {
            return (
                <option value={item.country} key={item.country}>{item.country}</option>
            );
        });
    };

    const renderStateOptions = () => {
        return selectedCountryStates?.map(item => {
            return (
                <option value={item} key={item}>{item}</option>
            );
        });
    };

    const handleForgetPasswordClick = () => {
        toggle();
        toggleSendEmailModal();
    };

    const handleForgetPasswordBack = () => {
        toggleSendEmailModal();
        toggle();
    };

    const handleSendEmailClick = () => {
        setForgetPasswordEmailValidateError('');
        const emailAddressError = Utils.Validator.validateEmailAddress(forgetPasswordEmail);
        if (emailAddressError) {
            return setForgetPasswordEmailValidateError(emailAddressError);
        } return sendForgetPasswordEmail(forgetPasswordEmail);
    };

    const renderHiddenSignupModal = () => {
        return (
            <Modal
                isOpen={signUpModalVisible}
                toggle={toggleSignupModal}
                className='modal-dialog-centered modal-lg'
            >
                <Container
                    css={nestedSignupModal}
                >
                    <ModalHeader style={{ justifyContent: 'center' }}>
                        <div
                            css={nestedSignupModalTitle}
                        >
                            Fill in the blanks
                        </div>
                    </ModalHeader>
                    <ModalBody css={ContainerAlignCenter}>
                        <Form>
                            <Row style={{ maxHeight: '75px' }}>
                                <ValidationCheck
                                    name='address1'
                                    value={signupAddress1}
                                    type='text'
                                    maxLength={50}
                                    placeholder='Address 1'
                                    css={nestedSignupModalInput}
                                    onChange={(e) => handleInputChange(e, signupAddress1, setSignupAddress1)}
                                    errorMessage='Please fill in your address correctly'
                                />
                            </Row>
                            <Row style={{ maxHeight: '75px' }}>
                                <ValidationCheck
                                    name='address2'
                                    value={signupAddress2}
                                    type='text'
                                    maxLength={50}
                                    placeholder='Address 2'
                                    css={nestedSignupModalInput}
                                    onChange={(e) => handleInputChange(e, signupAddress2, setSignupAddress2)}
                                    errorMessage='Please fill in your address correctly'
                                // validationDisabled
                                />
                            </Row>
                            <Row style={{ justifyContent: 'space-between', maxHeight: '75px' }}>
                                <ValidationCheck
                                    type='select'
                                    name='country'
                                    value={selectedCountry}
                                    style={{ maxWidth: '200px', marginRight: '10px' }}
                                    placeholder='Country'
                                    css={nestedSignupModalInput}
                                    onChange={(e) => handleInputChange(e, selectedCountry, setSelectedCountry)}
                                    errorMessage='Please select country'
                                >
                                    {renderCountryOptions()}
                                </ValidationCheck>
                                <ValidationCheck
                                    name='postcode'
                                    value={signupPostcode}
                                    type='text'
                                    placeholder='Postcode'
                                    css={nestedSignupModalInput}
                                    style={{ maxWidth: '200px' }}
                                    onChange={(e) => handleInputChange(e, signupPostcode, setSignupPostcode)}
                                    errorMessage='Invalid Postcode, please fill it in correctly.'
                                />
                            </Row>
                            <Row style={{ justifyContent: 'space-between', maxHeight: '75px' }}>
                                <ValidationCheck
                                    name='state'
                                    value={selectedState}
                                    type='select'
                                    placeholder='State'
                                    style={{ maxWidth: '200px' }}
                                    css={nestedSignupModalInput}
                                    onChange={(e) => handleInputChange(e, selectedState, setSelectedState)}
                                    errorMessage='Please select your state'
                                >
                                    {renderStateOptions()}
                                </ValidationCheck>
                                <ValidationCheck
                                    name='city'
                                    value={signupCity}
                                    type='text'
                                    placeholder='City'
                                    style={{ maxWidth: '200px' }}
                                    css={nestedSignupModalInput}
                                    onChange={(e) => handleInputChange(e, signupCity, setSignupCity)}
                                    errorMessage='Please enter your city'
                                />
                            </Row>
                            <Row style={{ maxHeight: '75px' }}>
                                <ValidationCheck
                                    name='phonenumber'
                                    value={signupPhoneNumber}
                                    type='text'
                                    placeholder='Phone number (0123456789)'
                                    css={nestedSignupModalInput}
                                    onChange={(e) => handleInputChange(e, signupPhoneNumber, setSignupPhoneNumber)}
                                    errorMessage='Invalid phone number, please fill it in correctly.'
                                />
                            </Row>
                            <Row style={{ maxHeight: '75px' }}>
                                <ValidationCheck
                                    name='gender'
                                    value={signupGender}
                                    type='select'
                                    placeholder='Gender'
                                    css={nestedSignupModalInput}
                                    style={{ maxWidth: '200px' }}
                                    onChange={(e) => handleInputChange(e, signupGender, setSignupGender)}
                                    errorMessage='Please select gender'
                                >
                                    <option value='Gender' selected hidden>Gender</option>
                                    <option value='MALE'>MALE</option>
                                    <option value='FEMALE'>FEMALE</option>
                                </ValidationCheck>
                            </Row>
                            <Row style={{ maxHeight: '75px' }}>
                                <DatePicker
                                    onChange={setSignupDateOfBirth}
                                    value={signupDateOfBirth}
                                    maxDate={new Date()}
                                    placeholder='Date of birth'
                                >
                                    <ValidationCheck
                                        type='text'
                                        validationDisabled
                                        css={nestedSignupModalInput}
                                        style={{ maxWidth: '200px' }}
                                        formGroupCss={{ marginBottom: 0 }}
                                    />
                                </DatePicker>
                            </Row>
                            {/* <Row>
                                <ValidationCheck
                                    name='salutation'
                                    value={signupSalutation}
                                    type='select'
                                    placeholder='Salutation'
                                    css={nestedSignupModalInput}
                                    style={{ maxWidth: '200px', marginRight: '10px' }}
                                    onChange={(e) => handleInputChange(e, signupSalutation, setSignupSalutation)}
                                    errorMessage='Please select salutation'
                                >
                                    <option value='Mr.'>Mr.</option>
                                    <option value='Mrs.'>Mrs.</option>
                                    <option value='Ms.'>Ms.</option>
                                </ValidationCheck>
                                <ValidationCheck
                                    name='idno'
                                    value={signupIdNo}
                                    type='text'
                                    placeholder='Id No. (optional)'
                                    css={nestedSignupModalInput}
                                    style={{ maxWidth: '200px', marginRight: '10px' }}
                                    onChange={(e) => handleInputChange(e, signupIdNo, setSignupIdNo)}
                                />
                            </Row> */}
                        </Form>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'center', marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
                        <ErrorMessage error={signupError || signUpValidateError} />
                        {signingup ? <Spinner color='primary' /> : (
                            <Button
                                css={nestedSignupModalSubmitButton}
                                onClick={handleSubmitSignup}
                            >
                                Submit
                            </Button>
                        )}
                    </ModalFooter>
                </Container>
            </Modal>
        );
    };

    const renderRequestOtpModal = () => {
        const renderButton = () => {
            if (forgetPasswordEmailSent) {
                return (
                    <div style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                        <CustomButton
                            style={{ marginTop: '10px' }}
                            onClick={toggleSendEmailModal}
                            text='OK'
                        />
                    </div>
                );
            }

            return (
                <div style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                    <CustomButton
                        style={{ marginTop: '10px' }}
                        onClick={handleSendEmailClick}
                        disabled={!forgetPasswordEmail.length || forgetPasswordEmailSent}
                        error={forgetPasswordEmailValidateError || forgetPasswordError}
                        loading={forgetPasswordLoading}
                        text='Send email'
                    />
                </div>
            );
        };

        return (
            <Modal
                isOpen={sendEmailModal}
                toggle={toggleSendEmailModal}
                size='md'
            >
                <ModalHeader>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button outline style={{ marginRight: '15px' }} onClick={handleForgetPasswordBack}>
                            {'< Back'}
                        </Button>
                        Reset Password
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Label>
                        Enter email address
                    </Label>
                    <Row style={{ maxHeight: '75px' }}>
                        <ValidationCheck
                            name='email'
                            value={forgetPasswordEmail}
                            type='email'
                            placeholder='E-mail Address'
                            css={InputStyle}
                            onChange={(e) => handleInputChange(e, forgetPasswordEmail, setForgetPasswordEmail)}
                            formGroupCss={{ display: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }}
                            errorMessage='Invalid email, please fill it in correctly.'
                            disabled={forgetPasswordEmailSent}
                            onKeyDown={(e) => {
                                const { key } = e;
                                if (key === 'Enter') handleSendEmailClick();
                            }}
                        />
                    </Row>
                    <div>
                        <Label hidden={!forgetPasswordEmailSent}>
                            An email has been sent to
                            {' '}
                            {forgetPasswordEmail}
                        </Label>
                    </div>
                    {renderButton()}
                </ModalBody>
            </Modal>
        );
    };

    return (
        <>
            {modal ? (
                <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className='modal-dialog-centered modal-xl'
                >
                    <Container fluid>
                        <Row css={LoginContainer}>
                            <Col css={leftContainer} xs='12' lg='6'>
                                <Form>
                                    <Container style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '100%' }}>
                                            <Row css={ContainerAlignCenter}>
                                                <div css={leftTitle}>Create Account</div>
                                            </Row>
                                            <Row style={{ height: '75px' }}>
                                                <ValidationCheck
                                                    name='firstname'
                                                    value={signupFirstName}
                                                    type='text'
                                                    placeholder='First Name'
                                                    css={InputStyle}
                                                    onChange={(e) => handleInputChange(e, signupFirstName, setSignupFirstName)}
                                                    formGroupCss={formGroupCss}
                                                    errorMessage='Please fill in first name correctly.'
                                                    feedbackCss={signUpError}
                                                />
                                            </Row>
                                            <Row style={{ height: '75px' }}>
                                                <ValidationCheck
                                                    name='lastname'
                                                    value={signupLastName}
                                                    type='text'
                                                    placeholder='Last Name'
                                                    css={InputStyle}
                                                    onChange={(e) => handleInputChange(e, signupLastName, setSignupLastName)}
                                                    formGroupCss={formGroupCss}
                                                    errorMessage='Please enter last name correctly.'
                                                    feedbackCss={signUpError}
                                                />
                                            </Row>
                                            <Row style={{ height: '75px' }}>
                                                <ValidationCheck
                                                    name='email'
                                                    value={signupEmail}
                                                    type='email'
                                                    placeholder='E-mail Address'
                                                    css={InputStyle}
                                                    onChange={(e) => handleInputChange(e, signupEmail, setSignupEmail)}
                                                    formGroupCss={formGroupCss}
                                                    errorMessage='Invalid Email, please fill it in correctly.'
                                                    feedbackCss={signUpError}
                                                />
                                            </Row>
                                            <Row style={{ height: '75px' }}>
                                                <ValidationCheck
                                                    name='password'
                                                    value={signupPassword}
                                                    type='password'
                                                    placeholder='Password'
                                                    css={InputStyle}
                                                    onChange={(e) => handleInputChange(e, signupPassword, setSignupPassword)}
                                                    formGroupCss={formGroupCss}
                                                    errorMessage='Please enter password'
                                                    feedbackCss={signUpError}
                                                />
                                            </Row>
                                            <Row style={{ height: '75px' }}>
                                                <ValidationCheck
                                                    name='confirmedpassword'
                                                    value={signupConfirmedPassword}
                                                    type='password'
                                                    placeholder='Confirm Password'
                                                    css={InputStyle}
                                                    onChange={(e) => handleInputChange(e, signupConfirmedPassword, setSignupConfirmedPassword)}
                                                    formGroupCss={formGroupCss}
                                                    errorMessage='Please enter password'
                                                    feedbackCss={signUpError}
                                                    onKeyDown={handleSignUpBasicOnKeyDown}
                                                />
                                            </Row>
                                            <Row css={ContainerAlignCenter}>
                                                <div css={CheckBoxContainer}>
                                                    <CustomInput
                                                        type='checkbox'
                                                        id='terms'
                                                        checked={agreeTerms}
                                                        onChange={() => setAgreeTerms(!agreeTerms)}
                                                    />
                                                    <div>
                                                        I agree to the
                                                        <button
                                                            type='button'
                                                            style={{ color: 'black', marginLeft: 5, border: 0, backgroundColor: 'transparent', textDecorationLine: 'underline' }}
                                                            onClick={() => {
                                                                toggle(false);
                                                                NavActions.navToTermsAndConditions();
                                                            }}
                                                        >
                                                            Terms & Privacy Policy
                                                        </button>
                                                    </div>
                                                </div>
                                            </Row>
                                            <ErrorMessage error={signUpBasicError} />
                                            <Row css={ContainerAlignCenter}>
                                                <Button
                                                    css={ButtonStyleLeft}
                                                    onClick={handleSignupClick}
                                                >
                                                    ㅤ SIGN UP
                                                </Button>
                                            </Row>
                                        </div>
                                    </Container>
                                </Form>
                            </Col>
                            <Col css={rightContainer} xs='12' lg='6'>
                                <Row css={CloseContainer}>
                                    <div> </div>
                                    <Close
                                        onClick={onClosePressed}
                                        onKeyPress={onClosePressed}
                                        role='button'
                                        tabIndex='0'
                                    />
                                </Row>
                                <Container>
                                    <Form>
                                        <Row css={ContainerAlignCenter}>
                                            <div css={RightTitle}>Sign In</div>
                                        </Row>
                                        <Row css={ContainerAlignCenter}>
                                            <Input
                                                type='text'
                                                placeholder='E-mail Address'
                                                css={loginInput}
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                onKeyDown={handleLoginOnKeyDown}
                                            />
                                        </Row>
                                        <Row css={ContainerAlignCenter}>
                                            <Input
                                                type='password'
                                                placeholder='Password'
                                                css={loginInput}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onKeyDown={handleLoginOnKeyDown}
                                            />
                                        </Row>
                                        <Row css={ContainerAlignCenter}>
                                            <div css={CheckBoxOuterContainerRight}>
                                                <div css={CheckBoxContainerRight}>
                                                    <CustomInput type='checkbox' id='remember me' />
                                                    <div>Remember me</div>
                                                </div>
                                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                                <div onClick={handleForgetPasswordClick} onKeyDown={handleForgetPasswordClick} css={ForgotPasswordStyle}>
                                                    Forgot your password?
                                                </div>
                                            </div>
                                        </Row>
                                        <Row css={ContainerAlignCenter}>
                                            <div css={ButtonStyleRight}>
                                                <CustomButton
                                                    onClick={onLoginPressed}
                                                    text='SIGN IN'
                                                    loading={loading}
                                                    error={error}
                                                />
                                            </div>
                                        </Row>
                                    </Form>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            ) : null}
            {renderHiddenSignupModal()}
            {renderRequestOtpModal()}
        </>
    );
};

const mapStateToProps = (state) => ({
    loading: Selectors.getAuthLoginAttempting(state),
    error: Selectors.getAuthLoginError(state),
    signingup: Selectors.getAuthSignUpAttempting(state),
    signupError: Selectors.getAuthSignUpError(state),
    signUpModalVisible: Selectors.getUiAuthSignUpModalVisible(state),
    forgetPasswordLoading: Selectors.getAuthForgetPasswordAttempting(state),
    forgetPasswordError: Selectors.getAuthForgetPasswordError(state),
    forgetPasswordEmailSent: Selectors.getUiAuthIsEmailSent(state),
});

const mapDispatchToProps = (dispatch) => ({
    login: (params) => dispatch(Actions.authLogin(params)),
    reset: () => dispatch(Actions.authClearLogin()),
    signup: (data) => dispatch(Actions.authSignup({ ...data })),
    setSignUpModalVisible: (modalVisible) => dispatch(Actions.uiSetSignUpModalVisible(modalVisible)),
    sendForgetPasswordEmail: (email) => dispatch(Actions.authForgetPassword({ email })),
    setIsEmailSent: (data) => dispatch(Actions.uiSetIsEmailSent(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignUpModal);
